import React, { useEffect, useRef, useState } from "react";
import PaymentCard from "./Component/PaymentCard";
import SoldCard from "./Component/SoldCard";
import "./EventAttendence.css";
import "react-toastify/dist/ReactToastify.css";
import {
  GetAllOrderFormFieldsByEventId,
  ticktesCartItem,
  PersistShoppingCartSession,
  GetDiscountData,
  CreateShoppingCartSession,
} from "Actions/eventActions";
import moment from "moment";
import { BsSearch } from "react-icons/bs";
import SearchBar from "./Component/SearchBar";
import EventAttendeeTable from "./Component/EventTable";
import {
  Modal,
  Tab,
  Tabs,
  Grid,
  Button,
  withStyles,
  LinearProgress,
} from "@material-ui/core";
import OrderDetail from "./Component/ModalAction/OrderDetail";
import { useLayoutEffect } from "react";
import { IoCloseOutline } from "react-icons/io5";
import { axiosE } from "Service/service";
import { connect } from "react-redux";
import { default as momentTz } from "moment-timezone";
import RenderTicktsData from "./Component/RenderTicketData/RenderTicketData";
import AddAttendeeModal from "./Component/AddAttendee/AddAttendeeModal";
import { Redirect } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { HiPlus } from "react-icons/hi";
import Loader from "Helper/loader/loader";
import { validatePhoneNo } from "Helper/Validations";
import { routeName } from "Service/env";
import { Text, URLs } from "Constants";
import {ParseError, parsePhoneNumberWithError} from "libphonenumber-js";
import * as constants from "../../../../Constants";

const libphonenumber = require('libphonenumber-js');

function EventAttendence({
  PersistShoppingCartSession,
  CreateShoppingCartSession,
  dispatch,
  results,
  eventUserEvents,
  GetAllOrderFormFieldsByEventId,
}) {
  const { EventId, TimeZone, EventCode, Title, StartDateTime, EndDateTime } = eventUserEvents?.eventsAbout || {};
  const [isOpen, setIsOpen] = useState(false);
  const [addAttendee, setAddAttendee] = useState(true);
  const [editAttendee, setEditAttendee] = useState(true);
  const [payoutData, setPayoutData] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [isNotFilled, setIsNotFilled] = useState(false);
  const [checkboxValue, setCheckBoxValue] = useState("");
  const [ticketData, setTicketData] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [validationCode, setValidationCode] = useState("");
  const [shoppingCartDetails, setShoppingCartDetails] = useState("");
  const [modalType, setModalType] = useState("");
  const [orderId, setOrderId] = useState("");
  const [editAdditionalInfo, setEditAdditionalinfo] = useState();
  const [loader, setLoader] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const [recurringData, setRecurringData] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [bundleSelectedQuantity, setBundleSelectedQuantity] = useState({});
  // const [disableDialCode, setDisableDialCode] = useState(true);

  // call child comp fn in parnet using useRef
  const getAttendeeRef = useRef(null);
  function toggleModal() {
    setIsOpen(!isOpen);
    setModalType("");
  }

  useEffect(() => {
    //Find index of specific object using findIndex method.
    let objIndex = additionalInfo?.findIndex(
      (obj) => obj?.FieldName === "Terms And Conditions"
    );
    //Update object's name property.
    if (objIndex > 0) {
      additionalInfo[objIndex].DefaultValue = isChecked;
    }
  }, [isChecked]);

  const checkboxItem = additionalInfo.find(
    (x) => x.FieldName === "Terms And Conditions"
  );
  useEffect(() => {
    if (additionalInfo?.some((i) => i?.DefaultValue === "")) {
      setIsNotFilled(true);
    } else {
      setIsNotFilled(false);
    }

    if (checkboxItem) {
      setCheckBoxValue(checkboxItem.DefaultValue);
    }
  }, [additionalInfo, checkboxItem]);

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }
  const [width, height] = useWindowSize();

  const AllTicketsAnalyticByEventId = async () => {
    try {
      const { data } = await axiosE.get(
        "ManageEvents/AllTicketsAnalyticByEventId/" + EventId
      );

      if (data.results.ResponseCode) {
        setPayoutData(data.results.Data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    AllTicketsAnalyticByEventId();

    // Fetch the user's IP address
    fetch(constants.API.Geolocation_V2)
        .then((response) => response.json())
        .then((data) => {
          // Extract the country code from the IP address data
          const countryCode = data.country.toLowerCase();
          setSelectedCountry(countryCode); // Set the default country
        })
        .catch((error) => {
          console.log('Error fetching IP address:', error);
        });
  }, []);

  let formattedInfo = {};
  const data = additionalInfo.map(({ FieldName, DefaultValue }) =>
    Object.assign(formattedInfo, {
      [FieldName.replace(/\s/g, FieldName === "Full Name" ? "" : "_")]:
        DefaultValue,
    })
  );
  formattedInfo.Phone = formattedInfo?.Phone?.replace(/[^\d]+/g, "");

  const OrderAdditionalData = () => {
    dispatch(GetAllOrderFormFieldsByEventId(EventId))
      .then((data) => {
        if (data.results.ResponseCode) {
          const defaultAdditionalData = data.results.ListOrderFormField.map(
            (item) => {
              const obj = Object.assign({}, item);
              if (
                item.FieldName === "Full Name" &&
                results?.ProfileDetail?.FirstName !== null
              ) {
                obj["DefaultValue"] = results?.ProfileDetail?.FirstName;
              }
              if (
                item.FieldName === "Email" &&
                results?.ProfileDetail?.Email !== null
              ) {
                obj["DefaultValue"] = results?.ProfileDetail?.Email;
              }
              if (
                item.FieldName === "Phone" &&
                results?.ProfileDetail?.PhoneNumber !== null
              ) {
                let fomatedNumber = "";
                if (results?.ProfileDetail?.PhoneNumber) {
                  // let cleaned =
                  //   "" + results?.ProfileDetail?.PhoneNumber.replace(/\D/g, "");
                  // for (let index = 0; index < cleaned.length; index++) {
                  //   if (index == 0) {
                  //     fomatedNumber = "(";
                  //   } else if (index == 3) {
                  //     fomatedNumber = fomatedNumber + ") ";
                  //   } else if (index == 6) {
                  //     fomatedNumber = fomatedNumber + "-";
                  //   }
                  //   fomatedNumber = fomatedNumber + cleaned[index];
                  // }
                  fomatedNumber = results?.ProfileDetail?.PhoneNumber
                }
                obj["DefaultValue"] = fomatedNumber;
              }
              return obj;
            }
          );

          setAdditionalInfo(defaultAdditionalData);
        }
      })
      .catch(() => {});
  };

  const CartSessionByOrganizer = async () => {
    try {
      const { data } = await axiosE.get(
        `CreateShoppingCartSessionByOrganizer/${EventId}/${EventCode}`
      );
      const timeUTCtoLocal = (date, timezone) => {
        var local = momentTz
          .tz(moment.utc(date), "MM/DD/YYYY hh:mm:ss a", timezone)
          .format("MM/DD/YYYY hh:mm:ss a");
        return moment(local, "MM/DD/YYYY hh:mm:ss a");
      };

      let newData = { ...data.results };
      const ticketDataUtctoLocal = newData?.CartItems?.map((ticketdata) => {
        return {
          ...ticketdata,
          ActiveDate: timeUTCtoLocal(ticketdata.ActiveDate, TimeZone),
          InActiveDate: timeUTCtoLocal(ticketdata.InActiveDate, TimeZone),
        };
      });
      setTicketData(ticketDataUtctoLocal);
      setShoppingCartDetails({
        ...data.results,
        CartItems: ticketDataUtctoLocal,
      });
      setValidationCode(data.results.CartSession.ValidationCode);
    } catch (error) {}
  };

  const convertAndAdjustDatetime = (utcDate, utcTime) => {
    const targetTimezone = JSON.parse(localStorage.getItem("CurrentTimeZone"));
    // if (targetTimezone === "Asia/Kolkata") {
    const utcDateTime = momentTz.utc(
        `${utcDate} ${utcTime}`,
        "DD/MM/YYYY HH:mm:ss"
    );
    const convertedDateTime = momentTz.tz(utcDateTime, targetTimezone);

    if (convertedDateTime.date() > utcDateTime.date()) {
      const revertedDateTime = momentTz
          .tz(utcDateTime, "UTC")
          .subtract(1, "day");
      return revertedDateTime.format("MM/DD/YYYY");
    } else if (convertedDateTime.date() < utcDateTime.date()) {
      const revertedDateTime = momentTz.tz(utcDateTime, "UTC").add(1, "day");
      return revertedDateTime.format("MM/DD/YYYY");
    }

    return convertedDateTime.format("MM/DD/YYYY");
    // }

    // return moment(utcDate, "DD/MM/YYYY").format("MM/DD/YYYY");
  };

  const sortSelectedSlots = (item) => {
    if (item.selectedSlots) {
      item.selectedSlots.sort((a, b) => {
        // Convert date strings to JavaScript Date objects for comparison
        const dateA = parseDate(a.date, a.StartTime);
        const dateB = parseDate(b.date, b.StartTime);

        // Compare dates
        if (dateA < dateB) {
          return -1;
        } else if (dateA > dateB) {
          return 1;
        } else {
          // If dates are equal, compare start times
          const timeA = parseInt(a.StartTime.replace(':', ''));
          const timeB = parseInt(b.StartTime.replace(':', ''));

          return timeA - timeB;
        }
      });
    }
  };

  // Parse date in DD/MM/YYYY format and time in HH:mm format
  const parseDate = (date, time) => {
    const [day, month, year] = date.split('/');
    const hours = time ? parseInt(time.split(':')[0]) : 0;
    const minutes = time ? parseInt(time.split(':')[1]) : 0;
    // Months are zero-based in JavaScript Date, so we subtract 1 from the month
    return new Date(year, month - 1, day, hours, minutes);
  };

  const PersistShoppingCart = () => {
    setIsOpen(false);
    setLoader(true);
    if (validationCode) {
      if (results?.ProfileDetail?.UserId) {
        setLoading(true);
        cartItems.forEach((item) => sortSelectedSlots(item));
        const data = {
          ShoppingCart: {
            CartSession: {
              EventId: EventId,
              SessionId: shoppingCartDetails?.CartSession.SessionId,
              DiscountId: "",
              UserId: results?.ProfileDetail?.UserId,
              Salt: EventCode,
              ValidationCode: validationCode,
            },
            CartItems: cartItems.flatMap((cartItem) => {
                  const {
                    Quantity,
                    TicketId,
                    ItemId,
                    RecurringTicket,
                    selectedDate,
                    selectedSlots,
                    selectedSlot,
                    isBundle,
                  } = cartItem;

                  const RecurringTicketValue = RecurringTicket === "True" ? "true" : "false";

                  if (isBundle) {
                    // If it's a bundle, iterate through selectedSlots
                    return selectedSlots.map((slot) => ({
                      Quantity,
                      TicketId,
                      // ItemId: slot.ItemId,
                      RecurringTicket: RecurringTicketValue,
                      BookingDate: convertAndAdjustDatetime(slot.date, slot.StartTime),
                      BookingTime: slot.StartTime,
                      isBundle: isBundle ? 1 : 0
                    }));
                  } else {
                    // If it's not a bundle, return a single item
                    return {
                      Quantity,
                      TicketId,
                      ItemId,
                      RecurringTicket: RecurringTicketValue,
                      BookingDate: RecurringTicket === "True" ? convertAndAdjustDatetime(selectedDate, selectedSlot?.StartTime) : "",
                      BookingTime: RecurringTicket === "True" ? selectedSlot?.StartTime : "",
                    };
                  }
                }
            ),
          },
        };
        if (cartItems?.length > 0) {
          dispatch(PersistShoppingCartSession(data))
            .then((res) => {
              if (res.ResponseCode) {
                PersistFreeOrder(res.CartSession);
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      }
    }
  };

  const PersistFreeOrder = (res) => {
    // localStorage.setItem("additionalInfo", JSON.stringify(obj))
    cartItems.forEach((item) => sortSelectedSlots(item));
    const payload = {
      ShoppingCart: {
        CartSession: {
          Salt: EventCode,
          isOrganizer: res.isOrganizer,
          EventId: EventId,
          SessionId: shoppingCartDetails?.CartSession.SessionId,
          DiscountId: "",
          UserId: results?.ProfileDetail?.UserId,
          OrderAdditionalData: JSON.stringify(formattedInfo),
          ValidationCode: validationCode,
        },
        CartItems: cartItems.flatMap(
          ({
            Quantity,
            TicketId,
            ItemId,
            RecurringTicket,
            selectedDate,
            selectedSlot,
            selectedSlots,
            isBundle
          }) => {
            const RecurringTicketValue = RecurringTicket === "True" ? "true" : "false";

            if (isBundle) {
              // If it's a bundle, iterate through selectedSlots
              return selectedSlots.map((slot) => ({
                Quantity,
                TicketId,
                // ItemId: slot.ItemId,
                RecurringTicket: RecurringTicketValue,
                BookingDate: convertAndAdjustDatetime(slot.date, slot.StartTime),
                BookingTime: slot.StartTime,
                isBundle: isBundle ? 1 : 0
              }));
            } else {
              return {
                Quantity,
                TicketId,
                ItemId,
                RecurringTicket: RecurringTicketValue,
                BookingDate: RecurringTicket === "True" ? convertAndAdjustDatetime(selectedDate, selectedSlot?.StartTime) : "",
                BookingTime: RecurringTicket === "True" ? selectedSlot?.StartTime : "",
              };
            }
          }
        ),
      },
    };

    // if (cartItems?.length > 0) {
    axiosE
      .post("PersistFreeOrder", payload)
      .then(({ data }) => {
        if (data.results.ResponseCode) {
          toast("Attendee Added successfully");
          getAttendeeRef.current.GetAllAttendeeByEventId();
          // history.push(`${routeName}/success`);
        }
        setIsOpen(false);
        setLoader(false);
        setCartItems([]);
        setModalType("");
      })
      .catch((e) => {
        console.log(e);
      });
    // }
  };

  const handlePhoneNumberChange = (value, country) => {
    try {
      // Remove all non-numeric characters from the value
      value = value.replace(/\D/g, "");

      // setDisableDialCode(false);

      // Add the country code to the value
      const countryCode = `${country.dialCode}`;
      if (!value.startsWith(countryCode)) {
        value = countryCode + value;
      }

      // Ensure the input value has a minimum length before parsing
      if (value.length >= 6) {
        const phoneNumberObject = parsePhoneNumberWithError(value, country.countryCode.toUpperCase());
        if (phoneNumberObject !== undefined && phoneNumberObject !== "") {
          if (phoneNumberObject.isValid()) {
            // Valid phone number
            setIsNotFilled(false);
            // const formattedNumber = phoneNumberObject.formatInternational().replace(countryCode, "");
            setPhoneNumber(value);
            setSelectedCountry(country.countryCode);
            setPhoneError("");
            let newData = [...additionalInfo];

            if (newData !== "") {
              let foundObject = newData.find((item) => item.FieldName === 'Phone');

              if (foundObject) {
                foundObject.DefaultValue = value
              }
              setAdditionalInfo(newData)
            }

          } else {
            // Invalid phone number
            setIsNotFilled(true);
            setPhoneNumber(value); // Keep the entered value as-is
            console.log('Invalid phone number:', value);
            setPhoneError("Please enter a valid phone number.");
          }
        } else {
          // Parsing failed
          setIsNotFilled(true);
          setPhoneNumber(value); // Keep the entered value as-is
          console.log('Phone number parsing failed:', value);
        }
      } else {
        // Input doesn't meet minimum length
        setIsNotFilled(true);
        setPhoneNumber(value); // Keep the entered value as-is
        setPhoneError("Please enter a valid phone number.");
      }

      if (selectedCountry !== country.countryCode) {
        setIsNotFilled(true);
        setPhoneError("Phone no. is required");
      }
    } catch (error) {
      if (error instanceof ParseError) {
        // Not a phone number, non-existent country, etc.
        console.log(error.message)
      } else {
        throw error
      }
    }
  };

//   const getPlaceholder = (countryCode) => {
//     // Specify the phone number and the desired country code
//     const phoneNumber = '9999999999';
//
//     // Get the number format for the specified country
//     const formattedNumber = libphonenumber.formatNumber(phoneNumber ,countryCode, 'INTERNATIONAL');
//
// // Log the formatting pattern
//     console.log(formattedNumber);
//
//     return formattedNumber;
//     // return countryFormatMap[countryCode] || 'xxxxxxxx'; // Default to 'xxxxxxxx' for unknown countries
//   };


  const handleChangeAdd = (e) => {
    let { name, value, id } = e.target;
    let newData = [...additionalInfo];

    // if (name === "Phone") {
    //   if (!/[a-zA-Z]/.test(value)) {
    //     let cleaned = "" + value.replace(/\D/g, "");
    //     for (let index = 0; index < cleaned.length; index++) {
    //       if (index == 0) {
    //         value = "(";
    //       } else if (index == 3) {
    //         value = value + ") ";
    //       } else if (index == 6) {
    //         value = value + "-";
    //       }
    //       value = value + cleaned[index];
    //     }
    //   } else {
    //     value = newData[id]["DefaultValue"];
    //   }
    //   setPhoneError(validatePhoneNo(value));
    // }

    newData[id]["DefaultValue"] = value;
    setAdditionalInfo(newData);
  };
  if (additionalInfo?.length > 0) {
    ["Email", "Full Name"].map((item) => {
      const index = additionalInfo?.findIndex((object) => {
        return object?.FieldName === item;
      });
      const toIndex = 0;
      const element = additionalInfo.splice(index, 1)[0];
      additionalInfo.splice(toIndex, 0, element);
    });
  }

  const handleInput = (e) => {
    e.target.style.height = 'auto'; // Reset height
    e.target.style.height = `${e.target.scrollHeight}px`; // Set height based on scroll height
    handleChangeAdd(e); // Call the existing handleChange function to handle other logic
  };

  const handleChange = (Quantity, ticket, recurringData) => {
    //  coming from api response

    const exist = cartItems?.find((x) => x.TicketId === ticket.TicketId);
    if (exist && Quantity.value > 0) {
      if (ticket.RecurringTicket === "True") {
        if (ticket.isBundle) {
          setBundleSelectedQuantity((prevQuantities) => ({
            ...prevQuantities,
            [ticket.TicketId]: Quantity.value,
          }));

          if (bundleSelectedQuantity[ticket.TicketId] !== Quantity.value) {
            setCartItems(
                cartItems.filter((x) => x.TicketId !== ticket.TicketId)
            )
          } else {
            setCartItems (
              cartItems?.map((x) =>
                  x.TicketId === ticket.TicketId
                      ? {...exist, Quantity: Quantity.value, ...recurringData}
                      : x
              )
            )
          }
        } else {
          setCartItems(
              cartItems?.map((x) =>
                  x.TicketId === ticket.TicketId
                      ? {...exist, Quantity: Quantity.value, ...recurringData}
                      : x
              )
          );
        }
      } else {
        setCartItems(
          cartItems?.map((x) =>
            x.TicketId === ticket.TicketId
              ? { ...exist, Quantity: Quantity.value }
              : x
          )
        );
      }
    } else {
      if (Quantity.value > 0) {
        if (ticket.RecurringTicket === "True") {
          if (ticket.isBundle) {
            setBundleSelectedQuantity((prevQuantities) => ({
              ...prevQuantities,
              [ticket.TicketId]: Quantity.value,
            }));
          }

          if (ticket.isBundle && (recurringData !== undefined && recurringData !== null && recurringData.selectedSlots !== undefined && recurringData.selectedSlots !== null)) {
            setCartItems([
              ...cartItems,
              {...ticket, Quantity: Quantity.value, ...recurringData},
            ])
          } else if (!ticket.isBundle) {
            setCartItems([
              ...cartItems,
              {...ticket, Quantity: Quantity.value, ...recurringData},
            ]);
          }
        } else {
          setCartItems([...cartItems, { ...ticket, Quantity: Quantity.value }]);
        }
      } else {
        if (ticket.isBundle) {
          setBundleSelectedQuantity((prevQuantities) => ({
            ...prevQuantities,
            [ticket.TicketId]: Quantity.value,
          }));
        }

        setCartItems(cartItems.filter((x) => x.TicketId !== ticket.TicketId));
      }
    }

    if (exist && Quantity.value === 0 && ticket.RecurringTicket === "True") {
      console.log({ exist });
      setCartItems(cartItems?.filter((x) => x.TicketId !== ticket.TicketId));
    }
    // setSelectedOption(ticket);
  };

  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 6,
      borderRadius: 0,
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    bar: {
      borderRadius: 0,
      height: 50,
      backgroundColor: "rgb(0, 171, 85)",
      transform: ({ value }) => {
        return value > 0
          ? `translateX(${value}%)`
          : `translateX(-101%) !important`;
      },
    },
    // the default style uses translateX, so we need to switch the axis
  }))(LinearProgress);

  //  Update attendee fn with ref to call getAllattendee
  const AttendeeUpdate = async (payload) => {
    setIsOpen(false);
    setLoader(true);
    try {
      const { data } = await axiosE.post("Attendee/Update", payload);
      if (data.results.ResponseCode) {
        setModalType("");
        setLoader(false);

        getAttendeeRef.current.GetAllAttendeeByEventId();
        toast("Attendee updated successfully");
      }
    } catch (error) {}
  };

  const GetAllAttendees = () => {
    getAttendeeRef.current.GetAllAttendeeByEventId();
  };

  const CurrentEventId = JSON.parse(localStorage.getItem("CurrentEventId"));

  if (CurrentEventId !== EventId) {
    return <Redirect to="/" />;
  }

  return (
    <div>
      {loader && <Loader />}
      <ToastContainer
        className="toaster-container"
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {eventUserEvents.eventPublish ? null : (
        <Redirect to={`${routeName}/Dashboard/Publish`} />
      )}
      <div className="eventAttendence">
        <div className="email_invite_wrapper">
          {/* <ShowErrorToast message={error} closeToast={() => setError("")} /> */}
          <div className="email_invite_header">
            <div className="email_invite">
              <span style={{ fontSize: 30 }}>{Text.Attendees}</span>
              <button
                onClick={() => {
                  setIsOpen(true);
                  setModalType("Add");
                }}
                className="email_invite_button"
              >
                {Text.AddAttendeePlus}
              </button>
            </div>
          </div>
        </div>

        <Modal open={isOpen} onClose={toggleModal}>
          <AddAttendeeModal
            {...{
              modalType,
              phoneError,
              selectedCountry,
              phoneNumber,
              // to update attendee
              GetAllAttendees,
              AttendeeUpdate,
              orderId,
              setOrderId,
              OrderAdditionalData,
              CartSessionByOrganizer,
              width,
              PersistShoppingCart,
              setIsOpen,
              TimeZone,
              additionalInfo,
              handleChange,
              handleChangeAdd,
              handleInput,
              handlePhoneNumberChange,
              toggleModal,
              cartItems,
              ticketData,
              editAdditionalInfo,
              setEditAdditionalinfo,
              setRecurringData,
              recurringData,
              shoppingCartDetails,
              bundleSelectedQuantity,
              setBundleSelectedQuantity,
              Title
            }}
          />
        </Modal>

        <div className="eventAttendenceCheckIn">
          <div className="eventAttendenceCheckIn_left">
            <div style={{ width: 40 }}>
              <div className="warningLogo"></div>
            </div>
            <div className="eventAttendenceCheckIn_text">
              <h5>{Text.UBMeOrganizerAppText}</h5>
            </div>
          </div>
          <div className="eventAttendenceCheckIn_right">
            <a href={`${URLs.playStoreUrl}`} target="_blank">
              <img
                className="play_store_img"
                src="https://d2gnb7jphto2jj.cloudfront.net/img/play_store.png"
                alt=""
              />
            </a>
            <a href={`${URLs.appStoreUrl}`} target="_blank">
              <img
                className="app_store_img"
                src="https://d2gnb7jphto2jj.cloudfront.net/img/App_store.png"
                alt=""
              />
            </a>
          </div>
        </div>
        <div className="eventAttendenceCheckIn_right_mobile">
          <a href={`${URLs.playStoreUrl}`} target="_blank">
            <img
              className="play_store_img"
              src="https://d2gnb7jphto2jj.cloudfront.net/img/play_store.png"
              alt=""
            />
          </a>
          <a href={`${URLs.appStoreUrl}`} target="_blank">
            <img
              className="app_store_img"
              src="https://d2gnb7jphto2jj.cloudfront.net/img/App_store.png"
              alt=""
            />
          </a>
        </div>
        <div className="eventAttendenceCard">
          <div className="sold">
            <SoldCard payoutData={payoutData} />
          </div>
          <div className="payout">
            <PaymentCard payoutData={payoutData} />
          </div>
        </div>
        <div className="checkInOrder">
          <div className="checkInOrderHeader">
            <h2>{Text.Confirm} </h2>
            <h3 style={{ fontStyle: "italic" }}>({Text.ConfirmAttendees})</h3>
          </div>

          <div className="checkInData">
            <div className="checkInData_bar">
              <BorderLinearProgress
                variant="determinate"
                value={
                  (Number(payoutData?.Scanned) / Number(payoutData?.Sold)) * 100
                }
              />
            </div>

            {/* <div className="checkInLevel" /> */}
            <span>
              {payoutData?.Scanned
                ? Intl.NumberFormat().format(payoutData?.Scanned)
                : 0}
              /
              {payoutData?.Sold
                ? Intl.NumberFormat().format(payoutData?.Sold)
                : 0}
            </span>
          </div>
        </div>
        <div>
          <button
            onClick={() => {
              setIsOpen(true);
              setModalType("Add");
            }}
            className="edit_container_toggle_btn"
          >
            <HiPlus color="#ffffff" size={30} />
          </button>
        </div>
        <EventAttendeeTable
          {...{
            ConfirmAttendees: () => AllTicketsAnalyticByEventId(),
            EventId,
            TimeZone,
            Title,
            setIsOpen,
            setModalType,
            setOrderId,
            setEditAdditionalinfo,
            getAttendeeRef,
            StartDateTime,
            EndDateTime
          }}
        />
        <style jsx="true">
          {`
            .text-input {
              width: 100%;
            }
          `}
        </style>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { eventUserEvents } = state;
  return {
    eventUserEvents: eventUserEvents,
    eventPublish: state.eventUserEvents.eventPublish,
    results: state.loginResponse.results,
  };
};

const matchDispatchToProps = (dispatch) => {
  return {
    // AddToFavorite: () => dispatch(AddToFavorite),
    // SetCardData: () => dispatch(SetCardData),
    // LoginPopup: () => dispatch(LoginPopup),
    // GetUserProfileDetail,
    // saveUserData,
    PersistShoppingCartSession,
    CreateShoppingCartSession,
    GetAllOrderFormFieldsByEventId,
    dispatch,
  };
};

export default connect(mapStateToProps, matchDispatchToProps)(EventAttendence);
