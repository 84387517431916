import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { AiFillCaretDown } from "react-icons/ai";
import {
  Button,
  CircularProgress,
  makeStyles,
  Modal,
  Paper,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import { Parser } from "json2csv";
import { default as momentTz } from "moment-timezone";
import TablePagination from "@mui/material/TablePagination";
import { useTable } from "react-table";
import { axiosE } from "Service/service";
import SearchBar from "./SearchBar";
import UseOutsideClick from "Components/UBMeEvents/Components_UBMeEvents/OutSideClick/UseOutsideClick";
import { useRef } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { UserIconsPic } from "Components/UBMeEvents/Components_UBMeEvents/Navbar/Alphabets/UserIconsPic";
import { LazyLoadImage } from "react-lazy-load-image-component";
import moment from "moment/moment";
import { RiErrorWarningFill } from "react-icons/ri";
import { FiHelpCircle } from "react-icons/fi";
import { Text } from "Constants";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import ExcelJS from "exceljs";
import * as FileSaver from "file-saver";
import ubmeLogo from '../../../../../images/ubme_logo.png';
import {parsePhoneNumberFromString} from "libphonenumber-js";
const { jsPDF } = require("jspdf");
require("jspdf-autotable");

const _ = require('lodash');

const EventAttendeeTable = ({
  EventId,
  TimeZone,
  Title,
  setIsOpen,
  setModalType,
  setOrderId,
  setEditAdditionalinfo,
  getAttendeeRef,
  ConfirmAttendees,
  StartDateTime,
  EndDateTime
}) => {
  const [attendeeData, setAttendeeData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchBy, setSearchBy] = useState("AttendeeName");
  const [anchorEl, setAnchorEl] = useState(null);
  const [csvTitle, setCsvTitle] = useState("Download Attendees");
  const [pdfTitle, setPDFTitle] = useState("Print Attendees");
  const menuRef = useRef(null);
  const [isSearched, setIsSearched] = useState(false);
  const [checkedIn, setCheckedIn] = useState({
    check: "",
    qrid: "",
    eventId: "",
  });
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    caption: {
      color: "green",
      padding: 8,
      border: "1px dashed grey",
      fontSize: "1.5rem",
    },
    // toolbar: {

    //   color: 'blue',
    //   fontSize: '16rem',
    // },
    spacer: {
      color: "green",
      fontSize: "16rem",
    },
    selectIcon: {
      fontSize: "16rem",
    },
  }));

  const classes = useStyles();
  // const jsonFormatterHelper = (ListAttendee) => {
  //   // loop data for serialized json;
  //   const filterdObjects = ListAttendee.map((item) => {
  //     // parsing string object
  //     const jsonobj = item.AdditionalDetails;
  //     const {
  //       Data,
  //       Message,
  //       ResponseCode,
  //       AdditionalDetails,
  //       Email,
  //       ...restItem
  //     } = item;
  //     // console.log("jsonobj", jsonobj.replaceAll("\\", ""));
  //     if (jsonobj) {
  //       // replace single qoute with double qoute for valid json format
  //       const additionalInfo = jsonobj.replace(/'/g, '"');
  //       const newString = additionalInfo.replaceAll("\\", "");
  //       const modifiedString = newString.replace(/([^:])"(s|t)/g, "$1'$2");
  //       // /(?<!:)"s/g, we use a negative lookbehind assertion (?<!: ) to match "s only if it is not preceded by a colon character.
  //       // const regex = /"(\w*)'(\w*)"(?![^<]*>)/g;
  //       // const replacement = "'$1s$2'";
  //       // const modifiedString = additionalInfo.replace(regex, replacement);

  //       // const modifiedString = additionalInfo.replace(/(?<!:)"s/g, (match) => {
  //       //   return "'s";
  //       // });
  //       // finally parsing the object

  //       const additionalInfoObject = JSON.parse(
  //         modifiedString.replace(/"{/g, "{").replace(/}"/g, "}")
  //       );
  //       // combined the objects
  //       const restInfo = additionalInfoObject;
  //       return { ...restInfo, ...restItem };
  //     } else {
  //       return { FullName: "", Email: "", Phone: "", ...restItem };
  //     }
  //   });
  //   return filterdObjects;
  // };

  const filterUnwantedKeys = (obj) => {
    const filteredObject = {};

    for (const key in obj) {
      if (isNaN(key)) {
        filteredObject[key] = obj[key];
      }
    }

    return filteredObject;
  };

  const parseAndValidateJSON = (jsonString) => {
    // Replace 'null' with 'null' and single quotes with double quotes
    const replacedString = jsonString
      .replace(/'/g, '"')
      .replace(/null/g, "null");

    try {
      const parsedData = JSON.parse(replacedString);
      return { success: true, data: parsedData };
    } catch (error) {
      return { success: false, error: error.message };
    }
  };

  const isPlainObject = (variable) => {
    return (
      typeof variable === "object" &&
      variable !== null &&
      !Array.isArray(variable)
    );
  };

  const jsonFormatterHelper = (ListAttendee) => {
    // console.log({ ListAttendee });
    const filterdObjects = ListAttendee.map((item) => {
      const jsonobj = item.AdditionalDetails;
      const {
        Data,
        Message,
        ResponseCode,
        AdditionalDetails,
        Email,
        ...restItem
      } = item;

      if (jsonobj) {
        let parsedData = {};
        try {
          const parsedJsonObj = parseAndValidateJSON(jsonobj);
          let restInfo = {};
          if (parsedJsonObj.success && parsedJsonObj.data?.FullName) {
            restInfo = { ...parsedJsonObj.data };
          } else {
            const modifiedJson = JSON.parse(jsonobj, (key, value) => {
              if (typeof value === "string") {
                // Replace single quotes within string values with apostrophes
                return value.replace(/'/g, "\\'");
              }
              return value;
            });

            parsedData = JSON.parse(jsonobj);
            const data = JSON.parse(parsedData);
            // console.log({ modifiedJson, parsedData, data });

            restInfo = { ...modifiedJson, ...data };
          }
          // console.log({ restInfo });

          return { ...restInfo, ...restItem };
        } catch (e) {
          const isObject = isPlainObject(parsedData);

          if (isObject) {
            return { ...parsedData, ...restItem };
          }

          // Replace single quotes with double quotes
          const correctedJsonString = parsedData.replace(/'/g, '"');
          const newParsedData = JSON.parse(correctedJsonString);
          return { ...newParsedData, ...restItem };
        }
      } else {
        return { FullName: "", Email: "", Phone: "", ...restItem };
      }
    });
    const filteredDetails = filterdObjects?.map((obj) =>
      filterUnwantedKeys(obj)
    );
    return filteredDetails;
  };

  useEffect(() => {
    if (searchTerm !== "") {
      SearchAttendees(false)
    } else {
      GetAllAttendeeByEventId();
    }
  }, [page]);

  const handleSearchAttendees = async (reset) => {
    if (page === 0) {
      if (searchTerm !== "" && !reset) {
        SearchAttendees(false)
      } else {
        GetAllAttendeeByEventId();
      }
    } else {
      setPage(0);
    }
  }

  const SearchAttendees = async () => {
    const payload = {
      EventId: EventId,
      SearchBy: searchBy,
      SearchTerm: searchTerm,
      PageNumber: page + 1,
      PageSize: rowsPerPage,
    };
    try {
        const { data } = await axiosE.post("SearchAttendee", payload);
        if (data.results.ResponseCode) {
          const filterdObjects = jsonFormatterHelper(data.results.ListAttendee);

          const timeUTCtoLocal = (date) => {
            var local = momentTz
                .tz(moment.utc(date), "MM/DD/YYYY hh:mm:ss A", TimeZone)
                .format("MM/DD/YYYY hh:mm:ss A");
            const localTime = moment(local, "MM/DD/YYYY hh:mm:ss A");
            return localTime.format("llll");
          };

          const dataWithLocalTime = filterdObjects?.map((value) => {
            if (value.ScannedDateTime !== "") {
              return {
                ...value,
                OrderDate: timeUTCtoLocal(value.OrderDate),
                ScannedDateTime: timeUTCtoLocal(value.ScannedDateTime),
              };
            } else {
              return {
                ...value,
                OrderDate: timeUTCtoLocal(value.OrderDate),
              };
            }
          })

          setAttendeeData(dataWithLocalTime);
          setIsSearched(true);
        } else {
          setIsSearched(true);
          setAttendeeData([]);
        }
    } catch (error) {}
  };

  const handleClearSearch = () => {
    setSearchBy("AttendeeName");
    setSearchTerm("");
    GetAllAttendeeByEventId();
  };

  const GetAllAttendeeByEventId = async () => {
    try {
      if (checkedIn.check === "") {
        setLoading(true);
      }
      const { data } = await axiosE.post("GetAllAttendeeByEventId", {
        EventId: EventId,
        PageNumber: page + 1,
        PageSize: rowsPerPage,
      });
      if (data.results.ResponseCode) {
        const timeUTCtoLocal = (date) => {
          var local = momentTz
            .tz(moment.utc(date), "MM/DD/YYYY hh:mm:ss A", TimeZone)
            .format("MM/DD/YYYY hh:mm:ss A");
          const localTime = moment(local, "MM/DD/YYYY hh:mm:ss A");
          return localTime.format("llll");
        };
        const filterdObjects = jsonFormatterHelper(data.results.ListAttendee);
        const dataWithLocalTime = filterdObjects?.map((value) => {
          if (value.ScannedDateTime !== "") {
            return {
              ...value,
              OrderDate: timeUTCtoLocal(value.OrderDate),
              ScannedDateTime: timeUTCtoLocal(value.ScannedDateTime),
            };
          } else {
            return {
              ...value,
              OrderDate: timeUTCtoLocal(value.OrderDate),
            };
          }
        });
        setAttendeeData(dataWithLocalTime);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  React.useImperativeHandle(getAttendeeRef, () => ({
    GetAllAttendeeByEventId,
  }));

  const CheckedIn = async (type, qrid, eventId) => {
    const endPoint = type === "IN" ? "QRCodeCheckedIn" : "QRCodeCheckedOut";
    try {
      const { data } = await axiosE.get(`${endPoint}/${qrid}/${eventId}`);
      if (data.results.ResponseCode) {
        const CurrUTCtime = moment.utc().format("YYYY-MM-DD HH:mm:ss");
        const timeUTCtoLocal = (date) => {
          var local = momentTz
            .tz(moment.utc(date), "MM/DD/YYYY hh:mm:ss a", TimeZone)
            .format("MM/DD/YYYY hh:mm:ss a");
          return moment(local, "MM/DD/YYYY hh:mm:ss a");
        };
        const updatedArray = attendeeData.map((item) => {
          if (item.QRCode === qrid) {
            return {
              ...item,
              Status: type === "IN" ? "CheckedIn" : "CheckedOut",
              ScannedDateTime: type === "IN" ? timeUTCtoLocal(CurrUTCtime) : "",
            };
          }
          return item;
        });
        setAttendeeData(updatedArray);
        ConfirmAttendees();
        setCheckedIn({ check: "", qrid: "", eventId: "" });
      }
    } catch (error) {}
  };

  const scheduleEmailDeleteModal = () => {
    return (
      <Modal
        open={checkedIn.check !== ""}
        onClose={() => setCheckedIn({ check: "", qrid: "", eventId: "" })}
      >
        <div className="delete_modal_wrapper">
          <div
            style={{
              width: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100px",
            }}
          >
            <RiErrorWarningFill size={30} />
          </div>
          <p style={{ textAlign: "center", fontSize: 16 }}>
            {Text.SureToCancelEvent}{" "}
            {checkedIn.check === "IN"
              ? "confirm this attendee?"
              : "unconfirm this attendee?"}
          </p>
          <div style={{ display: "flex" }}>
            <Button
              // className={classes.deleteModalSaveAndCancelButton}
              onClick={() => setCheckedIn({ check: "", qrid: "", eventId: "" })}
              style={{
                color: "blue",
                fontSize: "1.5rem",
                padding: "10px 25px",
              }}
            >
              {Text.Cancel}
            </Button>
            <Button
              // className={classes.deleteModalSaveAndCancelButton}
              onClick={() =>
                CheckedIn(checkedIn.check, checkedIn.qrid, checkedIn.eventId)
              }
              style={{
                color: "red",
                fontSize: "1.5rem",
                padding: "10px 25px",
              }}
            >
              {checkedIn.check === "IN" ? "YES, CONFIRM " : "YES, UNCONFIRM"}
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  const resendOrder = async (id) => {
    try {
      const { data } = await axiosE.post("ResentOrder", { OrderId: id });
      if (data.results.ResponseCode) {

        toast("Tickets were resent successfully!");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const tableData = [
    {
      name: "Val Arzunian",
      email: "val@gmail.com",
      type: "VIP",
      orderId: "7845126",
      orderDate: "3/4/2022",
      gender: "Male",
      city: "Bethlehem",
      status: "Checked in",
      action: "Actions",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJ8NrMScVPAhv_W7joclr21QukbPhkOMorsA&usqp=CAU",
    },
  ];

  function ListItemLink(props) {
    return (
      <ListItem
        primaryTypographyProps={{ style: { fontSize: 14 } }}
        button
        component="a"
        {...props}
      />
    );
  }

  const CreateEventDetailsAboutToolTip = withStyles(() => ({
    tooltip: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: "16px",
      border: "1px solid #dadde9",
      marginTop: "10px",
    },
  }))(Tooltip);

  const titleWithToolTip = (title, mandatory = false, children) => {
    return (
      <span style={{ fontSize: 16, marginTop: "10px", color: "#000000" }}>
        <CreateEventDetailsAboutToolTip
          title={<React.Fragment>{title}</React.Fragment>}
        >
          {children}
        </CreateEventDetailsAboutToolTip>
      </span>
    );
  };

  const [open, setOpen] = useState("");

  const [isCheckedOut, setIsCheckedOut] = useState("");

  UseOutsideClick(
    () => {
      if (open) {
        setOpen("");
      }
    },
    ".Menu-list-wrapper",
    menuRef
  );

  const formatDateWithTimeAndTimeZone = (dateString, timeString, timeZone) => {
    const dateTimeString = `${dateString} ${timeString}`;
    const utcDateTime = momentTz
      .utc(dateTimeString, "MM/DD/YYYY HH:mm:ss")
      .toDate();
    const formattedDate = momentTz(utcDateTime)
      .tz(timeZone)
      .format("ddd, MMM D YYYY, h:mm A");
    return formattedDate;
  };

  const eventDateDetails = (row) => {
    const timeZone = JSON.parse(localStorage.getItem("CurrentTimeZone"));
    if (row) {
      const date = row?.original?.BookingDate?.split(" ")[0];
      const time = row?.original?.BookingTime;
      return formatDateWithTimeAndTimeZone(date, time, timeZone);
    }
  };

  const DEFAULT_COUNTRY_CODE = '1';
  const formatPhoneNumber = (phoneValue) => {

    try {
      // Use libphonenumber-js to parse the phone number
      let phoneNumberObject = parsePhoneNumberFromString(phoneValue);

      if (!phoneNumberObject) {
        let phoneValueWithPlus = "+" + phoneValue
        phoneNumberObject = parsePhoneNumberFromString(phoneValueWithPlus);

        if(!phoneNumberObject) {
          let phoneValueWithPlusWithDefaultCode = "+" + DEFAULT_COUNTRY_CODE + phoneValue
          phoneNumberObject = parsePhoneNumberFromString(phoneValueWithPlusWithDefaultCode);

          if (phoneNumberObject) {
            phoneValue =  phoneNumberObject.formatInternational()
          }
        } else if (!phoneNumberObject.isValid()) {
          let phoneValueWithPlusWithDefaultCode = "+" + DEFAULT_COUNTRY_CODE + phoneValue
          phoneNumberObject = parsePhoneNumberFromString(phoneValueWithPlusWithDefaultCode);

          if (phoneNumberObject) {
            phoneValue =  phoneNumberObject.formatInternational()
          }
        } else {
          phoneValue =  phoneNumberObject.formatInternational()
        }
      }

      // Format the phone number in international format
      phoneValue =  phoneNumberObject.formatInternational()
    } catch (error) {
      // Handle any errors here
      console.error('Error formatting phone number:', error);
      return phoneValue;
    }

    return phoneValue;
  };

  const generatePDF = async (details) => {
    const doc = new jsPDF();

    // Add the image (assuming it's a local image, or you can fetch it and pass the data URL)
    const img = new Image();
    img.src = ubmeLogo;

    // List of fields to exclude
    const fieldsToExclude = [
      "TotalCounts", "Title", "Status", "OrderDate", "EventId", "PdfFile", "OrderId",
      "ScannedDateTime", "QRCode", "Amount", "BookingDate", "BookingTime", "CurrencyCode",
      "CurrencySymbol", "DiscountAmount", "DiscountCode", "Price", "Re_ScannedDateTime",
      "RecurringInterval", "RecurringTicket", "TaxAmount", "TaxName", "UBMeFeeAmount",
      "isAbsorb", "Terms_And_Conditions", "TermsAndConditions", "SlotPerBundle", "isBundle",
      "TicketDescription", "Notes",
    ];

    // Filter out excluded fields
    const filteredDetails = Object.keys(details)
        .filter(key => !fieldsToExclude.includes(key))
        .reduce((obj, key) => {
          obj[key] = details[key];
          return obj;
        }, {});

    // Arrange FullName, Email, and Phone at the top
    const orderedFields = [
      "FullName", "Email", "Phone",
      ...Object.keys(filteredDetails).filter(key => !["FullName", "Email", "Phone"].includes(key))
    ];

    img.onload = () => {
      const imgWidth = 40; // Image width in the PDF
      const imgHeight = (img.height * imgWidth) / img.width; // Maintain aspect ratio

      // Center the image
      const imgX = (doc.internal.pageSize.width - imgWidth) / 2;
      const imgY = 10; // Vertical position

      doc.addImage(img, 'JPEG', imgX, imgY, imgWidth, imgHeight);

      // Add the Event Name (Title) below the image
      const title = Title || "Event Title"; // Ensure there is a title to display
      doc.setFontSize(16); // Slightly larger font for the title
      doc.setFont("helvetica", "bold"); // Bold font for title

      const titleY = imgY + imgHeight + 10; // Vertical position for the title
      doc.text(title, doc.internal.pageSize.width / 2, titleY, { align: "center" });

      // Format the OrderDateTime
      const orderDateTime = details.OrderDate;

      // Add the OrderDateTime below the event name, in gray and smaller font
      doc.setFontSize(10); // Smaller font for OrderDateTime
      doc.setFont("helvetica", "normal"); // Normal font for OrderDateTime
      doc.setTextColor(128, 128, 128); // Gray color

      const dateTimeY = titleY + 6; // Vertical position for the OrderDateTime
      doc.text(orderDateTime, doc.internal.pageSize.width / 2, dateTimeY, { align: "center" });

      // Reset the text color to black for the rest of the content
      doc.setTextColor(0, 0, 0);

      // Set position below the image for the table
      let y = dateTimeY + 15;

      const pageHeight = doc.internal.pageSize.height;
      const leftColumnX = 20;
      const maxLineWidth = 160; // Max width for the question and answer text
      const padding = 1; // Add padding between text and cell border
      const extraSpaceAfterAnswer = 7; // Extra space after the answer (adjust this value)

      doc.setFontSize(12);

      orderedFields.forEach((key) => {
        if (filteredDetails[key]) {
          // Format the Phone field using the formatPhoneNumber function
          let answer = filteredDetails[key].toString(); // Get answer value
          if (key === "Phone") {
            answer = formatPhoneNumber(answer); // Format the phone number
          }

          const question = key === "FullName" ? "Full Name" : key.replace(/_/g, " "); // Format key for display

          // Split long text into multiple lines for the first column (question)
          const questionLines = doc.splitTextToSize(question, maxLineWidth);
          const questionHeight = questionLines.length * 6; // Approximate line height for the question

          // Split long text into multiple lines for the second column (answer)
          const answerLines = doc.splitTextToSize(answer, maxLineWidth); // Adjust based on available width
          const answerHeight = answerLines.length * 6; // Approximate line height for the answer

          // Check if we need to add a new page
          const requiredHeight = questionHeight + answerHeight + padding + extraSpaceAfterAnswer;
          if (y + requiredHeight > pageHeight) { // 20px margin at the bottom
            doc.addPage(); // Add new page
            y = 20; // Reset y position to top of new page
          }

          // Add the question text in bold
          doc.setFont("helvetica", "bold"); // Bold font for question
          doc.text(questionLines, leftColumnX, y); // Display question

          // Move the y-position down for the answer (add some space after the question)
          y += questionHeight + 2;

          // Add the answer text below the question
          doc.setFont("helvetica", "normal"); // Normal font for answer
          doc.text(answerLines, leftColumnX, y); // Display answer below the question

          // Move the cursor down for the next row (question-answer pair) with extra space after the answer
          y += answerHeight + padding + extraSpaceAfterAnswer;
        }
      });

      // Get FullName for file name
      const fullName = details.FullName || "output";

      // Save the PDF with the FullName
      doc.save(`${fullName}.pdf`);
    };
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ATTENDEE NAME ",
        accessor: "FullName", // accessor is the "key" in the data
        width: 200,
        Cell: ({ row }) => {
          return <p>{row?.original?.FullName}</p>;
        },
      },
      {
        Header: "EMAIL",
        accessor: "Email",
        width: 200,
      },
      {
        Header: "TICKET TYPE",
        accessor: "Title", // accessor is the "key" in the data
        width: 200,
      },
      {
        Header: "ORDER #",
        accessor: "OrderId",
        width: 200,
      },
      {
        Header: "TICKET #",
        accessor: "QRCode",
        width: 200,
      },
      {
        Header: "ORDER DATE",
        accessor: "OrderDate", // accessor is the "key" in the data
        width: 300,
        Cell: ({ row }) => {
          return <p>{moment(row.original.OrderDate).format("llll")}</p>;
        },
      },
      // {
      //   Header: "GENDER",
      //   accessor: "gender",
      // },
      // {
      //   Header: "HOMETOWN",
      //   accessor: "city", // accessor is the "key" in the data
      // },
      {
        Header: "TIMESLOT",
        accessor: "", // accessor is the "key" in the data
        width: 200,
        Cell: ({ row }) => {
          return (row.original.RecurringTicket === null || row.original.RecurringTicket === "False") ? (
            "--"
          ) : (
            <p>{eventDateDetails(row)}</p>
          );
        },
      },
      {
        Header: "STATUS",
        accessor: "ScannedDateTime",
        width: 200,
        Cell: ({ row }) => {
          // convert UTC time into LOCAL
          // const timeUTCtoLocal = (date) => {
          //   var local = momentTz
          //     .tz(moment.utc(date), "MM/DD/YYYY hh:mm:ss a", TimeZone)
          //     .format("MM/DD/YYYY hh:mm:ss a");
          //   return moment(local, "MM/DD/YYYY hh:mm:ss a");
          // };

          return (
            <div>
              {(row.original.ScannedDateTime === "" &&
                row.original.Status === "CheckedOut") ||
              (row.original.ScannedDateTime === "" &&
                row.original.Status === "Draft") ||
              (row.original.ScannedDateTime === "" &&
                row.original.Status === "") ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    backgroundColor: "green",
                    borderRadius: "10px",
                  }}
                >
                  <p
                    onClick={() =>
                      setCheckedIn({
                        check: "IN",
                        qrid: row.original.QRCode,
                        eventId: row.original.EventId,
                      })
                    }
                    style={{
                      color: "white",
                      padding: "7px",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                  >
                    {Text.Confirm}
                  </p>
                </div>
              ) : row.original.Status === "Refunded" ||
                row.original.Status === "fully Refund" ||
                row.original.Status === "Canceled" ? (
                <div>
                  <p
                    style={{
                      padding: "7px",
                      textAlign: "center",
                      color: "#960018",
                    }}
                  >
                    {row.original.Status}
                  </p>
                </div>
              ) : (
                <div>
                  {titleWithToolTip(
                    'To unconfirm attendee, click "Select" and choose unconfirm.',
                    false,
                    <div
                      style={{ border: "1px solid gray", borderRadius: "10px" }}
                    >
                      <p style={{ padding: "7px", textAlign: "center" }}>
                        Confirmed at{" "}
                        {moment(row.original.ScannedDateTime).format("hh:mm a")}
                      </p>
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        },
      },
      {
        Header: "ACTIONS",
        accessor: "action",
        width: 200,
        Cell: ({ row }) => {
          return (
            <div>
              <div
                onClick={() => setOpen(row.original.QRCode)}
                style={{
                  border: "2px solid rgb(170 170 170 / 40%)",

                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingRight: "5px",
                  paddingLeft: "5px",
                  cursor: "pointer",
                  width: 100,
                }}
              >
                <p
                  style={{
                    padding: "5px",
                    // textAlign: "left",
                    textAlign: "center",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Select
                </p>
                <AiFillCaretDown />
              </div>
              {open === row.original.QRCode ? (
                <div className="Menu-list-wrapper">
                  <div
                    className="Menu-list-popover"
                    style={{
                      width: 150,
                      top: row.original.Status !== "Refunded" ? -115 : 0,
                    }}
                  >
                    <div>
                      <List component="nav" aria-label="main mailbox folders">
                        {row.original.Status !== "Refunded" && (
                          <ListItem
                            onClick={() => {
                              setOpen(false);
                              resendOrder(row.original.OrderId);
                            }}
                            button
                          >
                            <ListItemText
                              primaryTypographyProps={{
                                style: { fontSize: 14 },
                              }}
                              primary="Resend Tickets"
                            />
                          </ListItem>
                        )}

                        <ListItem
                          onClick={() => {
                            setIsOpen(true);
                            setModalType("order");
                            setOrderId({
                              orderId: row.original.OrderId,
                              status: row.original.Status,
                            });
                            setOpen(false);
                            // cloneThisEvent(row.original.EventId);
                          }}
                          button
                        >
                          <ListItemText
                            primaryTypographyProps={{ style: { fontSize: 14 } }}
                            primary="Order Details"
                          />
                        </ListItem>
                        {row.original.Status !== "Refunded" && (
                          <ListItem
                            onClick={() => {
                              setIsOpen(true);
                              setModalType("edit");
                              setEditAdditionalinfo(row.original);
                              setOpen(false);
                            }}
                            button
                          >
                            <ListItemText
                              primaryTypographyProps={{
                                style: { fontSize: 14 },
                              }}
                              primary="Edit Attendee"
                            />
                          </ListItem>
                        )}
                        <ListItem
                            onClick={() => {
                              // setIsOpen(true);
                              // setModalType("edit");
                              // setEditAdditionalinfo(row.original);
                              setOpen(false);
                              generatePDF(row.original);
                            }}
                            button
                        >
                          <ListItemText
                              primaryTypographyProps={{
                                style: { fontSize: 14 },
                              }}
                              primary="Print Attendee"
                          />
                        </ListItem>
                        {row.original.Status !== "Refunded" && (
                          <ListItemLink
                            target="_blank"
                            href={`${row.original.PdfFile}`}
                          >
                            <ListItemText
                              primaryTypographyProps={{
                                style: { fontSize: 14 },
                              }}
                              primary="Print Tickets"
                            />
                          </ListItemLink>
                        )}

                        {row.original.Status === "CheckedIn" && (
                          <ListItem
                            onClick={() => {
                              setCheckedIn({
                                check: "OUT",
                                qrid: row.original.QRCode,
                                eventId: row.original.EventId,
                              });
                              setOpen(false);
                            }}
                            button
                          >
                            <ListItemText
                              primaryTypographyProps={{
                                style: { fontSize: 14 },
                              }}
                              primary="Unconfirm"
                            />
                          </ListItem>
                        )}
                        <ListItem
                            onClick={() => {
                              setIsOpen(true);
                              setModalType("notes");
                              setOrderId({
                                orderId: row.original.OrderId,
                                status: row.original.Status,
                              });
                              setOpen(false);
                            }}
                            button
                        >
                          <ListItemText
                              primaryTypographyProps={{
                                style: { fontSize: 14 },
                              }}
                              primary="Notes"
                          />
                        </ListItem>
                      </List>

                      {/* <List component="nav" aria-label="secondary mailbox folders">

      </List> */}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          );
        },
      },
    ],
    [anchorEl, open]
  );

  function Table({ columns, data }) {
    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable({
        columns,
        data,
      });

    const sanitizeName = (name) => {
      return name.replace("'", "&#39;");
    };

    // Render the UI for your table
    return (
      <table className="tickets_table" {...getTableProps()}>
        <thead className="tickets_table_thead">
          {headerGroups.map((headerGroup, i) => (
            <tr
              key={i}
              style={{ backgroundColor: "#ffffff" }}
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column, i) => (
                <th
                  className="tickets_table_th"
                  key={i}
                  style={{
                    fontSize: 14,
                    paddingBottom: 20,
                    textAlign: "center",
                  }}
                  {...column.getHeaderProps({})}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);

            return (
              <tr className="ticket-table-tr" key={i} {...row.getRowProps()}>
                {row.cells.map((cell, i) => {
                  return (
                    <td
                      ref={menuRef}
                      key={i}
                      data-label={cell.column.Header}
                      style={{
                        textAlign: "center",
                      }}
                      className={`ticket_td text-align-td text-white ${
                        cell.column.className ?? ""
                      }`}
                      {...cell.getCellProps({
                        style: {
                          width: cell.column.width,
                        },
                      })}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  const removeUnwantedProperties = (obj, propertiesToRemove) => {
    const filteredObject = { ...obj };

    propertiesToRemove.forEach((property) => {
      delete filteredObject[property];
    });

    return filteredObject;
  };

  function areArraysEqual(arr1, arr2) {
    return JSON.stringify(arr1) === JSON.stringify(arr2);
  }

  const headerCellStyle = (backgroundColor, fontColor, isToWrapText = false) => {
    return {
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: backgroundColor }
      },
      alignment: {
        horizontal: "center",
        wrapText: isToWrapText
      },
      font: { color: { argb: fontColor } }
    };
  };

  // Function to format currency values
  function formatCurrency(value, currencySymbol) {
    // Format the number as currency
    return currencySymbol + Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(Number(value));
  }

  const downloadEmailInvitationContacts = async () => {
    try {
      // Fetch Attendees list
      const { data } = await axiosE.get(`DownloadAllAttendeeByEventId/${EventId}`);

      const timeUTCtoLocal = (date) => {
        var local = moment.tz(moment.utc(date), "MM/DD/YYYY hh:mm:ss a", TimeZone).format("MM/DD/YYYY hh:mm:ss a");
        return moment(local, "MM/DD/YYYY hh:mm:ss a").format("llll");
      };

      if (data?.results?.ListAttendee?.length > 0) {
        const filterdObjects = jsonFormatterHelper(data.results.ListAttendee);

        // Create a new workbook
        const workbook = new ExcelJS.Workbook();

        // Add a worksheet to the workbook
        const worksheet = workbook.addWorksheet("Attendee List");

        // Set column headers and make them bold
        const predefinedColumns = [
          { header: "Order ID", key: "OrderId", width: 15 },
          { header: "Event ID", key: "EventId", width: 15 },
          { header: "Full Name", key: "FullName", width: 20 },
          { header: "Email", key: "Email", width: 25 },
          { header: "Phone", key: "Phone", width: 15 },
          { header: "Order Date", key: "OrderDate", width: 20 },
          { header: "Confirmed Date/Time", key: "ScannedDateTime", width: 20 },
          { header: "Status", key: "Status", width: 15 },
          { header: "Ticket Type", key: "TicketType", width: 30 },
          { header: "Ticket Quantity", key: "TicketQuantity", width: 15 },
          { header: "Ticket ID", key: "TicketId", width: 15 },
          { header: "Ticket Cost", key: "TicketCost", width: 15 },
          { header: "Discount", key: "Discount", width: 15 },
          { header: "Discount Code", key: "DiscountCode", width: 15 },
          { header: filterdObjects[0].TaxName !== '' ? filterdObjects[0].TaxName : "Tax", key: "Tax", width: 15 },
          { header: "UBMe Fee", key: "UBMeFee", width: 15 },
          { header: "Order Total", key: "OrderTotal", width: 15 },
          { header: "Order PDF", key: "OrderPDF", width: 50 },
        ];

        // Convert UTC OrderDate to local time in sortedObjects
        const sortedObjects = filterdObjects.map((obj) => {
          const localOrderDate = timeUTCtoLocal(obj.OrderDate); // Assuming the OrderDate field contains UTC timestamps
          return { ...obj, OrderDate: localOrderDate };
        });

        // Sort the sortedObjects array in descending order based on OrderDate
        sortedObjects.sort((a, b) => new Date(b.OrderDate) - new Date(a.OrderDate));

        // Define a list of columns to exclude
        const columnsToExclude = [
          "Amount",
          "BookingDate",
          "BookingTime",
          "CurrencyCode",
          "CurrencySymbol",
          "DiscountAmount",
          "DiscountCode",
          "Email",
          "EventId",
          "FullName",
          "OrderDate",
          "OrderId",
          "PdfFile",
          "Phone",
          "QRCode",
          "Price",
          "RecurringInterval",
          "RecurringTicket",
          "ScannedDateTime",
          "Re_ScannedDateTime",
          "Status",
          "TaxAmount",
          "TaxName",
          "Title",
          "TotalCounts",
          "UBMeFeeAmount",
          "isAbsorb",
          "Terms_And_Conditions",
          "TermsAndConditions",
          "isBundle",
          "SlotPerBundle",
          "TicketDescription",
          "Notes",
        ];

        // Define additional columns by filtering out excluded columns
        const additionalColumns = Object.keys(sortedObjects[0]).filter((columnName) => {
          return !columnsToExclude.includes(columnName);
        }).map((columnName) => ({
          header: columnName.replace(/_/g, ' '), // Display name for the column
          key: columnName,    // Unique identifier for the column
          width: 15,          // Optional: specify the column width
        }));

        // Combine predefined and additional columns for the header row
        const allHeaders = predefinedColumns.concat(additionalColumns);

        // Extract keys from allHeaders
        const allHeadersKeys = allHeaders.map(header =>  header.header);

        // Add the header row for detailed headers (5th row) with headers and widths
        const headerRow = worksheet.addRow(allHeadersKeys.map(key => key)); // Include allHeadersKeys here if needed
        headerRow.font = { bold: true };

        // Loop through each cell in the header row and set wrapText alignment
        // headerRow.eachCell({ includeEmpty: true }, (cell) => {
        //   cell.alignment = { wrapText: true };
        // });

        let currencySymbol = ""

        // Group rows by orderId and collect ticket details for each orderId
        const groupedRows = sortedObjects.reduce((acc, row) => {
          const key = `${row.OrderId}-${row.FullName}-${row.Email}-${row.Phone}-${row.OrderDate}`;
          if (!acc[key]) {
            acc[key] = { ...row, TicketType: [], TicketId: [], TicketCost: [], UBMeFee: [], TicketStatuses: [], TaxFee: [], DiscountCode: [], DiscountAmount: [], TicketDetails: [] };
          }

          currencySymbol = row.CurrencySymbol;

          const ticketType = (row.Status === 'Refunded') ? `${row.Title} (Refunded)` : row.Title;

          acc[key].TicketType.push(ticketType);
          acc[key].TicketId.push(row.QRCode);
          acc[key].TicketStatuses.push(row.Status);

          const ticketDetail = {
            TicketName: row.Title,
            TicketId: row.QRCode,
            ConfirmedDateTime: row.ScannedDateTime !== "" ? timeUTCtoLocal(row.ScannedDateTime) : "",
            ReScannedDateTime: [],  // Store multiple ReScannedDateTime values
          };

          // Parse the ReScannedDateTime JSON string into an array of objects
          let reScannedDateTimeArray = [];
          try {
            reScannedDateTimeArray = JSON.parse(row.Re_ScannedDateTime);
          } catch (error) {
            console.error("Error parsing Re_ScannedDateTime:", error);
          }

          // Check if ReScannedDateTime is available and push it to the array
          reScannedDateTimeArray.forEach(entry => {
            ticketDetail.ReScannedDateTime.push(timeUTCtoLocal(entry.ScannedDateTime));
          });

          acc[key].TicketDetails.push(ticketDetail);

          acc[key].TicketCost.push((row.Price !== "" && row.Price !== '0') ? formatCurrency(Number(row.Price), currencySymbol) : "Free");
          acc[key].DiscountCode.push(row.DiscountCode);
          // acc[key].DiscountAmount.push(row.DiscountAmount);

          // Add Discount Amount value only once for each unique ticket type
          if (!acc[key].DiscountAmount[ticketType] && row.DiscountAmount !== "") {
            acc[key].DiscountAmount[ticketType] =  (row.isBundle === "True") ? (parseFloat(row.DiscountAmount.replace(currencySymbol, "").replace(",", "")) * row.SlotPerBundle).toString() : row.DiscountAmount
          }

          // Add UBMe fee value only once for each unique ticket type
          if (!acc[key].UBMeFee[ticketType]) {
            acc[key].UBMeFee[ticketType] = (row.isAbsorb !== '1' ? (row.isBundle === "True")? (parseFloat(row.UBMeFeeAmount.replace(currencySymbol, "").replace(",", "")) * row.SlotPerBundle).toString() : row.UBMeFeeAmount : "")
          }

          // Add Tax fee value only once for each unique ticket type
          if (!acc[key].TaxFee[ticketType] && row.TaxAmount !== "") {
            acc[key].TaxFee[ticketType] = (row.isBundle === "True") ? (parseFloat(row.TaxAmount.replace(currencySymbol, "").replace(",", "")) * row.SlotPerBundle).toString() : row.TaxAmount
          }

          return acc;
        }, {});

        // Add data rows and apply alternate row background color
        let prevOrderId = null;
        let rowIndex = 6;
        Object.values(groupedRows).forEach((rowData, index) => {

          const {
            OrderId,
            FullName,
            Email,
            Phone,
            EventId,
            OrderDate,
            TicketDetails,
            TicketType,
            TicketId,
            TicketCost,
            TicketStatuses,
            DiscountAmount,
            DiscountCode,
            TaxFee,
            UBMeFee,
            Amount,
            PdfFile,
            isBundle,
            SlotPerBundle
          } = rowData;

          // Initialize an object to store TicketId and corresponding Rescanned Date/Time
          const ticketRescannedData = {};

          // Loop through TicketDetails to group "TicketId" and "Rescanned Date/Time" values
          TicketDetails.forEach((ticketDetail) => {
            const ticketId = ticketDetail.TicketId;
            const ticketName = ticketDetail.TicketName;
            const confirmedDateTime = ticketDetail.ConfirmedDateTime
            const reScannedDateTimeValues = ticketDetail.ReScannedDateTime.join("\n"); // Join with line breaks

            if (!ticketRescannedData[ticketId]) {
              ticketRescannedData[ticketId] = {
                TicketName: ticketName,
                ConfirmedDateTime: confirmedDateTime,
                RescannedDateTimes: [],
              };
            }

            // Add ConfirmedDateTime at the beginning of the RescannedDateTimes array
            ticketRescannedData[ticketId].RescannedDateTimes.unshift(confirmedDateTime);

            ticketRescannedData[ticketId].RescannedDateTimes = ticketRescannedData[ticketId].RescannedDateTimes.concat(reScannedDateTimeValues);
          });

          const ubmeFeeValues = Object.values(UBMeFee).map(value => {
            const amount = parseFloat(value.replace(currencySymbol, "").replace(",", ""));
            return isNaN(amount) ? 0 : amount;
          });

          const taxFeeValues = Object.values(TaxFee).map(value => {
            const amount = parseFloat(value.replace(currencySymbol, "").replace(",", ""));
            return isNaN(amount) ? 0 : amount;
          });

          const nonEmptyDiscountCode = DiscountCode.find(code => code !== "");

          const nonEmptyDiscountAmount = Object.values(DiscountAmount).map(value => {
            const amount = parseFloat(value.replace(currencySymbol, "").replace(",", ""));
            return isNaN(amount) ? 0 : amount;
          });

          // const nonEmptyDiscountAmount = DiscountAmount.find(amount => amount !== "");

          let rescannedDateTimeValues = "";
          rescannedDateTimeValues = Object.keys(ticketRescannedData).map((id) => {
            const rescannedData = ticketRescannedData[id];
            if (rescannedData.RescannedDateTimes[0] !== undefined && rescannedData.RescannedDateTimes[0] !== "") {
              return `${rescannedData.TicketName}:\n${id}:\n${rescannedData.RescannedDateTimes.join("\n")}`;
            }
          }).filter(Boolean).join("\n\n");

          const ticketStatuses = [...new Set(TicketStatuses)]; // Remove duplicate statuses
          let formattedStatus = "";

          if (ticketStatuses.every(status => status === "Refunded")) {
            formattedStatus = "Refunded"; // All tickets refunded
          } else if (ticketStatuses.every(status => status === "CheckedIn")) {
            formattedStatus = ""; // All tickets checked in
          } else if (ticketStatuses.includes("Refunded")) {
            formattedStatus = "Partially Refunded"; // Some tickets refunded
          } else if (ticketStatuses.includes("CheckedIn")) {
            formattedStatus = ""; // Some tickets checked in
          } else {
            formattedStatus = ""; // Add more cases if needed
          }

          // Calculate the sum of UBMeFee values
          const DiscountAmountSum = nonEmptyDiscountAmount.reduce((sum, value) => sum + value, 0);

          // Calculate the sum of UBMeFee values
          const ubmeFeeSum = ubmeFeeValues.reduce((sum, value) => sum + value, 0);

          // Calculate the sum of TaxFee values
          const taxFeeSum = taxFeeValues.reduce((sum, value) => sum + value, 0);

          // Define the array of values for predefined columns
          const predefinedDataArray = [
            OrderId,
            EventId,
            FullName,
            Email,
            Phone,
            OrderDate,
            rescannedDateTimeValues,
            formattedStatus,
            TicketType.join("\n"),
            TicketType.length,
            TicketId.join("\n"),
            TicketCost.join("\n"),
            (DiscountAmountSum !== undefined && DiscountAmountSum !== "" && DiscountAmountSum !== 0) ? formatCurrency(DiscountAmountSum, currencySymbol) : "",
            (nonEmptyDiscountCode !== undefined && nonEmptyDiscountCode !== "") ? nonEmptyDiscountCode : "",
            (taxFeeSum !== undefined && taxFeeSum !== "" && taxFeeSum !== 0) ? formatCurrency(taxFeeSum, currencySymbol) : "",
            (ubmeFeeSum !== 0) ? formatCurrency(ubmeFeeSum, currencySymbol) : "",
            (Amount !== "" && Amount !== '0') ? formatCurrency(Amount, currencySymbol) : "Free",
            PdfFile
          ];

          // Create an array for additional column data based on column order
          const additionalDataArray = additionalColumns.map(column => rowData[column.key] || '');

          // Combine predefined and additional data arrays
          const rowDataArray = predefinedDataArray.concat(additionalDataArray);

          // Add the row to the worksheet
          const orderRow = worksheet.addRow(rowDataArray);

          // Apply alternate row background color
          orderRow.fill = {type: "pattern", pattern: "solid", fgColor: {argb: index % 2 === 0 ? "FFFFFF" : "EFEFEF"}};

          // Get the column index for "OrderPDF"
          const orderPdfColumnIndex = predefinedColumns.findIndex(header => header.key === "OrderPDF");
          let orderPdfCell = orderRow.getCell(orderPdfColumnIndex + 1);
          if (orderPdfCell.value) {
            const hyperlinkText = orderPdfCell.value;
            orderPdfCell.value = {
              text: hyperlinkText,
              hyperlink: hyperlinkText, // Provide the URL here
            };
            orderPdfCell.alignment = {wrapText: true, horizontal: "center"};
            orderPdfCell.font = {color: {argb: "0000FF"}, underline: true};
          } else {
            orderPdfCell.value = ""; // Empty cell if no PDF link
          }

          if (prevOrderId !== OrderId) {
            prevOrderId = OrderId;
          }

          rowIndex++;
        });

        // Set specific column widths based on column index
        worksheet.columns.forEach((column, columnIndex) => {
          if (columnIndex === 0 || columnIndex === 1 || columnIndex === 2 || columnIndex === 6 || columnIndex === 7 || columnIndex === 8 || columnIndex === 10) {
            column.width = 40;
          } else if (columnIndex === 3 || columnIndex === 4 || columnIndex === 5 ) {
            column.width = 25;
          } else if (columnIndex === 17) {
            column.width = 100;
          } else {
            column.width = 15
          }
        });

        // Function to apply center alignment to specified columns
        const applyCenterAlignmentToColumns = (worksheet, columnKeys) => {
          columnKeys.forEach((key) => {
            const column = worksheet.getColumn(key);
            column.eachCell((cell) => {
              cell.alignment = { horizontal: "center", vertical: 'middle', wrapText: true };
            });
          });
        };

        // Call the function to apply center alignment to "TicketCost" and "Discount" columns
        applyCenterAlignmentToColumns(worksheet, [10, 12, 13, 14, 15, 16, 17]);

        // Function to apply bold and green font to "OrderTotal" column
        const applyBoldAndGreenFontToColumn = (worksheet, columnKey) => {
          const column = worksheet.getColumn(columnKey);
          column.eachCell((cell, rowNumber) => {
            if (rowNumber !== 1) {
              cell.font = {bold: true, color: {argb: "00AA00"}}; // Green color code
            }
          });
        };

        // Call the function to apply bold and green font to "OrderTotal" column
        applyBoldAndGreenFontToColumn(worksheet, 17);

        // Generate XLSX file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        FileSaver.saveAs(blob, `${Title}.xlsx`);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const downloadPDF = async () => {
    try {
      // Fetch Attendees list
      const { data } = await axiosE.get(`DownloadAllAttendeeByEventId/${EventId}`);

      const timeUTCtoLocal = (date) => {
        var local = moment.tz(moment.utc(date), "MM/DD/YYYY hh:mm:ss a", TimeZone).format("MM/DD/YYYY hh:mm:ss a");
        return moment(local, "MM/DD/YYYY hh:mm:ss a").format("llll");
      };

      if (data?.results?.ListAttendee?.length > 0) {
        const filteredObjects = jsonFormatterHelper(data.results.ListAttendee);

        // Set column headers
        const allHeaders = [
          { header: "", key: "CheckMark"},
          { header: "Order ID", key: "OrderId"},
          { header: "Full Name", key: "FullName"},
          { header: "Email", key: "Email"},
          { header: "Phone", key: "Phone"},
          { header: "Ticket Type", key: "TicketType"},
        ];

        // Convert UTC OrderDate to local time in sortedObjects
        const sortedObjects = filteredObjects.map((obj) => {
          const localOrderDate = timeUTCtoLocal(obj.OrderDate); // Assuming the OrderDate field contains UTC timestamps
          return { ...obj, OrderDate: localOrderDate };
        });

        // Sort the sortedObjects array in descending order based on OrderDate
        sortedObjects.sort((a, b) => new Date(b.OrderDate) - new Date(a.OrderDate));

        // Define rows data
        const rows = sortedObjects.map((attendee, index) => [
          "",
          attendee.OrderId,
          attendee.FullName,
          attendee.Email,
          attendee.Phone,
          attendee.Title,
        ]);

        // Define the font you want to use
        const fontName = "Arial";

        // Create a new PDF document
        const doc = new jsPDF('landscape');
        doc.setFont(fontName);

        // Center-align the title
        const titleWidth = doc.getStringUnitWidth(Title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        const titleX = (doc.internal.pageSize.width - titleWidth) / 2;
        // Set text color to red
        doc.setTextColor('#851679');
        doc.text(Title, titleX, 10);
        doc.setFontSize(18)

        // change back to what we want for other stuff
        doc.setFontSize(12);
        doc.setTextColor(0, 0, 0);

        // Format event start date-time and end date-time
        const eventStart = moment.tz(StartDateTime, TimeZone).format("dddd, MMMM DD, YYYY hh:mm A");
        let eventEnd = moment.tz(EndDateTime, TimeZone).format("dddd, MMMM DD, YYYY hh:mm A");
        // Display end time only if it's different from the start date
        if (moment(StartDateTime).format("YYYY-MM-DD") === moment(EndDateTime).format("YYYY-MM-DD")) {
          eventEnd = moment.tz(EndDateTime, TimeZone).format("hh:mm A");
        }

        // Center-align the start and end date-time
        const eventDateTimeWidth = doc.getStringUnitWidth(eventStart + " - " + eventEnd) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        const eventDateTimeX = (doc.internal.pageSize.width - eventDateTimeWidth) / 2;
        doc.text(eventStart + " - " + eventEnd, eventDateTimeX, 20);

        // Customize the content of the cell using the didParseCell hook
        const didParseCell = function (data) {
          data.cell.styles.font = fontName;
        };

        // Draw rectangle in the first column after cell is drawn
        const didDrawCell = function (data) {
          if (data.column.index === 0 && data.section === 'body') { // Draw for data rows only
            const padding = 2; // Adjust padding as needed
            const cellWidth = data.cell.width; // Increase cell width by 10 units
            const cellHeight = data.cell.height;
            const startX = data.cell.x + padding;
            const startY = data.cell.y + padding;
            const rectSize = Math.min(cellWidth, cellHeight) - (2 * padding); // Choose the smaller dimension as the size of the square
            doc.rect(startX, startY, rectSize, rectSize);
          }
        };

        // Generate autoTable after event date-time
        doc.autoTable({
          headStyles: {
            fillColor: '#851679'
          },
          head: [allHeaders.map(header => header.header)],
          body: rows,
          startY: 40,
          columnStyles: {
            0: { // index of the first column
              cellWidth: 8 // specify the width for the first column
            }
          },
          didParseCell: didParseCell,
          didDrawCell: didDrawCell,
        });

        // Add footer with page numbers
        const totalPages = doc.internal.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
          doc.setPage(i);
          doc.text('Page ' + i + ' of ' + totalPages, doc.internal.pageSize.width / 2, doc.internal.pageSize.height - 10, { align: 'center' });
        }

        // Save the PDF
        doc.save(`${Title}.pdf`);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div>
      {scheduleEmailDeleteModal()}
      <SearchBar
        {...{
          isSearched,
          setIsSearched,
          searchTerm,
          setSearchTerm,
          setSearchBy,
          searchBy,
          handleSearchAttendees,
          handleClearSearch,
        }}
      />

      <div className="eventTable">
        <div className="eventAttendenceTable">
          <span style={{ fontSize: "20px" }}>
            {Text.Attendees}:{" "}
            <b>
              {attendeeData[0]?.TotalCounts
                ? Intl.NumberFormat().format(attendeeData[0]?.TotalCounts)
                : 0}
            </b>
          </span>
          <div style={{display: "flex"}}>
            <span
                className="DownloadAttendenceList"
                style={{
                  backgroundColor:
                      attendeeData?.length > 0 ? "#ca3dd9" : "rgb(236, 236, 236)",
                  marginRight: "10px"
                }}
                onClick={
                  pdfTitle === "Downloading"
                      ? undefined
                      : attendeeData?.length > 0
                          ? downloadPDF
                          : undefined
                }
            >
              {pdfTitle}
            </span>
            <span
              className="DownloadAttendenceList"
              style={{
                backgroundColor:
                  attendeeData?.length > 0 ? "#ca3dd9" : "rgb(236, 236, 236)",
              }}
              onClick={
                csvTitle === "Downloading"
                  ? undefined
                  : attendeeData?.length > 0
                  ? downloadEmailInvitationContacts
                  : undefined
              }
            >
              {csvTitle}
            </span>
          </div>
        </div>
        <div className="tableData">
          {loading ? (
            <div
              style={{
                width: "100%",
                height: "200px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress size={50} color="secondary" />
            </div>
          ) : (
            <div>
              {attendeeData?.length > 0 ? (
                <div>
                  <Table columns={columns} data={attendeeData} />
                  <div className="custom-pagination">
                    <TablePagination
                      size={50}
                      rowsPerPageOptions={[]}
                      component="div"
                      count={Number(attendeeData[0]?.TotalCounts)}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      showFirstButton
                      showLastButton
                    />
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    width: "100%",
                    height: "20vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h1 style={{ color: "#808080", textAlign: "center" }}>
                    {Text.NoAttendeeFound}
                  </h1>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventAttendeeTable;
