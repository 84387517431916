import React, { useEffect, useState } from "react";
import Grid from "styles/Grid.jsx";
import "./event.css";
import { GetUserProfileDetail, LoginPopup, logoutAction } from "Actions";
import { AddToFavorite, SetCardData } from "Actions/favoriteAction";
import EventDetail from "../EventDetail/EventDetail";
import EventMap from "../EventMap/EventMap";
import EventHeader from "../HeroSection/EventHeader";
import Sponsors from "../Sponsors/Sponsors";
import Faq from "../FAQ/Faq";
import EventFooter from "../EventFooter/EventFooter";
import Agenda from "../Agenda/Agenda";
import Vendors from "../Vendors/Vendors";
import { history } from "store";
import { withRouter } from "react-router-dom";

import {
  GetEventMasterDetailsById,
  currentEventTicketDetails,
  EventSearch,
  GetAllEvents,
  ticktesCartItem,
  GetDiscountData,
} from "Actions/eventActions";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import Footer from "Components/UBMeEvents/Events/EventFooter/Footer/Footer";
import EventNavbar from "../EventNavbar/EventNavbar";
import Aboutapp from "../EventFooter/Aboutapp";
import axios from "axios";
import { routeName } from "Service/env";
import * as constants from "Constants";
import { axiosE } from "Service/service";
import moment from "moment";
import "moment-timezone";
import { CircularProgress } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import CheckOutModal from "../CheckOutModal/CheckOutModal";
import MetaDecorator from "Components/UBMeEvents/Components_UBMeEvents/Meta/MetaDecorator";
import axiosI from "Service/service";
import { removeEmptyDialog } from "../../../../Actions/messagesAction";
import Modal from "@material-ui/core/Modal";
import Card from "../../../../Helper/CardWrapper/cardWrapper";
import WebView from "../../../BusinessPortal/Messages/WebView";
import jwt_decode from "jwt-decode";

const Events = ({
  match,
  dispatch,
  GetEventMasterDetailsById,
  currentEventDetails,
  isLoggedIn,
  cardData,
  results,
  favoriteData,
  eventSearchData,
  GetAllEvents,
  EventSearch,
  latlong,
  ticktesCartItem,
  GetDiscountData,
  shoppingCartDetails,
  messageData,
  removeEmptyDialog,
  location,
  GetUserProfileDetail,
  logoutAction,
}) => {
  const eventId = match.params.eventId;
  const [eventData, setEventData] = useState({});
  const [allEventData, setAllEventData] = useState([]);
  const [profileData, setProfileData] = useState({});
  const [notPublished, setNotPublished] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isTicketSaleEnded, setIsTicketSaleEnded] = useState(false);
  const [ticketSaleSarted, setTicketSaleStarted] = useState(false);
  const [ticketUnavailable, setTicketUnavailable] = useState(false);
  const [ticketType, setTicketType] = useState(0);
  const [soldOut, setSoldOut] = useState(false);
  const [openCheckOut, setOpenCheckOut] = useState(false);
  const [eventIsDrfat, setEventIsDraft] = useState(false);
  const [userMessagesModalIsOpen, setUserMessagesModalIsOpen] = useState(false);
  const [userMessages, setUserMessages] = useState({});
  const [hasCheckoutQuery, setHasCheckoutQuery] = useState(false);

  const params = new URLSearchParams(location.search);
  let Token = params.get("token");
  // let Title = match.params.title

  useEffect(() => {
    setAllEventData([]);
    dispatch(currentEventTicketDetails(null));
    let isMounted = true;
    localStorage.setItem("app-token", null);
    localStorage.setItem("payment", "");
    setLoading(true);
    setNotPublished(false);
    dispatch(GetEventMasterDetailsById(eventId))
      .then((data) => {
        if (data.ResponseCode) {
          GetEventData(data?.UserId);
          // document.title = match.params.title;
          const convertTimeUtcToLocal = (time) => {
            const UtcToLocal = moment
              .utc(time)
              .local()
              .format("MM/DD/YYYY hh:mm:ss A");
            var Time = moment(UtcToLocal, "MM/DD/YYYY hh:mm:ss A");

            const convertedTime = Time.tz(data?.TimeZone);
            return moment(convertedTime).format("MM/DD/YYYY hh:mm:ss A");
          };
          let newData = { ...data };
          newData["StartDateTime"] = convertTimeUtcToLocal(data.StartDateTime);
          newData["EndDateTime"] = convertTimeUtcToLocal(data.EndDateTime);
          const ticketDataUtctoLocal = newData?.TicketData?.map(
            (ticketdata) => {
              return {
                ...ticketdata,
                ActiveDate: convertTimeUtcToLocal(ticketdata.ActiveDate),
                InActiveDate: convertTimeUtcToLocal(ticketdata.InActiveDate),
              };
            }
          );
          const sortedArrayTicketData = ticketDataUtctoLocal.sort(
            (a, b) =>
              new moment(a.ActiveDate).format("X") -
              new moment(b.ActiveDate).format("X")
          );
          newData["TicketData"] = sortedArrayTicketData;
          scrollToTop();
          if (newData?.Status !== "Published") {
            if (newData?.UserId !== Number(localStorage.getItem("UserId"))) {
              history.replace(`${routeName}/404`);
            } else {
              setNotPublished(true);
            }
          }
          if (newData.Status === "Published") {
            setNotPublished(
              moment(newData.StartDateTime, "MM/DD/YYYY HH:mm:ss A").isBefore(
                moment(moment().tz(newData.TimeZone), "MM/DD/YYYY HH:mm:ss A")
              )
            );
          }
          if (isMounted) {
            if (
              newData?.TicketData.length > 0 &&
              newData?.TicketData.filter((t) => t.Status !== "Deleted").every(
                (e) => Number(e.TotalAvailable) === 0
              )
            ) {
              setSoldOut(true);
            } else if (
              newData?.TicketData.every((item) =>
                moment(item?.InActiveDate, "MM/DD/YYYY hh:mm:ss A").isBefore(
                  moment(
                    moment()
                      .tz(newData?.TimeZone)
                      .format("MM/DD/YYYY hh:mm:ss A"),
                    "MM/DD/YYYY hh:mm:ss A"
                  )
                )
              )
            ) {
              setIsTicketSaleEnded(true);
            }

            dispatch(currentEventTicketDetails(newData));
            localStorage.setItem("TicketData", JSON.stringify(newData));
            setEventData(newData);
            setLoading(false);
          }
        } else {
          setLoading(false);
          history.replace(`${routeName}/404`);
        }
      })
      .catch((error) => {});
    return () => {
      isMounted = false;
    };
  }, [eventId, isLoggedIn]);

  useEffect(() => {
    if (Token) {
      let decoded = jwt_decode(Token);
      let UserId = decoded.userId;
      let Userdata = {
        UserId: UserId,
        OtherUserId: UserId,
        lat: latlong.lat,
        lng: latlong.lng,
      };
      dispatch(GetUserProfileDetail(Userdata)).then((data) => {
        localStorage.setItem("UserName", data.results.ProfileDetail.UserName);
        localStorage.setItem(
          "userFullName",
          data.results.ProfileDetail.FirstName
        );
        localStorage.setItem(
          "ProfileImage",
          data.results.ProfileDetail.ProfileImage
        );
        localStorage.setItem("token", Token);
        localStorage.setItem("app-token", Token);
        localStorage.setItem("UserId", UserId);
        //   history.replace({
        //     pathname: location.pathname,
        //     search: '',
        // })
        // params.delete("userId")
        if (
          currentEventDetails?.Status !== "Published" ||
          isTicketSaleEnded ||
          ticketUnavailable ||
          (ticketSaleSarted && !isTicketSaleEnded) ||
          soldOut ||
          (currentEventDetails?.TicketData?.length > 0 &&
            !currentEventDetails?.TicketData.some(
              (item) =>
                Number(item.TotalAvailable) !== 0 &&
                moment(item?.InActiveDate, "MM/DD/YYYY hh:mm:ss A").isAfter(
                  moment(
                    moment()
                      .tz(currentEventDetails?.TimeZone)
                      .format("MM/DD/YYYY hh:mm:ss A"),
                    "MM/DD/YYYY hh:mm:ss A"
                  )
                )
            ) &&
            !soldOut &&
            !isTicketSaleEnded)
        ) {
          console.log("");
        } else {
          setTimeout(() => {
            setOpenCheckOut(true);
          }, 2000);
        }
      });
    }
  }, [currentEventDetails]);

  const LoginPopupOpen = (val) => {
    dispatch(LoginPopup({ path: "", value: val }));
  };
  const AddDataTofavorite = (item) => {
    dispatch(AddToFavorite(item));
  };

  const GetEventData = async (UserId) => {
    try {
      const userId = isLoggedIn ? results.ProfileDetail.UserId : 0;
      const { data } = await axiosE.get(
        `${constants.API.GetAllPublishedEventsByOrganizerLandingPage}/${UserId}/${userId}`
      );
      if (data.results.ResponseCode) {
        const newTimeZoneData = (data.results.EventList).map((item) => {
          const UtcToLocal = moment.utc(item.StartDateTime).local().format("MM/DD/YYYY HH:mm:ss A");
          const convertedTime = moment(UtcToLocal, "MM/DD/YYYY HH:mm:ss A").tz(item.TimeZone);
          return { ...item, StartDateTime: convertedTime };
        });

        const sortedArray = [...newTimeZoneData].sort(
            (a, b) => new moment(a.StartDateTime).format("X") - new moment(b.StartDateTime).format("X")
        );

        setAllEventData(sortedArray);
      }
    } catch (error) {}
  };

  const scrollToTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    setIsTicketSaleEnded(false);
    setTicketSaleStarted(false);
    if (currentEventDetails !== null) {
      if (
        moment(
          currentEventDetails?.StartDateTime,
          "MM/DD/YYYY, hh:mm:ss A"
        ).isBefore(
          moment(
            moment()
              .tz(currentEventDetails?.TimeZone)
              .format("MM/DD/YYYY hh:mm:ss A"),
            "MM/DD/YYYY hh:mm:ss A"
          )
        )
      ) {
        setIsTicketSaleEnded(true);
      }

      if (
        moment(
          currentEventDetails?.TicketData[0]?.ActiveDate,
          "MM/DD/YYYY hh:mm:ss A"
        ).isAfter(
          moment(
            moment()
              .tz(currentEventDetails?.TimeZone)
              .format("MM/DD/YYYY hh:mm:ss A"),
            "MM/DD/YYYY hh:mm:ss A"
          )
        )
      ) {
        setTicketSaleStarted(true);
      }
      if (
        !currentEventDetails?.TicketData.some(
          (item) =>
            Number(item.TotalAvailable) !== 0 &&
            moment(item?.InActiveDate, "MM/DD/YYYY hh:mm:ss A").isAfter(
              moment(
                moment()
                  .tz(currentEventDetails?.TimeZone)
                  .format("MM/DD/YYYY hh:mm:ss A"),
                "MM/DD/YYYY hh:mm:ss A"
              )
            )
        )
      ) {
        setIsTicketSaleEnded(true);
      }
    }
  }, [currentEventDetails]);

  useEffect(() => {
    setEventIsDraft(false);
    setTicketUnavailable(false);

    if (currentEventDetails) {
      if (currentEventDetails?.Status !== "Published") {
        if (
          currentEventDetails?.UserId === Number(localStorage.getItem("UserId"))
        ) {
          if (!currentEventDetails?.TicketData.length > 0) {
            setIsTicketSaleEnded(false);
            setSoldOut(false);
            setTicketUnavailable(true);
          } else {
            if (currentEventDetails?.Status !== "Ended") {
              setEventIsDraft(true);
            }
          }
        }
      }
    }

    if (currentEventDetails?.TicketData.length > 0) {
      setTicketType(0);
      const ticketData = currentEventDetails?.TicketData.map((ticket) => {
        if (Number(ticket.TicketType) === 1) {
          setTicketType(1);
        }
      });

      setIsTicketSaleEnded(
        currentEventDetails?.TicketData.every((t) => {
          return moment(t?.InActiveDate, "MM/DD/YYYY hh:mm:ss A").isBefore(
            moment(
              moment()
                .tz(currentEventDetails?.TimeZone)
                .format("MM/DD/YYYY hh:mm:ss A"),
              "MM/DD/YYYY hh:mm:ss A"
            )
          );
        })
      );
    }
  }, [currentEventDetails]);

  // Check for 'checkout' query parameter and open checkout if present
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);  // Use location from props
    if (searchParams.get('checkout') === 'true') {
      setOpenCheckOut(true);
      setHasCheckoutQuery(true);
    } else {
      setHasCheckoutQuery(false);
    }
  }, [location.search]);

  useEffect(() => {
    console.log('Current event details after:', currentEventDetails);

    if (!openCheckOut && shoppingCartDetails !== null) {
      const data = {
        DiscountType: "",
        disCountAmount: 0.0,
        disCountId: "",
        TicketsId: [],
        RemainingUtilization: "",
        ResponseCode: false,
        DiscountCode: "",
      };
      dispatch({ type: "REMAINING_UTILIZATION", payload: null });
      // if DiscountType is Percentage
      dispatch(GetDiscountData(data));
      dispatch(ticktesCartItem([]));
      dispatch({
        type: "PAYMENT_INTENT_DETAILS",
        payload: { PublicKey: null, paymentId: null },
      });
      dispatch({
        type: "START_TIMMER",
        payload: false,
      });
      params.delete("token");
      // For restart cartSession
      try {
        axiosE
          .post("ShoppingCart/Delete", {
            SessionId: shoppingCartDetails?.CartSession.SessionId,
          })
          .then(() => {
            dispatch({ type: "CREATE_SHOPPING_DETAILS", payload: null });
          })
          .catch(() => {});
      } catch (error) {}
    }
  }, [openCheckOut]);

  const userMessagesModal = (data) => {
    if (data !== null) {
      const userId = results?.ProfileDetail?.UserId;
      let selectedChatIndex = -1;
      if (userMessagesModalIsOpen && messageData) {
        messageData?.recentChat?.forEach((message, index) => {
          if (data.UserId + "" === message.userid) {
            selectedChatIndex = index;
          }
        });
      }
      let showInfo = false;
      const organizerFirstName = data.OrginizerDetails ? data.OrginizerDetails.FirstName : '';
      const organizerProfileImage = data.OrginizerDetails ? data.OrginizerDetails.ProfileImage : '';

      return (
          // <div style={{backgroundColor:'red', width:'250px', height:'250px'}}>
          //   <h2>MY Title</h2>
          // </div>
          <Modal
              open={userMessagesModalIsOpen}
              onClose={() => closeUserMessagesModal(data)}
          >
            <Card
                CardContentProps={{
                  style: userMessagesModalStyles(),
                }}
                content={
                  <WebView
                      userId={userId}
                      updateState={updateState}
                      // toggleAlert={this.toggleAlert}
                      showInfo={showInfo}
                      curbsideCommunication={true}
                      curbsideCommunicationUserData={{
                        FullName: organizerFirstName,
                        ProfileImage: organizerProfileImage,
                        // UserName: data.UserName,
                        UserId: data.UserId,
                      }}
                      curbsideCommunicationSelectedChatIndex={selectedChatIndex}
                  />
                }
            />
          </Modal>
      );
    }
  };

  const showUserMessagesModal = (userMessages) => {
    setUserMessagesModalIsOpen(true);
    // setUserMessages(userMessages)
  };

  const closeUserMessagesModal = (data) => {
    if (Object.keys(messageData.selectedChat.last_message_json).length == 0) {
      removeEmptyDialog(data);
    }
    localStorage.removeItem("curbsideCommunicationUserId");
    localStorage.removeItem("curbsideCommunicationUserName");
    localStorage.removeItem("curbsideCommunicationFullName");
    localStorage.removeItem("curbsideCommunicationProfileImage");
    localStorage.removeItem("pendingEventsButtonClick");

    setUserMessagesModalIsOpen(false);
    setUserMessages({});
  };

  const userMessagesModalStyles = () => {
    return {
      position: "fixed",
      top: window.screen.Height < 1100 ? "10%" : "5%",
      width:
        window.screen.width < 1100
          ? window.screen.width < 850
            ? "84%"
            : "55%"
          : "",
      left:
        window.screen.width < 1100
          ? window.screen.width < 850
            ? "8%"
            : "22%"
          : "30%",
      right:
        window.screen.width < 1100
          ? window.screen.width < 850
            ? "8%"
            : "22%"
          : "30%",
      borderRadius: "8px",
      backgroundColor: "white",
      maxHeight: "80vh", // Set a maximum height for the card
      overflowY: "auto", // Enable vertical scrolling
    };
  };

  const updateState = (state, value) => {
    this.setState({
      [state]: value,
    });
  };

  const toggleAlert = (msg) => {
    this.setState({
      showAlert: !this.state.showAlert,
      alertMessage: msg,
    });
  };

  return (
    <>
      {/*  */}
      {openCheckOut ? (
        <CheckOutModal
          Token={Token}
          openCheckOut={openCheckOut}
          setOpenCheckOut={setOpenCheckOut}
          hasCheckoutQuery={hasCheckoutQuery}
        />
      ) : null}

      {userMessagesModalIsOpen ? userMessagesModal(currentEventDetails) : null}

      {loading || (Token && !openCheckOut) ? (
        <div
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {" "}
          <CircularProgress size={50} color="secondary" />
        </div>
      ) : (
        <div>
          <MetaDecorator currentEventDetails={currentEventDetails} />
          <div id="home" />
          <EventNavbar
            eventIsDrfat={eventIsDrfat}
            soldOut={soldOut}
            setOpenCheckOut={setOpenCheckOut}
            agendaData={currentEventDetails}
            openMessage={setUserMessagesModalIsOpen}
          />
          <div>
            <div>
              <EventHeader
                notPublished={notPublished}
                title={currentEventDetails?.Title}
                eventImage={currentEventDetails?.ImageLarge}
                EventId={currentEventDetails?.EventId}
                isLoggedIn={isLoggedIn}
                eventData={currentEventDetails}
                LoginPopup={LoginPopupOpen}
                favoriteData={favoriteData}
                GetAllEvents={GetAllEvents}
                AddFavorite={AddDataTofavorite}
              />
            </div>
            <EventDetail
              currentEventDetails={currentEventDetails}
              title={currentEventDetails?.Title}
              address={currentEventDetails?.Add1}
              EndDateTime={currentEventDetails?.EndDateTime}
              StartDateTime={currentEventDetails?.StartDateTime}
              VenueName={currentEventDetails?.VenueName}
            />

            <div id="about">
              <EventMap
                parking={currentEventDetails?.Parking}
                Add1={currentEventDetails?.Add1}
                VenueName={currentEventDetails?.VenueName}
                startDateTime={currentEventDetails?.StartDateTime}
                discription={currentEventDetails?.Description}
                title={currentEventDetails?.Title}
                ImageSmall={currentEventDetails?.ImageSmall}
                latlongData={{
                  lat: currentEventDetails?.Latitude,
                  lng: currentEventDetails?.Longitude,
                }}
                onContactClick={showUserMessagesModal}
                userProfileData={currentEventDetails}
                isLoggedIn={isLoggedIn}
                results={results}
                LoginPopup={LoginPopupOpen}
              />
            </div>

            {currentEventDetails &&
              currentEventDetails?.SponsorData?.length > 0 && (
                <div id="faq">
                  <Sponsors currentEventDetails={currentEventDetails} />
                </div>
              )}
            <div id="faq" />
            {currentEventDetails &&
              currentEventDetails?.FaqData?.length > 0 && (
                <div>
                  <Faq currentEventDetails={currentEventDetails} />
                </div>
              )}
            <div id="agenda" />
            {currentEventDetails &&
              currentEventDetails?.AgendaSessionData?.length > 0 && (
                <div>
                  <Agenda currentEventDetails={currentEventDetails} />
                </div>
              )}
            <div id="vendors" />
            {currentEventDetails &&
              currentEventDetails?.VendorData?.length > 0 && (
                <div>
                  <Vendors
                    eventData={currentEventDetails}
                    currentEventDetails={currentEventDetails}
                  />
                </div>
              )}
            {currentEventDetails &&
                currentEventDetails.IsVirtualEvent !== "True" && currentEventDetails.IsVisibleOnApp === "True" && (
                    <div>
                      <div id="aboutapp" />
                      <br />
                      <br />
                      <Aboutapp
                          title={currentEventDetails?.Title}
                          VenueName={currentEventDetails?.VenueName}
                      />
                    </div>
                )
            }
            <div id="organizer" />
            {currentEventDetails &&
                currentEventDetails.IsVirtualEvent !== "True" && (
                    <div>
                      <br />
                      <br />
                    </div>
              )
            }
            <div>
              <EventFooter
                City={currentEventDetails?.City}
                isLoggedIn={isLoggedIn}
                data={allEventData.filter(
                  ({ EventId }) => EventId !== currentEventDetails?.EventId
                )}
                results={results}
                favoriteData={favoriteData}
                LoginPopup={LoginPopupOpen}
                AddFavorite={AddDataTofavorite}
                userProfileData={currentEventDetails}
              />
            </div>
            {!notPublished && (
              <Footer url={`${constants.URLs.baseUrl}/event/${eventId}`} />
            )}
          </div>
          <div className="event_sticky_button">
            <div className="event_sticky_button_box">
              <Link
                className="ticket_button_2"
                style={{
                  textAlign: "center",
                  backgroundColor:
                    currentEventDetails?.Status !== "Published" ||
                    isTicketSaleEnded ||
                    ticketUnavailable ||
                    (ticketSaleSarted && !isTicketSaleEnded) ||
                    soldOut ||
                    (!currentEventDetails?.TicketData.some(
                      (item) =>
                        Number(item.TotalAvailable) !== 0 &&
                        moment(
                          item?.InActiveDate,
                          "MM/DD/YYYY hh:mm:ss A"
                        ).isAfter(
                          moment(
                            moment()
                              .tz(currentEventDetails?.TimeZone)
                              .format("MM/DD/YYYY hh:mm:ss A"),
                            "MM/DD/YYYY hh:mm:ss A"
                          )
                        )
                    ) &&
                      !soldOut &&
                      !isTicketSaleEnded)
                      ? "#9c9c9c"
                      :  (currentEventDetails?.EventColor !== undefined && currentEventDetails.EventColor !== "") ? currentEventDetails.EventColor :  "rgb(161 3 150)",
                  color: "#fff",
                }}
                onClick={() =>
                  currentEventDetails?.Status !== "Published" ||
                  isTicketSaleEnded ||
                  ticketUnavailable ||
                  (ticketSaleSarted && !isTicketSaleEnded) ||
                  soldOut ||
                  (currentEventDetails?.TicketData.length > 0 &&
                    !currentEventDetails?.TicketData.some(
                      (item) =>
                        Number(item.TotalAvailable) !== 0 &&
                        moment(
                          item?.InActiveDate,
                          "MM/DD/YYYY hh:mm:ss A"
                        ).isAfter(
                          moment(
                            moment()
                              .tz(currentEventDetails?.TimeZone)
                              .format("MM/DD/YYYY hh:mm:ss A"),
                            "MM/DD/YYYY hh:mm:ss A"
                          )
                        )
                    ) &&
                    !soldOut &&
                    !isTicketSaleEnded)
                    ? null
                    : setOpenCheckOut(true)
                }
                to="#"
              >
                {ticketUnavailable
                  ? "Unavailable"
                  : (currentEventDetails?.ActionButtonText !== undefined && currentEventDetails?.ActionButtonText !== "")
                        ? currentEventDetails?.ActionButtonText
                        : ticketType === 1
                  ? "Purchase Tickets"
                  : "Register"}
              </Link>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              {isTicketSaleEnded && soldOut && (
                <p
                  style={{
                    textAlign: "center",
                    fontWeight: 600,
                    color: "#f03063",
                    bottom: -6,
                    left: 0,
                    right: 0,
                  }}
                >
                  {constants.Text.ticketSaleEnd}
                </p>
              )}
              {eventIsDrfat && (
                <p
                  style={{
                    textAlign: "center",
                    fontWeight: 600,
                    color: "#f03063",
                    bottom: -6,
                    left: 0,
                    right: 0,
                  }}
                >
                  {constants.Text.draftEvent}
                </p>
              )}
              {isTicketSaleEnded && !soldOut && !eventIsDrfat && (
                <p
                  style={{
                    // position: "absolute",
                    textAlign: "center",
                    fontWeight: 600,
                    color: "#f03063",
                    bottom: -6,
                    left: 0,
                    right: 0,
                  }}
                >
                  {constants.Text.ticketSaleEnd}
                </p>
              )}
              {!isTicketSaleEnded && soldOut && (
                <p
                  style={{
                    // position: "absolute",
                    textAlign: "center",
                    fontWeight: 600,
                    color: "#f03063",
                    bottom: -6,
                    left: 0,
                    right: 0,
                  }}
                >
                  {constants.Text.soldOut}
                </p>
              )}
              {currentEventDetails?.TicketData.length > 0 &&
                !currentEventDetails?.TicketData.some(
                  (item) =>
                    Number(item.TotalAvailable) !== 0 &&
                    moment(item?.InActiveDate, "MM/DD/YYYY hh:mm:ss A").isAfter(
                      moment(
                        moment()
                          .tz(currentEventDetails?.TimeZone)
                          .format("MM/DD/YYYY hh:mm:ss A"),
                        "MM/DD/YYYY hh:mm:ss A"
                      )
                    )
                ) &&
                !soldOut &&
                !isTicketSaleEnded &&
                !eventIsDrfat && (
                  <p
                    style={{
                      // position: "absolute",
                      textAlign: "center",
                      fontWeight: 600,
                      color: "#f03063",
                      bottom: -6,
                      left: 0,
                      right: 0,
                    }}
                  >
                    {constants.Text.ticketSaleEnd}
                  </p>
                )}
              {ticketSaleSarted && !isTicketSaleEnded && !eventIsDrfat && (
                <p
                  style={{
                    textAlign: "center",
                    fontWeight: 600,
                    color: "#f03063",
                    bottom: -6,
                    left: 0,
                    right: 0,
                  }}
                >
                  {`On Sale ${moment(
                    currentEventDetails?.TicketData[0]?.ActiveDate
                  ).format("MMM DD")} at
                   ${moment(
                     currentEventDetails?.TicketData[0]?.ActiveDate
                   ).format("hh:mm A")}`}
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    eventSearchData: state.eventReducer.EventSearchData,
    favoriteData: state.favoriteReducer.favoriteData,
    results: state.loginResponse.results,
    isLoggedIn: state.loginResponse.isLoggedIn,
    currentEventDetails: state.eventReducer.currentEventDetails,
    latlong: state.favoriteReducer.latlong,
    cardData: state.favoriteReducer.cardData,
    shoppingCartDetails: state.eventReducer.createshoppingCartDetails,
    messageData: state.messageReducer,
  };
};
const matchDispatchToProps = (dispatch) => {
  return {
    // AddToFavorite: () => dispatch(AddToFavorite),
    // SetCardData: () => dispatch(SetCardData),
    // LoginPopup: () => dispatch(LoginPopup),
    AddToFavorite,
    LoginPopup,
    currentEventTicketDetails,
    GetEventMasterDetailsById,
    GetAllEvents,
    EventSearch,
    ticktesCartItem,
    GetDiscountData,
    removeEmptyDialog,
    GetUserProfileDetail,
    logoutAction,
    dispatch,
  };
};

const EventsWithRouter = withRouter(Events);

export default connect(mapStateToProps, matchDispatchToProps)(EventsWithRouter);
