import React from "react";
import {Button} from "@material-ui/core";
import {IoCloseOutline, IoPrintOutline} from "react-icons/io5";
import {useEffect} from "react";
import {useState} from "react";
import {useRef} from "react";
import {Text} from "Constants";
import PhoneInput from "react-phone-input-2";
import {ParseError, parsePhoneNumberWithError, parsePhoneNumberFromString, format} from "libphonenumber-js";
import {jsPDF} from "jspdf";
import ubmeLogo from "../../../../../../images/ubme_logo.png";

const UpdateAttendeeModal = ({
                                 editAdditionalInfo,
                                 AttendeeUpdate,
                                 isOpen,
                                 eventName
                             }) => {
    // Extract data from props
    const {
        TotalCounts,
        Title,
        Status,
        OrderDate,
        EventId,
        PdfFile,
        OrderId,
        ScannedDateTime,
        QRCode,
        ...restinfo
    } = editAdditionalInfo;

    // Initialize state variables
    const [isNotFilled, setIsNotFilled] = useState(false);
    const [phoneError, setPhoneError] = useState("");
    const [phoneNumber, setPhoneNumber] = useState('');
    const [selectedCountry, setSelectedCountry] = useState(''); // Default country code

    const DEFAULT_COUNTRY_CODE = '1'; // Replace with your desired default country code

    // Initialize updatedInfo state
    const updatedInfoInitialState = Object.keys(restinfo).map((key, index) => {
        return {
            FieldName: key,
            DefaultValue: restinfo[key],
            id: index.toString(),
        };
    });

    // Define handleChangeAdd function
    const handleChangeAdd = (e) => {
        let {name, value, id} = e.target;
        let newData = [...updatedInfo];

        // Validation for full name
        if (name === "FullName") {
            if (!/^(?=.*[A-Za-z])[\w\s'-]+ [A-Za-z\d\s'-]+$/.test(value)) {
                // If the entered value doesn't match the pattern, handle it accordingly
                setIsNotFilled(true);
                newData[id]["IsValid"] = false;
                newData[id]["ErrorMessage"] = "Please enter a valid first and last name.";
            } else {
                setIsNotFilled(false);
                newData[id]["IsValid"] = true;
                newData[id]["ErrorMessage"] = "";
            }
        } else if (name === "Email") {
            // Validation for email
            // You can use a regular expression or any other method to validate the email format
            // Here, I'm using a simple format validation for demonstration
            if (!/\S+@\S+\.\S+/.test(value)) {
                setIsNotFilled(true);
                newData[id]["IsValid"] = false;
                newData[id]["ErrorMessage"] = "Please enter a valid email address.";

            } else {
                setIsNotFilled(false);
                newData[id]["IsValid"] = true;
                newData[id]["ErrorMessage"] = "";
            }
        }

        newData[id]["DefaultValue"] = value;
        setUpdatedInfo(newData);
    };

    const handleInput = (e) => {
        e.target.style.height = 'auto'; // Reset height
        e.target.style.height = `${e.target.scrollHeight}px`; // Set height based on scroll height
        handleChangeAdd(e); // Call the existing handleChange function to handle other logic
    };

    // Define handlePhoneNumberChange function
    const handlePhoneNumberChange = (value, country) => {
        try {
            // Remove all non-numeric characters from the value
            value = value.replace(/\D/g, "");

            // Add the country code to the value
            const countryCode = `${country.dialCode}`;
            if (!value.startsWith(countryCode)) {
                value = countryCode + value;
            }

            // Ensure the input value has a minimum length before parsing
            if (value.length >= 6) {
                const phoneNumberObject = parsePhoneNumberWithError(
                    value,
                    country.countryCode.toUpperCase()
                );
                if (phoneNumberObject !== undefined && phoneNumberObject !== "") {
                    if (phoneNumberObject.isValid()) {
                        // Valid phone number
                        setIsNotFilled(false);
                        setPhoneNumber(phoneNumberObject.nationalNumber);
                        setSelectedCountry(country.countryCode);
                        setPhoneError("");
                        let newData = [...updatedInfo];

                        if (newData !== undefined && newData !== "") {
                            let foundObject = newData.find((item) => item.FieldName === "Phone");

                            if (foundObject) {
                                foundObject.DefaultValue = phoneNumberObject.nationalNumber;
                            }
                            setUpdatedInfo(newData);
                        }
                    } else {
                        // Invalid phone number
                        setIsNotFilled(true);
                        setPhoneNumber(value);
                        setPhoneError("Please enter a valid phone number.");
                    }
                } else {
                    // Parsing failed
                    setIsNotFilled(true);
                    setPhoneNumber(value);
                    setPhoneError("Phone number parsing failed.");
                }
            } else {
                // Input doesn't meet minimum length
                setIsNotFilled(true);
                setPhoneNumber(value);
                setPhoneError("Please enter a valid phone number.");
            }

            if (selectedCountry !== country.countryCode) {
                setIsNotFilled(true);
                setPhoneError("Phone number is required.");
            }
        } catch (error) {
            if (error instanceof ParseError) {
                console.log(error.message);
            } else {
                throw error;
            }
        }
    };

    // Initialize updatedInfo state
    const [updatedInfo, setUpdatedInfo] = useState(updatedInfoInitialState);

    let obj = {};
    updatedInfo.map(({ FieldName, DefaultValue }) =>
        Object.assign(obj, {
            [FieldName.replace(/\s/g, FieldName === "Full Name" ? "" : "_")]:
            DefaultValue,
        })
    );

    obj.Phone = obj?.Phone?.replace(/[^\d]+/g, "");
    if (obj.hasOwnProperty("TermsAndConditions")) {
        let specialChars =
            /[\\"/\#\$\%\&\(\)\*\+\,\-\.\:\;\<\=\>\?\@\[\]\^\`\{\|\}\~\'\!]/g;
        obj.TermsAndConditions = obj.TermsAndConditions.replace(
            specialChars,
            "\\$&"
        );
    }

    // Remove specified properties from obj
    delete obj.Amount;
    delete obj.BookingDate;
    delete obj.BookingTime;
    delete obj.CurrencyCode;
    delete obj.CurrencySymbol;
    delete obj.DiscountAmount;
    delete obj.DiscountCode;
    delete obj.Price;
    delete obj.Re_ScannedDateTime;
    delete obj.RecurringInterval;
    delete obj.RecurringTicket;
    delete obj.TaxAmount;
    delete obj.TaxName;
    delete obj.UBMeFeeAmount;
    delete obj.isAbsorb;
    delete obj.Terms_And_Conditions;
    delete obj.TermsAndConditions;
    delete obj.SlotPerBundle;
    delete obj.isBundle;
    delete obj.TicketDescription;
    delete obj.Notes;

    // Define updatedPayload
    const updatedPayload = {
        OrderId: OrderId,
        AdditionalDetails: JSON.stringify(obj),
    };

    useEffect(() => {

        // Check if "Phone" field exists and get its default value
        const phoneInfo = updatedInfo?.find((i) => i.FieldName === "Phone");
        let phoneValue = phoneInfo ? phoneInfo.DefaultValue : '';

        try {
            // Use libphonenumber-js to parse the phone number
            let phoneNumberObject = parsePhoneNumberFromString(phoneValue);

            if (!phoneNumberObject) {
                let phoneValueWithPlus = "+" + phoneValue
                phoneNumberObject = parsePhoneNumberFromString(phoneValueWithPlus);

                if(!phoneNumberObject) {
                    let phoneValueWithPlusWithDefaultCode = "+" + DEFAULT_COUNTRY_CODE + phoneValue
                    phoneNumberObject = parsePhoneNumberFromString(phoneValueWithPlusWithDefaultCode);

                    if (phoneNumberObject) {
                        phoneValue =  phoneNumberObject.formatInternational()
                    }
                } else if (!phoneNumberObject.isValid()) {
                    let phoneValueWithPlusWithDefaultCode = "+" + DEFAULT_COUNTRY_CODE + phoneValue
                    phoneNumberObject = parsePhoneNumberFromString(phoneValueWithPlusWithDefaultCode);

                    if (phoneNumberObject) {
                        phoneValue =  phoneNumberObject.formatInternational()
                    }
                } else {
                    phoneValue =  phoneNumberObject.formatInternational()
                }
            }

            // Format the phone number in international format
            phoneValue =  phoneNumberObject.formatInternational()
        } catch (error) {
            // Handle any errors here
            console.error('Error formatting phone number:', error);
            return phoneValue;
        }

        const fieldsToExcludeFromValidation = [
            "Amount",
            "BookingDate",
            "BookingTime",
            "CurrencyCode",
            "CurrencySymbol",
            "DiscountAmount",
            "DiscountCode",
            "Price",
            "Re_ScannedDateTime",
            "RecurringInterval",
            "RecurringTicket",
            "TaxAmount",
            "TaxName",
            "UBMeFeeAmount",
            "isAbsorb",
            "Terms_And_Conditions",
            "TermsAndConditions",
            "SlotPerBundle",
            "isBundle",
            "TicketDescription",
            "Notes",
        ];

        const isNotFilled = updatedInfo?.some(
            (i) =>
                !fieldsToExcludeFromValidation.includes(i.FieldName) &&
                i.DefaultValue === ""
        );

        setIsNotFilled(isNotFilled);

        // Set the phoneNumber state
        setPhoneNumber(phoneValue);
    }, [updatedInfo]);

    // Create refs for each textarea/input
    const refs = useRef([]);

    // Set initial height for each textarea based on its content
    useEffect(() => {
        refs.current.forEach((ref) => {
            if (ref && ref.tagName === 'TEXTAREA') { // Ensure we only apply this to textareas
                ref.style.height = 'auto'; // Reset height to auto to calculate the correct height
                ref.style.height = `${ref.scrollHeight}px`; // Set height based on scrollHeight
            }
        });
    }, [updatedInfo]); // Run whenever updatedInfo changes

    const formatPhoneNumber = (phoneValue) => {

        try {
            // Use libphonenumber-js to parse the phone number
            let phoneNumberObject = parsePhoneNumberFromString(phoneValue);

            if (!phoneNumberObject) {
                let phoneValueWithPlus = "+" + phoneValue
                phoneNumberObject = parsePhoneNumberFromString(phoneValueWithPlus);

                if(!phoneNumberObject) {
                    let phoneValueWithPlusWithDefaultCode = "+" + DEFAULT_COUNTRY_CODE + phoneValue
                    phoneNumberObject = parsePhoneNumberFromString(phoneValueWithPlusWithDefaultCode);

                    if (phoneNumberObject) {
                        phoneValue =  phoneNumberObject.formatInternational()
                    }
                } else if (!phoneNumberObject.isValid()) {
                    let phoneValueWithPlusWithDefaultCode = "+" + DEFAULT_COUNTRY_CODE + phoneValue
                    phoneNumberObject = parsePhoneNumberFromString(phoneValueWithPlusWithDefaultCode);

                    if (phoneNumberObject) {
                        phoneValue =  phoneNumberObject.formatInternational()
                    }
                } else {
                    phoneValue =  phoneNumberObject.formatInternational()
                }
            }

            // Format the phone number in international format
            phoneValue =  phoneNumberObject.formatInternational()
        } catch (error) {
            // Handle any errors here
            console.error('Error formatting phone number:', error);
            return phoneValue;
        }

        return phoneValue;
    };

    const generatePDF = async (details) => {
        const doc = new jsPDF();

        // Add the image (assuming it's a local image, or you can fetch it and pass the data URL)
        const img = new Image();
        img.src = ubmeLogo;

        // List of fields to exclude
        const fieldsToExclude = [
            "TotalCounts", "Title", "Status", "OrderDate", "EventId", "PdfFile", "OrderId",
            "ScannedDateTime", "QRCode", "Amount", "BookingDate", "BookingTime", "CurrencyCode",
            "CurrencySymbol", "DiscountAmount", "DiscountCode", "Price", "Re_ScannedDateTime",
            "RecurringInterval", "RecurringTicket", "TaxAmount", "TaxName", "UBMeFeeAmount",
            "isAbsorb", "Terms_And_Conditions", "TermsAndConditions", "SlotPerBundle", "isBundle",
            "TicketDescription", "Notes",
        ];

        // Filter out excluded fields
        const filteredDetails = Object.keys(details)
            .filter(key => !fieldsToExclude.includes(key))
            .reduce((obj, key) => {
                obj[key] = details[key];
                return obj;
            }, {});

        // Arrange FullName, Email, and Phone at the top
        const orderedFields = [
            "FullName", "Email", "Phone",
            ...Object.keys(filteredDetails).filter(key => !["FullName", "Email", "Phone"].includes(key))
        ];

        img.onload = () => {
            const imgWidth = 40; // Image width in the PDF
            const imgHeight = (img.height * imgWidth) / img.width; // Maintain aspect ratio

            // Center the image
            const imgX = (doc.internal.pageSize.width - imgWidth) / 2;
            const imgY = 10; // Vertical position

            doc.addImage(img, 'JPEG', imgX, imgY, imgWidth, imgHeight);

            // Add the Event Name (Title) below the image
            const title = eventName
            doc.setFontSize(16); // Slightly larger font for the title
            doc.setFont("helvetica", "bold"); // Bold font for title

            const titleY = imgY + imgHeight + 10; // Vertical position for the title
            doc.text(title, doc.internal.pageSize.width / 2, titleY, { align: "center" });

            // Format the OrderDateTime
            const orderDateTime = details.OrderDate;

            // Add the OrderDateTime below the event name, in gray and smaller font
            doc.setFontSize(10); // Smaller font for OrderDateTime
            doc.setFont("helvetica", "normal"); // Normal font for OrderDateTime
            doc.setTextColor(128, 128, 128); // Gray color

            const dateTimeY = titleY + 6; // Vertical position for the OrderDateTime
            doc.text(orderDateTime, doc.internal.pageSize.width / 2, dateTimeY, { align: "center" });

            // Reset the text color to black for the rest of the content
            doc.setTextColor(0, 0, 0);

            // Set position below the image for the table
            let y = dateTimeY + 15;

            const pageHeight = doc.internal.pageSize.height;
            const leftColumnX = 20;
            const maxLineWidth = 160; // Max width for the question and answer text
            const padding = 1; // Add padding between text and cell border
            const extraSpaceAfterAnswer = 7; // Extra space after the answer (adjust this value)

            doc.setFontSize(12);

            orderedFields.forEach((key) => {
                if (filteredDetails[key]) {
                    // Format the Phone field using the formatPhoneNumber function
                    let answer = filteredDetails[key].toString(); // Get answer value
                    if (key === "Phone") {
                        answer = formatPhoneNumber(answer); // Format the phone number
                    }

                    const question = key === "FullName" ? "Full Name" : key.replace(/_/g, " "); // Format key for display

                    // Split long text into multiple lines for the first column (question)
                    const questionLines = doc.splitTextToSize(question, maxLineWidth);
                    const questionHeight = questionLines.length * 6; // Approximate line height for the question

                    // Split long text into multiple lines for the second column (answer)
                    const answerLines = doc.splitTextToSize(answer, maxLineWidth); // Adjust based on available width
                    const answerHeight = answerLines.length * 6; // Approximate line height for the answer

                    // Check if we need to add a new page
                    const requiredHeight = questionHeight + answerHeight + padding + extraSpaceAfterAnswer;
                    if (y + requiredHeight > pageHeight) { // 20px margin at the bottom
                        doc.addPage(); // Add new page
                        y = 20; // Reset y position to top of new page
                    }

                    // Add the question text in bold
                    doc.setFont("helvetica", "bold"); // Bold font for question
                    doc.text(questionLines, leftColumnX, y); // Display question

                    // Move the y-position down for the answer (add some space after the question)
                    y += questionHeight + 2;

                    // Add the answer text below the question
                    doc.setFont("helvetica", "normal"); // Normal font for answer
                    doc.text(answerLines, leftColumnX, y); // Display answer below the question

                    // Move the cursor down for the next row (question-answer pair) with extra space after the answer
                    y += answerHeight + padding + extraSpaceAfterAnswer;
                }
            });

            // Get FullName for file name
            const fullName = details.FullName || "output";

            // Save the PDF with the FullName
            doc.save(`${fullName}.pdf`);
        };
    };

    return (
        <div className="UpdateAttendeeModal">
            <div>
                <div className="edit_container">
                    <div style={{padding: 10}} className="edit_container_close_btn">
                        <IoCloseOutline
                            size={25}
                            color="rgba(0, 0, 0, 0.54)"
                            onClick={isOpen}
                        />
                    </div>
                    <p className="edit_container_header">Edit Attendee</p>
                    <div style={{padding: 10}} className="edit_container_close_btn">
                        <IoPrintOutline
                            size={25}
                            color="rgba(0, 0, 0, 0.54)"
                            onClick={() => generatePDF(editAdditionalInfo)}
                        />
                    </div>
                </div>

                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                    }}
                    className="modalDetail"
                >
                    <div className="modalForm">
                        {updatedInfo?.map(({FieldName, FormFieldId, DefaultValue, IsValid, ErrorMessage}, index) =>
                                FieldName === "Terms_And_Conditions" ||
                                FieldName === "TermsAndConditions"
                                    ? null
                                    :
                                ![
                                    "Amount",
                                    "BookingDate",
                                    "BookingTime",
                                    "CurrencyCode",
                                    "CurrencySymbol",
                                    "DiscountAmount",
                                    "DiscountCode",
                                    "Price",
                                    "Re_ScannedDateTime",
                                    "RecurringInterval",
                                    "RecurringTicket",
                                    "TaxAmount",
                                    "TaxName",
                                    "UBMeFeeAmount",
                                    "isAbsorb",
                                    "Terms_And_Conditions",
                                    "TermsAndConditions",
                                    "SlotPerBundle",
                                    "isBundle",
                                    "TicketDescription",
                                    "Notes",
                                ].includes(FieldName) &&
                                    // (FieldName === "FullName" ||
                                    //     FieldName === "Email" ||
                                    //     FieldName === "Phone") &&

                                    (
                                    <div style={{
                                        position: "relative",
                                        marginBottom: 20,
                                    }}>
                                        <p
                                            style={{
                                                fontSize: 14,
                                                fontWeight: 400,
                                                marginBottom: 8,
                                            }}
                                        >
                                            {FieldName === "FullName"
                                                ? "Full Name"
                                                : FieldName?.replace(/_/g, " ")}{" "}
                                            <span
                                                style={{
                                                    color: "red",
                                                    fontSize: "medium",
                                                }}
                                            >
                          *
                        </span>
                                        </p>
                                        {FieldName === "Phone" ? (
                                            <div style={{
                                                paddingLeft: "2%",
                                                border: "2px solid #d8d8d8",
                                                borderRadius: "5px",
                                                outlineColor: "#fff",
                                            }}
                                            >
                                                <div className="phone-input-container">
                                                    <PhoneInput
                                                        country={selectedCountry || 'us'}
                                                        value={phoneNumber}
                                                        onChange={(value, country) => handlePhoneNumberChange(value, country)}
                                                        inputProps={{
                                                            name: 'phone',
                                                            required: true,
                                                        }}
                                                        containerStyle={{borderWidth: 0}} // Optional: To remove the border of the parent div
                                                        inputStyle={{
                                                            border: 'none',
                                                            marginLeft: '50px',
                                                            width: '80%'
                                                        }}
                                                        enableSearch={true}
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            <div style={{
                                                paddingLeft: "2%",
                                                border: "2px solid #d8d8d8",
                                                borderRadius: "5px",
                                                outlineColor: "#fff",
                                            }}
                                            >
                                                <div className="div-with-scroll">
                                                    {FieldName === "Full Name" || FieldName === "Email" || FieldName === "Phone" ? (
                                                        <input
                                                            className="add_info_input"
                                                            id={index.toString()}
                                                            name={FieldName}
                                                            value={DefaultValue}
                                                            key={FormFieldId}
                                                            ref={(el) => (refs.current[index] = el)} // Assign ref
                                                            type="text"
                                                            required={true}
                                                            // maxLength="260"
                                                            onChange={(e) => handleChangeAdd(e)}
                                                        />
                                                    ) : (
                                                        <textarea
                                                            className="add_info_textarea"
                                                            id={index.toString()}
                                                            name={FieldName}
                                                            value={DefaultValue}
                                                            ref={(el) => (refs.current[index] = el)} // Assign ref
                                                            key={FormFieldId}
                                                            required={true}
                                                            onChange={(e) => handleChangeAdd(e)}
                                                            onInput={(e) => handleInput(e)}
                                                            rows="1"  // Start with a single row
                                                        />
                                                    )
                                                    }
                                                    {DefaultValue === "" && (
                                                        <label
                                                            htmlFor={index.toString()}
                                                            style={{
                                                                position: "absolute",
                                                                // top: "-5px",
                                                                left: 4,
                                                                fontSize: 15,
                                                                margin: 0,
                                                                fontWeight: 400,
                                                                color: "#b1b1b1",
                                                                paddingTop: "0.5%",
                                                                minHeight: "33px",
                                                            }}
                                                        >
                                                            {FieldName !== "Full Name" && FieldName !== "Email" && FieldName !== "Phone" ? (
                                                                "Type your answer"
                                                            ) : (
                                                                <>
                                                                  <span
                                                                      style={{
                                                                          color: "red",
                                                                          fontSize: "medium",
                                                                      }}
                                                                  >
                                                                    *
                                                                  </span>{" "}
                                                                    {FieldName} {/* Correct placement of FieldName */}
                                                                </>
                                                            )}
                                                        </label>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        {FieldName === "Phone" && phoneError && (
                                            <div
                                                style={{
                                                    marginTop: 10,
                                                    color: "red",
                                                    fontSize: 12
                                                }}
                                            >
                                                {phoneError}
                                            </div>
                                        )}
                                        {(FieldName === "FullName" || FieldName === "Email") && !IsValid && (
                                            <div style={{color: "red"}}>
                                                {ErrorMessage}
                                            </div>
                                        )}

                                    </div>
                                )
                        )}
                        <div
                            style={{marginTop: 15}}
                            className="detailAction"
                            id="eventCommonModal"
                        >
                            <Button
                                onClick={() => isOpen()}
                                style={{
                                    width: 120,
                                    height: 50,
                                    backgroundColor: "#fff",
                                    fontSize: "1.5rem",
                                    color: "#000000",
                                    padding: "12px 60px",
                                    borderColor: "#968e8e",
                                }}
                                className="btn_save_edit_details"
                                color={"primary"}
                                variant={"outlined"}
                                component="span"
                            >
                                {Text.Cancel}
                            </Button>

                            <Button
                                onClick={() => {
                                    AttendeeUpdate(updatedPayload);
                                }}
                                disabled={isNotFilled}
                                style={{
                                    backgroundColor: isNotFilled ? "#ff91e0" : "rgb(163, 56, 164)",
                                    fontSize: "1.5rem",
                                    height: 50,
                                    color: "#fff",
                                    padding: "12px 60px",
                                    border: "none",
                                    width: 120,
                                    borderRadius: 9,
                                }}
                                className="btn_save_edit_details"
                                color={"primary"}
                                variant={"contained"}
                                component="span"
                            >
                                {Text.Save}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateAttendeeModal;
