import { eventApis } from "Constants";
import axiosI from "Service/service";
import { axiosE } from "Service/service";
import { history } from "../store";
import moment from "moment";
import "moment-timezone";
import * as constants from "Constants";

import {
  CREATE_EVENT,
  FETCH_EVENTS,
  FETCH_OWN_EVENTS,
  DELETE_EVENT,
  UPDATE_EVENT,
  ENABLE,
  DISABLE,
  REFETCH_EVENTS,
  REFETCH_OWNEVENTS,
  GET_EVENT_USER_EVENTS,
  ADD_UPDATE_EVENT_BY_EVENT_USER,
  UPDATE_EVENT_BY_EVENT_USER,
  CURRENT_EVENT_STATUS,
  CURRENT_EVENT_DETAILS,
  ADD_FAQ_BY_EVENT_USER,
  DELETE_FAQ_BY_EVENT_USER,
  UPDATE_FAQ_BY_EVENT_USER,
  ADD_AGENDA_BY_EVENT_USER,
  DELETE_AGENDA_BY_EVENT_USER,
  UPDATE_AGENDA_BY_EVENT_USER,
  ADD_ADS_BY_EVENT_USER,
  DELETE_ADS_BY_EVENT_USER,
  ADD_VENDOR_BY_EVENT_USER,
  DELETE_VENDOR_BY_EVENT_USER,
  UPDATE_VENDOR_BY_EVENT_USER,
  UPDATE_ADS_BY_EVENT_USER,
  ADD_SPONSOR_BY_EVENT_USER,
  UPDATE_SPONSOR_BY_EVENT_USER,
  DELETE_SPONSOR_BY_EVENT_USER,
  ADD_TICKET_BY_EVENT_USER,
  UPDATE_TICKET_BY_EVENT_USER,
  DELETE_TICKET_BY_EVENT_USER,
  CLEAR_EVENT_DETAILS,
  ADD_DISCOUNT_BY_EVENT_USER,
  UPDATE_DISCOUNT_BY_EVENT_USER,
  DELETE_DISCOUNT_BY_EVENT_USER,
  ADD_ADDITIONAL_TAX_BY_EVENT_USER,
  UPDATE_ADDITIONAL_TAX_BY_EVENT_USER,
  DELETE_ADDITIONAL_TAX_BY_EVENT_USER,
  ADD_ORDER_FORM_BY_EVENT_USER,
  UPDATE_ORDER_FORM_BY_EVENT_USER,
  DELETE_ORDER_FORM_BY_EVENT_USER,
  PUBLISH_EVENT,
  EVENT_STATUS,
  SET_RANDOM_CODE,
  ADD_SPEAKER_BY_EVENT_USER,
  UPDATE_SPEAKER_BY_EVENT_USER,
  DELETE_SPEAKER_BY_EVENT_USER,
  SET_EVENT_TYPE,
  SET_STRIPE_USER_ID,
  UPDATE_DESCRIPTOR_NAME,
  SET_SIDEBAR_FUNCTIONS,
  LATITUDE_LONG_SYNC_V2,
  // LATITUDE_LONG_SYNC_V1,
  EVENT_SEARCH_DATA,
  LATITUDE_LONG,
  TICKET_CART_ITEM,
  DISCOUNT_DETAILS,
  CURRENT_EVENT_TICKET_DETAILS,
  UPDATE_EVENT_DETAILS_BY_EVENT_USER,
  REMOVE_EVENT_BY_EVENT_USER,
  PERSIST_SHOPING_CART_DATA,
  CLEAR_CART_SESSION_DISCOUNT_ID,
  STORE_EVENT_DATE_DETAILS,
  REMOVE_EVENT_DATE_DETAILS_BY_TICKET_ID,
  REMOVE_ALL_EVENT_DATE_DETAILS,
} from "./actionTypes";
import { AddToFavorite } from "./favoriteAction";

// export const createEventAction = (eventData) => {
//   return (dispatch) => {
//     dispatch({ type: ENABLE, payload: true });
//     return axiosI.post(`/AddEventByUser`, eventData).then(({ data }) => {
//       if (data.results.ResponseCode) {
//         dispatch({
//           type: CREATE_EVENT,
//           payload: data.results,
//         });
//       }
//       dispatch({ type: DISABLE, payload: false });
//       return data;
//     });
//   };
// };

// export const fetchEventAction = (userDetails) => {
//   return (dispatch) => {
//     dispatch({
//       type: REFETCH_EVENTS,
//     });
//     return axiosI
//       .get(
//         `/GetEventsByUserTimeZoneWithImage/${userDetails.UserId}/${userDetails.ToUserId}/${userDetails.lat}/${userDetails.lng}/${userDetails.PageNumber}/${userDetails.PageSize}/${userDetails.deleteCount}/${userDetails.timeZoneOffsetInMinutes}/${userDetails.strEventImageNameCommaSeparated}`
//       )
//       .then(({ data }) => {
//         dispatch({
//           type: FETCH_EVENTS,
//           payload: data,
//         });
//       });
//   };
// };

// export const fetchOwnEventAction = (userDetails) => {
//   return (dispatch) => {
//     dispatch({
//       type: REFETCH_OWNEVENTS,
//     });
//     return axiosI
//       .get(
//         `/GetUserOwnEvents/${userDetails.UserId}/${userDetails.lat}/${userDetails.lng}/${userDetails.PageNumber}/${userDetails.PageSize}/${userDetails.deleteCount}/${userDetails.timeZoneOffsetInMinutes}/${userDetails.strEventImageNameCommaSeparated}`
//       )
//       .then(({ data }) => {
//         dispatch({
//           type: FETCH_OWN_EVENTS,
//           payload: data,
//         });
//       });
//   };
// };

// export const updateEventAction = (event) => {
//   return (dispatch) => {
//     dispatch({ type: ENABLE, payload: true });
//     return axiosI.post(`/UpdateEventByUser`, event).then(({ data }) => {
//       dispatch({
//         type: UPDATE_EVENT,
//         payload: { Results: data.results, event },
//       });
//       dispatch({ type: DISABLE, payload: false });
//       return data;
//     });
//   };
// };

// export const deleteEventAction = (event) => {
//   return (dispatch) => {
//     dispatch({ type: ENABLE, payload: true });
//     return axiosI.post(`/DeleteEventByUser`, event).then(({ data }) => {
//       dispatch({
//         type: DELETE_EVENT,
//         payload: event,
//       });
//       dispatch({ type: DISABLE, payload: false });
//       return data;
//     });
//   };
// };

// export const getEventUserEvents = (id) => {
//   return (dispatch) => {
//     return axiosI
//       .get(`/GetEventUserEvents/${id}`)
//       .then(({ data }) => {
//         dispatch({
//           type: GET_EVENT_USER_EVENTS,
//           payload: data,
//         });
//         return data;
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };
// };

export const currentEventStatus = (data) => {
  return (dispatch) =>
    dispatch({
      type: CURRENT_EVENT_STATUS,
      payload: data,
    });
};

export const currentEventDetails = (data) => {

  return (dispatch) =>
    dispatch({
      type: CURRENT_EVENT_DETAILS,
      payload: data,
    });
};

export const setRandomCode = (data) => {
  return (dispatch) =>
    dispatch({
      type: SET_RANDOM_CODE,
      payload: data,
    });
};

// Add Agenda
export const addAgenda = (data) => {
  return (dispatch) => {
    return axiosE
      .post(eventApis.addAgendaSession, data)
      .then(({ data }) => {
        dispatch({
          type: ADD_AGENDA_BY_EVENT_USER,
          payload: data,
        });
        return JSON.parse(data.results.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
// DELETE AGENDA
export const deleteAgenda = (data) => {
  return (dispatch) => {
    return axiosE
      .post(eventApis.deleteAgendaSession, data)
      .then((res) => {
        dispatch({
          type: DELETE_AGENDA_BY_EVENT_USER,
          payload: data,
        });
        return data;
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
// UPDATE AGENDA
export const updateAgenda = (data) =>
  updateAction(
    data,
    eventApis.updateAgendaSession,
    UPDATE_AGENDA_BY_EVENT_USER
  );
// Add Ad
export const addAd = (data) => {
  return (dispatch) => {
    return axiosE
      .post(eventApis.addAd, data)
      .then(({ data }) => {
        dispatch({
          type: ADD_ADS_BY_EVENT_USER,
          payload: data,
        });
        return JSON.parse(data.results.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
// DELETE AD
export const deleteAd = (data) => {
  return (dispatch) => {
    return axiosE
      .post(eventApis.deleteAd, data)
      .then((res) => {
        dispatch({
          type: DELETE_ADS_BY_EVENT_USER,
          payload: data,
        });
        return data;
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
// UPDATE Ad

//  ACTION CREATORS
// Add Action Creator
function addAction(data, endPoint, actionType) {
  return (dispatch) => {
    return axiosE
      .post(endPoint, data)
      .then((res) => {
        dispatch({
          type: actionType,
          payload: res.data,
        });
        console.warn("ADD ACTION RESPONSE FROM ACTION", res.data);
        return JSON.parse(res.data.results.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
// Delete Action Creator
function deleteAction(data, endPoint, actionType) {
  return (dispatch) => {
    return axiosE
      .post(endPoint, data)
      .then((res) => {
        dispatch({
          type: actionType,
          payload: data,
        });
        return data;
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
// UPDATE Action Creator
function updateAction(data, endPoint, actionType) {
  return (dispatch) => {
    const { EventCode, ...restUpdatedItem } = data;

    return axiosE
      .post(endPoint, { ...restUpdatedItem })
      .then((res) => {

        if (res.data.results.ResponseCode) {
          const jsonResponse = JSON.parse(res.data.results.Data);
          const newUpdatedData = {
            EventCode,
            ...jsonResponse,
          };

          if (actionType !== "UPDATE_TICKET_BY_EVENT_USER") {
            dispatch({
              type: actionType,
              payload: newUpdatedData,
            });
          }
        }

        return res;
      })
      .catch((err) => {
        console.log("error", err);
        return err;
      });
  };
}

// Event About Add
export const addUpdateEventByEventUser = (data) => {
  return (dispatch) => {
    return axiosE
      .post(eventApis.addEvent, data)
      .then((res) => {
        dispatch({
          type: ADD_UPDATE_EVENT_BY_EVENT_USER,
          payload: res.data,
        });
        return res;
      })
      .catch((err) => {
        return err;
      });
  };
};

// Clone Event By User

export const cloneEvenntByEventUser = (data) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_EVENT_BY_EVENT_USER,
      payload: data,
    });
    // dispatch({
    //   type: PUBLISH_EVENT,
    //   payload: data,
    // });
  };
};

export const removeEventByEventUser = () => {
  return (dispatch) => {
    // console.log("removing event");
    dispatch({
      type: REMOVE_EVENT_BY_EVENT_USER,
      payload: null,
    });
  };
};

// Edit Event By User
export const editEvenntByEventUser = (data) => {
  // console.log("handleClose",data);

  return (dispatch) => {
    if (data.Status === "Published" || (window.location.href.includes(`Dashboard/EventsOverview`))) {
      dispatch({
        type: PUBLISH_EVENT,
        payload: data,
      });
    }
    dispatch({
      type: UPDATE_EVENT_BY_EVENT_USER,
      payload: data,
    });
  };
};

export const UpdateEventDetailsByUser = (data) => {
  return (dispatch) =>
    dispatch({
      type: UPDATE_EVENT_DETAILS_BY_EVENT_USER,
      payload: data,
    });
};

export const updateEventByEventUser = (data) =>
  updateAction(data, eventApis.updateEvent, UPDATE_EVENT_BY_EVENT_USER);

// Vendor Actions
export const addVendor = (data) =>
  addAction(data, eventApis.addVendor, ADD_VENDOR_BY_EVENT_USER);
export const deleteVendor = (data) =>
  deleteAction(data, eventApis.deleteVendor, DELETE_VENDOR_BY_EVENT_USER);
export const updateVendor = (data) =>
  updateAction(data, eventApis.updateVendor, UPDATE_VENDOR_BY_EVENT_USER);

// FAQ Actions
export const addFAQ = (data) =>
  addAction(data, eventApis.addFaq, ADD_FAQ_BY_EVENT_USER);
export const updateFAQ = (data) =>
  updateAction(data, eventApis.updateFaq, UPDATE_FAQ_BY_EVENT_USER);
export const deleteFAQ = (data) =>
  deleteAction(data, eventApis.deleteFaq, DELETE_FAQ_BY_EVENT_USER);

// Ad Actions
export const updateAd = (data) =>
  updateAction(data, eventApis.updateAd, UPDATE_ADS_BY_EVENT_USER);

// Sponsor Actions
export const addSponsor = (data) =>
  addAction(data, eventApis.addSponsor, ADD_SPONSOR_BY_EVENT_USER);
export const updateSponsor = (data) =>
  updateAction(data, eventApis.updateSponsor, UPDATE_SPONSOR_BY_EVENT_USER);
export const deleteSponsor = (data) =>
  deleteAction(data, eventApis.deleteSponsor, DELETE_SPONSOR_BY_EVENT_USER);

// TICKETS SECTION
export const addTicket = (data) =>
  addAction(data, eventApis.addTicket, ADD_TICKET_BY_EVENT_USER);
export const updateTicket = (data) =>
  updateAction(data, eventApis.updateTicket, UPDATE_TICKET_BY_EVENT_USER);
export const deleteTicket = (data) =>
  deleteAction(data, eventApis.deleteTicket, DELETE_TICKET_BY_EVENT_USER);
// Discount Section
export const addDiscount = (data) =>
  addAction(data, eventApis.addDiscount, ADD_DISCOUNT_BY_EVENT_USER);
export const updateDiscount = (data) =>
  updateAction(data, eventApis.updateDiscount, UPDATE_DISCOUNT_BY_EVENT_USER);
export const deleteDiscount = (data) =>
  deleteAction(data, eventApis.deleteDiscount, DELETE_DISCOUNT_BY_EVENT_USER);
// Additional tax
export const addAdditionalTax = (data) =>
  addAction(data, eventApis.addTax, ADD_ADDITIONAL_TAX_BY_EVENT_USER);
export const updateAdditionalTax = (data) =>
  updateAction(data, eventApis.updateTax, UPDATE_ADDITIONAL_TAX_BY_EVENT_USER);
export const deleteAdditionalTax = (data) =>
  deleteAction(data, eventApis.deleteTax, DELETE_ADDITIONAL_TAX_BY_EVENT_USER);
// Order Form
export const addOrderForm = (data) =>
  addAction(data, eventApis.addOrderForm, ADD_ORDER_FORM_BY_EVENT_USER);
export const updateOrderForm = (data) =>
  updateAction(
    data,
    eventApis.updateOrderForm,
    UPDATE_ORDER_FORM_BY_EVENT_USER
  );
export const deleteOrderForm = (data) =>
  deleteAction(
    data,
    eventApis.deleteOrderForm,
    DELETE_ORDER_FORM_BY_EVENT_USER
  );

// Publish Event
export const publishEvent = (data) =>
  addAction(data, eventApis.publishEvent, PUBLISH_EVENT);

// Clear Event Details
export const clearEventDetails = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_EVENT_DETAILS,
    });
    return true;
  };
};

export const setEventStatus = (data) => {

  return (dispatch) => {
    dispatch({
      type: EVENT_STATUS,
      payload: data,
    });
  };
};

export const setEventType = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_EVENT_TYPE,
      data: data,
    });
  };
};
export const setStripeUserId = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_STRIPE_USER_ID,
      data: data,
    });
  };
};
// Agenda Speakers
export const addAgendaSpeaker = (data) => {
  return (dispatch) => {
    return axiosE
      .post(eventApis.addAgendaSpeaker, data.speakerData)
      .then((res) => {

        dispatch({
          type: ADD_SPEAKER_BY_EVENT_USER,
          payload: { data: res.data, day: data.day },
        });
        return JSON.parse(res.data.results.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const updateAgendaSpeaker = (data) => {
  return (dispatch) => {
    return axiosE
      .post(eventApis.updateAgendaSpeaker, data.speakerData)
      .then((res) => {
        dispatch({
          type: UPDATE_SPEAKER_BY_EVENT_USER,
          payload: { data: res.data, day: data.day },
        });
        return JSON.parse(res.data.results.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const deleteAgendaSpeaker = (data) => {
  return (dispatch) => {
    return axiosE
      .post(eventApis.deleteAgendaSpeaker, { SpeakerId: data.SpeakerId })
      .then((res) => {
        dispatch({
          type: DELETE_SPEAKER_BY_EVENT_USER,
          payload: {
            SpeakerId: data.SpeakerId,
            day: data.day,
            sessionID: data.sessionID,
          },
        });
        return JSON.parse(res.data.results.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const updateDescriptor = (data) => {
  return (dispatch) => {
    return axiosE
      .post(eventApis.Descriptor, data)
      .then((res) => {
        dispatch({
          type: UPDATE_DESCRIPTOR_NAME,
          payload: { name: data.PaymentDescriptor },
        });
        return JSON.parse(res.data.results.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const addSideBarFunctions = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_SIDEBAR_FUNCTIONS,
      payload: data,
    });
  };
};

// UBMe event actions
export const createEventAction = (eventData) => {
  return (dispatch) => {
    dispatch({ type: ENABLE, payload: true });
    return axiosI
      .post(constants.API.AddEventByUser, eventData)
      .then(({ data }) => {
        if (data.results.ResponseCode) {
          dispatch({
            type: CREATE_EVENT,
            payload: data.results,
          });
        }
        dispatch({ type: DISABLE, payload: false });
        return data;
      });
  };
};

export const fetchEventAction = (userDetails) => {
  return async (dispatch) => {
    dispatch({
      type: REFETCH_EVENTS,
    });
    const { data } = await axiosI.get(
      `${constants.API.GetEventsByUserTimeZoneWithImage}/${userDetails.UserId}/${userDetails.ToUserId}/${userDetails.lat}/${userDetails.lng}/${userDetails.PageNumber}/${userDetails.PageSize}/${userDetails.deleteCount}/${userDetails.timeZoneOffsetInMinutes}/${userDetails.strEventImageNameCommaSeparated}`
    );
    dispatch({
      type: FETCH_EVENTS,
      payload: data,
    });
  };
};

export const GetAllEvents = (eventData, onSuccess = () => {}) => {
  return async (dispatch) => {
    try {
      const { data } = await axiosE.post(constants.API.GetAllEvents, eventData);
      if (data.results.ResponseCode) {
        // Asia/Kolkata
        const newTimeZoneData = data.results.EventList.map((item) => {
          const UtcToLocal = moment
            .utc(item.StartDateTime)
            .local()
            .format("MM/DD/YYYY hh:mm:ss A");
          var Time = moment(UtcToLocal, "MM/DD/YYYY hh:mm:ss A");
          const convertedTime = Time.tz(item.TimeZone);
          const obj = Object.assign({}, item);
          obj["StartDateTime"] = convertedTime;
          return obj;
        });

        let newArr = [...newTimeZoneData];
        const sortedArray = newArr.sort(
          (a, b) =>
            new moment(a.StartDateTime).format("X") -
            new moment(b.StartDateTime).format("X")
        );

        // const filterEvents = sortedArray.filter((i) =>
        //   moment(i.StartDateTime, "MM/DD/YYYY HH:mm:ss A").isAfter(
        //     moment(moment().tz(i.TimeZone), "MM/DD/YYYY HH:mm:ss A")
        //   )
        // );
        dispatch(
          AddToFavorite(sortedArray?.filter((item) => item?.Liked === "true"))
        );
        const results = {
          ...data.results,
          EventList: sortedArray,
        };

        return results;
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const GetEventMasterDetailsById = (eventId, platform = "Web") => {
  // console.log("eventId, userId",eventId, userId);
  let userId =
    localStorage.getItem("UserId") !== null
      ? localStorage.getItem("UserId")
      : 0;
  return async (dispatch) => {
    try {
      const { data } = await axiosE.get(
        `${constants.API.GetEventMasterDetailsById}/${eventId}/${userId}/${platform}`
      );
      // if (data.results.ResponseCode){
      return data.results;
      // }
    } catch (e) {
      console.log(e);
    }
  };
};

export const GetOrdersByUserId = (userId) => {
  return (dispatch) => {
    return axiosE
      .get(`${constants.API.GetOrdersByUserId}/${userId}`)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const GetAllEventCategories = () => {
  return (dispatch) => {
    return axiosE
      .get(constants.API.GetAllEventCategories)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const GetAllOrderFormFieldsByEventId = (EventId) => {
  return (dispatch) => {
    return axiosE
      .get(`${constants.API.GetAllOrderFormFieldsByEventId}/${EventId}`)
      .then(({ data }) => {
        if (data.results.ResponseCode) {
          console.log(data);
          return data;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const CreateShoppingCartSession = (EventId) => {

  return (dispatch) => {
    return axiosE
      .get(`${constants.API.CreateShoppingCartSession}/${EventId}`)
      .then(({ data }) => {
        if (data.results.ResponseCode) {
          // dispatch({type:CREATE_SHOPPING_DETAILS, payload:data})
          return data.results;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const PersistShoppingCartSession = (CartData) => {
  return (dispatch) => {
    // console.log(CartData)
    return axiosE
      .post(constants.API.PersistShoppingCartSession, CartData)
      .then(({ data }) => {
        return data.results;
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getPersistShopingCartData = (data) => {
  // console.log({ data });
  return (dispatch) => {
    dispatch({
      type: PERSIST_SHOPING_CART_DATA,
      payload: data,
    });
  };
};

export const clearCartSessionDiscountId = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_CART_SESSION_DISCOUNT_ID,
    });
  };
};

export const fetchOwnEventAction = (userDetails) => {
  return (dispatch) => {
    dispatch({
      type: REFETCH_OWNEVENTS,
    });
    return axiosI
      .get(
        `${constants.API.GetUserOwnEvents}/${userDetails.UserId}/${userDetails.lat}/${userDetails.lng}/${userDetails.PageNumber}/${userDetails.PageSize}/${userDetails.deleteCount}/${userDetails.timeZoneOffsetInMinutes}/${userDetails.strEventImageNameCommaSeparated}`
      )
      .then(({ data }) => {
        dispatch({
          type: FETCH_OWN_EVENTS,
          payload: data,
        });
      });
  };
};

export const updateEventAction = (event) => {
  return (dispatch) => {
    dispatch({ type: ENABLE, payload: true });
    return axiosI
      .post(constants.API.UpdateEventByUser, event)
      .then(({ data }) => {
        dispatch({
          type: UPDATE_EVENT,
          payload: { Results: data.results, event },
        });
        dispatch({ type: DISABLE, payload: false });
        return data;
      });
  };
};

export const deleteEventAction = (event) => {
  return (dispatch) => {
    dispatch({ type: ENABLE, payload: true });
    return axiosI
      .post(constants.API.DeleteEventByUser, event)
      .then(({ data }) => {
        dispatch({
          type: DELETE_EVENT,
          payload: event,
        });
        dispatch({ type: DISABLE, payload: false });
        return data;
      });
  };
};

export const getEventUserEvents = (id) => {
  return (dispatch) => {
    return axiosI
      .get(`GetEventUserEvents/${id}`)
      .then(({ data }) => {
        dispatch({
          type: GET_EVENT_USER_EVENTS,
          payload: data,
        });
        return data;
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

// export const addUpdateEventByEventUser = (data) => {
//   return (dispatch) => {
//     return axiosI
//       .post(constants.API.AddUpdateEventByEventUser, data)
//       .then(({ data }) => {
//         dispatch({
//           type: ADD_UPDATE_EVENT_BY_EVENT_USER,
//           payload: data,
//         });
//         return data;
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };
// };
export const GetDiscountDetails = (EventId, discountCode) => {
  const sessionId = JSON.parse(localStorage.getItem("CurrentSessionId"));
  return (dispatch) => {
    return axiosE
      .get(
        `${constants.API.GetDiscountDetails}/${EventId}/${discountCode}/${sessionId}`
      )
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const LatLongData = (data) => {
  return (dispatch) => {
    dispatch({
      type: LATITUDE_LONG,
      payload: data,
    });
  };
};

export const EventSearch = (data) => {
  return (dispatch) => {
    dispatch({
      type: EVENT_SEARCH_DATA,
      payload: data,
    });
  };
};

export const SyncLatLongDataV1 = (data) => {
  return (dispatch) => {
    // dispatch({
    //   type: LATITUDE_LONG_SYNC_V1,
    //   payload: data,
    // });
  };
};
export const SyncLatLongDataV2 = (data) => {
  return (dispatch) => {
    dispatch({
      type: LATITUDE_LONG_SYNC_V2,
      payload: data,
    });
  };
};

// export const currentEventStatus = (data) => {
//   return (dispatch) =>
//     dispatch({
//       type: CURRENT_EVENT_STATUS,
//       payload: data,
//     });
// };

// export const currentEventDetails = (data) => {
//   return (dispatch) =>
//     dispatch({
//       type: CURRENT_EVENT_DETAILS,
//       payload: data,
//     });
// };

export const currentEventTicketDetails = (data) => {
  return (dispatch) =>
    dispatch({
      type: CURRENT_EVENT_TICKET_DETAILS,
      payload: data,
    });
};

export const ticktesCartItem = (data) => {
  return (dispatch) =>
    dispatch({
      type: TICKET_CART_ITEM,
      payload: data,
    });
};
export const GetDiscountData = (data) => {
  return (dispatch) =>
    dispatch({
      type: DISCOUNT_DETAILS,
      payload: data,
    });
};

export const storeEventDateDetails = (eventDateDetails) => {
  return (dispatch) => {
    dispatch({
      type: STORE_EVENT_DATE_DETAILS,
      payload: eventDateDetails,
    });
  };
};

export const removeEventDateDetailsByTicketId = (ticketId) => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_EVENT_DATE_DETAILS_BY_TICKET_ID,
      payload: ticketId,
    });
  };
};

export const removeAllEventDateDetails = () => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_ALL_EVENT_DATE_DETAILS,
    });
  };
};

// export const setEventExperienceData = (data) => ({
//   type: 'SET_EVENT_EXPERIENCE_DATA',
//   payload: data,
// });
