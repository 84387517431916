import React, { useState, useEffect } from "react";
import "./eventOverviewTable.css";
import { useTable } from "react-table";
import FavoriteImage from "images/tickets1.jpg";
import { connect } from "react-redux";
import storeFolder from "../../../../store";
import {
  GetOrdersByUserId,
  cloneEvenntByEventUser,
  editEvenntByEventUser,
  clearEventDetails,
  removeEventByEventUser,
  GetEventMasterDetailsById,
} from "Actions/eventActions";
import * as constants from "Constants";
import { CircularProgress, Modal, Paper, withStyles } from "@material-ui/core";
import moment from "moment";
import { removeEmptyDialog } from "Actions/messagesAction";
import { routeName } from "Service/env";
import { SalesListByOrganizerId } from "Actions";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { LinearProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { axiosE } from "Service/service";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { useRef } from "react";
import UseOutsideClick from "Components/UBMeEvents/Components_UBMeEvents/OutSideClick/UseOutsideClick";
import { history } from "store";
import { useLayoutEffect } from "react";
import EventOverviewMobular from "./eventOverviewMobular";
import TablePagination from "@mui/material/TablePagination";
import { GrDownload } from "react-icons/gr";
import Backdrop from "@mui/material/Backdrop";
import { Parser } from "json2csv";
import { default as momentTz } from "moment-timezone";
import ExcelJS from "exceljs";
import * as FileSaver from "file-saver";
import _ from "lodash";
import Share from "../../../UBMeEvents/Components_UBMeEvents/share/Share";
import ShareModal from "../../../UBMeEvents/Components_UBMeEvents/modal/Modal";
import { ws } from "App";
import {eventApis} from "Constants";
const { jsPDF } = require("jspdf");
require("jspdf-autotable");

const EventOverviewTable = ({
                              results,
                              GetOrdersByUserId,
                              dispatch,
                              currentEventDetails,
                              messageData,
                              SalesListByOrganizerId,
                              cloneEvenntByEventUser,
                              editEvenntByEventUser,
                              GetEventMasterDetailsById,
                              Status,
                              SearchTerm,
                              refresher,
                              forwardedRef,
                              // clearEventDetails,
                              removeEventByEventUser,
                            }) => {
  const [salesListByOrganizerData, setSalesListByOrganizerData] = useState([]); // use an empty array as initial value
  const [loading, setloading] = useState(false); // set a loader for api response
  const [anchorEl, setAnchorEl] = useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [modal, setModal] = useState({ isShow: false, title: "", eventId: "" });
  const [cancel, setCancel] = useState(0);
  const [cloning, setCloning] = useState(false);
  const [visible, setVisible] = useState(false);
  const [eventId, setEventId] = useState(null);
  let storeData = storeFolder.getState();

  const handleChangePage = (event, newPage) => {

    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    caption: {
      color: "green",
      padding: 8,
      border: "1px dashed grey",
      fontSize: "1.5rem",
    },
    spacer: {
      color: "green",
      fontSize: "16rem",
    },
    selectIcon: {
      fontSize: "16rem",
    },
  }));

  React.useImperativeHandle(forwardedRef, () => ({
    SalesListByOrganizerIdFn,
  }));

  const SalesListByOrganizerIdFn = () => {
    if (refresher === 0) {
      setloading(true);
    }
    let id = localStorage.getItem("UserId");
    if (SearchTerm !== "") {
      setPage(0);
    }
    const data = {
      OrganizerId: id,
      PageNumber: SearchTerm !== "" ? 1 : page + 1,
      RowsOfPage: rowsPerPage,
      Status: Status,
      SearchTerm: SearchTerm,
    };
    // calling GetsalesListByOrganizerDataId action for fetching data
    dispatch(SalesListByOrganizerId(data)).then((data) => {
      // if (data.results.ResponseCode) {
        setSalesListByOrganizerData(
            JSON.parse(data.results.Data)?.sort(
                (a, b) =>
                    new moment(a.ScannedDateTime).format("X") -
                    new moment(b.ScannedDateTime).format("X")
            )
        );
        setloading(false);
      // } else {
      //   setloading(false);
      // }
    });
  };

  useEffect(() => {
    if (page === 0) {
      SalesListByOrganizerIdFn();
    } else {
      setPage(0);
    }
  }, [Status]);

  useEffect(() => {
    SalesListByOrganizerIdFn();
  }, [refresher, page, cancel]);

  // useEffect(()=>{
  //   dispatch(clearEventDetails());
  // })

  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 6,
      borderRadius: 0,
    },
    colorPrimary: {
      backgroundColor:
          theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    bar: {
      borderRadius: 0,
      backgroundColor: "rgb(0, 171, 85)",
      transform: ({ value }) => {
        return value > 0
            ? `translateX(${value}%)`
            : `translateX(-101%) !important`;
      },
    },
    // the default style uses translateX, so we need to switch the axis
  }))(LinearProgress);

  const convertTimeUtcToLocal = (time, TimeZone) => {
    const UtcToLocal = moment.utc(time).local().format("MM/DD/YYYY hh:mm:ss A");
    var Time = moment(UtcToLocal, "MM/DD/YYYY hh:mm:ss A");

    const convertedTime = Time.tz(TimeZone);
    return moment(convertedTime);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuRef = useRef(null);

  const cloneThisEvent = async (EventId) => {
    setCloning(true);
    dispatch(clearEventDetails());
    handleClose();
    try {
      const { data } = await axiosE.post("/Event/Clone", { EventId });
      if (data.results.ResponseCode) {
        const cloneID = JSON.parse(data.results.Data).ClonedEventId;
        localStorage.setItem("CurrentEventId", JSON.stringify(cloneID));
        axiosE
            .get(
                `${constants.eventApis.GetEventMasterById}/${cloneID}/${results?.ProfileDetail?.UserId}/Admin`
            )
            .then(({ data }) => {
              localStorage.setItem("isCloneEvent", "true");
              const newDates = {
                StartDateTime: moment(new Date()),
                EndDateTime: moment().add(1, "days"),
              };
              const newData = { ...data.results, ...newDates };
              dispatch(cloneEvenntByEventUser(newData));
              setCloning(false);

              setTimeout(() => {
                window.location.href = `${routeName}/Dashboard/About`;
              }, 800);
            })
            .catch(() => {});
      }
    } catch (error) {}
  };

  const shareButton = (eventId) => {
    setEventId(eventId)
    setVisible(true);
  };

  const closeModal = () => {
    setVisible(false)
  };

  const editThisEvent = async (EventId, path = null) => {
    localStorage.removeItem("isCloneEvent");
    localStorage.setItem("CurrentEventId", JSON.stringify(EventId));
    window.location.href = path ? path : `${routeName}/Dashboard/About`;
    dispatch(clearEventDetails());
  };

  const deleteThisEvent = async (eventId) => {
    try {
      setSalesListByOrganizerData(
          salesListByOrganizerData.filter(({ EventId }) => EventId !== eventId)
      );
      const { data } = await axiosE.post(constants.API.DeleteEventByOrganizer, {
        UserId: results?.ProfileDetail?.UserId,
        EventId: eventId,
      });
      setModal({ isShow: false, title: "", eventId: "" });
    } catch (error) {}
  };

  const cancelThisEvent = async (eventId) => {
    try {
      const { data } = await axiosE.post("/Event/Cancel", {
        EventId: eventId,
      });
      if (data.results.ResponseCode) {
        setCancel((prev) => prev + 1);
      }
      setModal({ isShow: false, title: "", eventId: "" });
    } catch (error) {}
  };

  const cleareEventsAndCreateNewone = (path) => {
    dispatch(clearEventDetails());
    dispatch(removeEventByEventUser());

    localStorage.setItem("isItCreateOrEditEvent", "Edit");
    localStorage.setItem("buyerFee", null);
    localStorage.removeItem("isCloneEvent");
    localStorage.removeItem("isRecurring");

    setTimeout(() => {
      window.location.href = path;
    }, 200);
  };

  const filterUnwantedKeys = (obj) => {
    const filteredObject = {};

    for (const key in obj) {
      if (isNaN(key)) {
        filteredObject[key] = obj[key];
      }
    }

    return filteredObject;
  };

  const parseAndValidateJSON = (jsonString) => {
    // Replace 'null' with 'null' and single quotes with double quotes
    const replacedString = jsonString
        .replace(/'/g, '"')
        .replace(/null/g, "null");

    try {
      const parsedData = JSON.parse(replacedString);
      return { success: true, data: parsedData };
    } catch (error) {
      return { success: false, error: error.message };
    }
  };

  const isPlainObject = (variable) => {
    return (
        typeof variable === "object" &&
        variable !== null &&
        !Array.isArray(variable)
    );
  };

  const jsonFormatterHelper = (ListAttendee) => {
    // console.log({ ListAttendee });
    const filterdObjects = ListAttendee.map((item) => {
      const jsonobj = item.AdditionalDetails;
      const {
        Data,
        Message,
        ResponseCode,
        AdditionalDetails,
        Email,
        ...restItem
      } = item;

      if (jsonobj) {
        let parsedData = {};
        try {
          const parsedJsonObj = parseAndValidateJSON(jsonobj);
          let restInfo = {};
          if (parsedJsonObj.success && parsedJsonObj.data?.FullName) {
            restInfo = { ...parsedJsonObj.data };
          } else {
            const modifiedJson = JSON.parse(jsonobj, (key, value) => {
              if (typeof value === "string") {
                // Replace single quotes within string values with apostrophes
                return value.replace(/'/g, "\\'");
              }
              return value;
            });

            parsedData = JSON.parse(jsonobj);
            const data = JSON.parse(parsedData);

            restInfo = { ...modifiedJson, ...data };
          }

          return { ...restInfo, ...restItem };
        } catch (e) {
          const isObject = isPlainObject(parsedData);

          if (isObject) {
            return { ...parsedData, ...restItem };
          }

          // Replace single quotes with double quotes
          const correctedJsonString = parsedData.replace(/'/g, '"');
          const newParsedData = JSON.parse(correctedJsonString);
          return { ...newParsedData, ...restItem };
        }
      } else {
        return { FullName: "", Email: "", Phone: "", ...restItem };
      }
    });
    const filteredDetails = filterdObjects?.map((obj) =>
        filterUnwantedKeys(obj)
    );
    return filteredDetails;
  };

  function areArraysEqual(arr1, arr2) {
    return JSON.stringify(arr1) === JSON.stringify(arr2);
  }

  const headerCellStyle = (backgroundColor, fontColor, isToWrapText = false) => {
    return {
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: backgroundColor }
      },
      alignment: {
        horizontal: "center",
        wrapText: isToWrapText
      },
      font: { color: { argb: fontColor } }
    };
  };

  // Function to format currency values
  function formatCurrency(value, currencySymbol) {
    // Format the number as currency
    return currencySymbol + Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(Number(value));
  }

  const downloadFullReportXLSX = async (EventId, Title, TimeZone) => {
    try {
      // Fetch Attendees list
      const { data } = await axiosE.get(`DownloadAllAttendeeByEventId/${EventId}`);

      const timeUTCtoLocal = (date) => {
        var local = moment.tz(moment.utc(date), "MM/DD/YYYY hh:mm:ss a", TimeZone).format("MM/DD/YYYY hh:mm:ss a");
        return moment(local, "MM/DD/YYYY hh:mm:ss a").format("llll");
      };

      if (data?.results?.ListAttendee?.length > 0) {
        const filterdObjects = jsonFormatterHelper(data.results.ListAttendee);

        // Create a new workbook
        const workbook = new ExcelJS.Workbook();

        // Add a worksheet to the workbook
        const worksheet = workbook.addWorksheet("Attendee List");

        const topHeaders = [
          { header: "Total Ticket Sales", key: "TotalTicketSales", width: 15 },
          { header: "Total Tax/Fee Collected", key: "TotalTaxCollected", width: 15 },
          { header: "Total UBMe Fees", key: "TotalUBMeFees", width: 15 },
          // { header: "Total Processing Fees", key: "TotalProcessingFees", width: 15 },
          { header: "Total Payout for this period", key: "TotalPayout", width: 15 },
        ]

        // Extract keys from detailedHeaders
        const topHeadersKeys = topHeaders.map(header => header.header);

        // Add the header row for detailed headers (5th row) with headers and widths
        const topHeaderRow = worksheet.addRow(topHeadersKeys.map(key => key)); // Include detailedHeaderKeys here if needed
        topHeaderRow.font = { bold: true };

        const totalSalesHeaderCell = worksheet.getCell("A1");
        Object.assign(totalSalesHeaderCell, headerCellStyle("406BCA", "FFFFFF"));

        const totalTaxHeaderCell = worksheet.getCell("B1");
        Object.assign(totalTaxHeaderCell, headerCellStyle("406BCA", "FFFFFF"));

        const totalUBMeFeesHeaderCell = worksheet.getCell("C1");
        Object.assign(totalUBMeFeesHeaderCell, headerCellStyle("406BCA", "FFFFFF"));

        // const totalProcessingFeesHeaderCell = worksheet.getCell("D1");
        // totalProcessingFeesHeaderCell.fill = { type: "pattern", pattern: "solid", fgColor: { argb: "406BCA" } };
        // totalProcessingFeesHeaderCell.alignment = { horizontal: "center" }; // Center-align text
        // totalProcessingFeesHeaderCell.font = { color: { argb: "FFFFFF" } }; // White text color

        const totalPayoutHeaderCell = worksheet.getCell("D1");
        Object.assign(totalPayoutHeaderCell, headerCellStyle("2D5A1C", "FFFFFF"));

        // Add blank rows (3rd and 4th rows)
        worksheet.addRow([]);
        worksheet.addRow([]);

        // Set column headers and make them bold
        const predefinedColumns = [
          { header: "Order ID", key: "OrderId", width: 15 },
          { header: "Event ID", key: "EventId", width: 15 },
          { header: "Full Name", key: "FullName", width: 20 },
          { header: "Email", key: "Email", width: 25 },
          { header: "Phone", key: "Phone", width: 15 },
          { header: "Order Date", key: "OrderDate", width: 20 },
          { header: "Confirmed Date/Time", key: "ScannedDateTime", width: 20 },
          { header: "Status", key: "Status", width: 15 },
          { header: "Ticket Type", key: "TicketType", width: 30 },
          { header: "Ticket Quantity", key: "TicketQuantity", width: 15 },
          { header: "Ticket Cost", key: "TicketCost", width: 15 },
          { header: "Discount", key: "Discount", width: 15 },
          { header: "Discount Code", key: "DiscountCode", width: 15 },
          { header: filterdObjects[0].TaxName !== '' ? filterdObjects[0].TaxName : "Tax", key: "Tax", width: 15 },
          { header: "UBMe Fee", key: "UBMeFee", width: 15 },
          { header: "Order Total", key: "OrderTotal", width: 15 },
          { header: "Order PDF", key: "OrderPDF", width: 50 },
          { header: "Notes", key: "Notes", width: 50 },
        ];

        // Convert UTC OrderDate to local time in sortedObjects
        const sortedObjects = filterdObjects.map((obj) => {
          const localOrderDate = timeUTCtoLocal(obj.OrderDate); // Assuming the OrderDate field contains UTC timestamps
          return { ...obj, OrderDate: localOrderDate };
        });

        // Sort the sortedObjects array in descending order based on OrderDate
        sortedObjects.sort((a, b) => new Date(b.OrderDate) - new Date(a.OrderDate));

        // Define a list of columns to exclude
        const columnsToExclude = [
          "Amount",
          "BookingDate",
          "BookingTime",
          "CurrencyCode",
          "CurrencySymbol",
          "DiscountAmount",
          "DiscountCode",
          "Email",
          "EventId",
          "FullName",
          "OrderDate",
          "OrderId",
          "PdfFile",
          "Phone",
          "QRCode",
          "Price",
          "RecurringInterval",
          "RecurringTicket",
          "ScannedDateTime",
          "Re_ScannedDateTime",
          "Status",
          "TaxAmount",
          "TaxName",
          "Title",
          "TotalCounts",
          "UBMeFeeAmount",
          "isAbsorb",
          "Terms_And_Conditions",
          "TermsAndConditions",
          "isBundle",
          "SlotPerBundle",
          "TicketDescription",
          "Notes"
        ];

        // Define additional columns by filtering out excluded columns
        const additionalColumns = Object.keys(sortedObjects[0]).filter((columnName) => {
          return !columnsToExclude.includes(columnName);
        }).map((columnName) => ({
          header: columnName.replace(/_/g, ' '), // Display name for the column
          key: columnName,    // Unique identifier for the column
          width: 15,          // Optional: specify the column width
        }));

        // Combine predefined and additional columns for the header row
        const allHeaders = predefinedColumns.concat(additionalColumns);

        // Extract keys from allHeaders
        const allHeadersKeys = allHeaders.map(header =>  header.header);

        // Add the header row for detailed headers (5th row) with headers and widths
        const headerRow = worksheet.addRow(allHeadersKeys.map(key => key)); // Include allHeadersKeys here if needed
        headerRow.font = { bold: true };

        // Loop through each cell in the header row and set wrapText alignment
        // headerRow.eachCell({ includeEmpty: true }, (cell) => {
        //   cell.alignment = { wrapText: true };
        // });

        let currencySymbol = ""

        // Group rows by orderId and collect ticket details for each orderId
        const groupedRows = sortedObjects.reduce((acc, row) => {
          const key = `${row.OrderId}-${row.FullName}-${row.Email}-${row.Phone}-${row.OrderDate}`;
          if (!acc[key]) {
            acc[key] = { ...row, TicketType: [], TicketId: [], TicketCost: [], UBMeFee: [], TicketStatuses: [], TaxFee: [], DiscountCode: [], DiscountAmount: [], TicketDetails: []};
          }

          currencySymbol = row.CurrencySymbol;

          const ticketType = (row.Status === 'Refunded') ? `${row.Title} (Refunded)` : row.Title;

          const ticketTypeWithDescription = {
            TicketId: row.QRCode,
            TicketName: ticketType,
            TicketDescription: row.TicketDescription ? row.TicketDescription : '',
          };

          acc[key].TicketType.push(ticketTypeWithDescription);
          // acc[key].TicketType.push(ticketType);
          acc[key].TicketId.push(row.QRCode);
          acc[key].TicketStatuses.push(row.Status);

          const ticketDetail = {
            TicketName: row.Title,
            TicketId: row.QRCode,
            ConfirmedDateTime: row.ScannedDateTime !== "" ? timeUTCtoLocal(row.ScannedDateTime) : "",
            ReScannedDateTime: [],  // Store multiple ReScannedDateTime values
          };

          // Parse the ReScannedDateTime JSON string into an array of objects
          let reScannedDateTimeArray = [];
          try {
            reScannedDateTimeArray = JSON.parse(row.Re_ScannedDateTime);
          } catch (error) {
            console.error("Error parsing Re_ScannedDateTime:", error);
          }

          // Check if ReScannedDateTime is available and push it to the array
          reScannedDateTimeArray.forEach(entry => {
            ticketDetail.ReScannedDateTime.push(timeUTCtoLocal(entry.ScannedDateTime));
          });

          acc[key].TicketDetails.push(ticketDetail);

          acc[key].TicketCost.push((row.Price !== "" && row.Price !== '0') ? formatCurrency(Number(row.Price), currencySymbol) : "Free");
          acc[key].DiscountCode.push(row.DiscountCode);
          // acc[key].DiscountAmount.push(row.DiscountAmount);

          // Add Discount Amount value only once for each unique ticket type
          if (!acc[key].DiscountAmount[ticketType] && row.DiscountAmount !== "") {
            acc[key].DiscountAmount[ticketType] =  (row.isBundle === "True") ? (parseFloat(row.DiscountAmount.replace(currencySymbol, "").replace(",", "")) * row.SlotPerBundle).toString() : row.DiscountAmount
          }

          // Add UBMe fee value only once for each unique ticket type
          if (!acc[key].UBMeFee[ticketType]) {
            acc[key].UBMeFee[ticketType] = (row.isAbsorb !== '1' ? (row.isBundle === "True")? (parseFloat(row.UBMeFeeAmount.replace(currencySymbol, "").replace(",", "")) * row.SlotPerBundle).toString() : row.UBMeFeeAmount : "")
          }

          // Add Tax fee value only once for each unique ticket type
          if (!acc[key].TaxFee[ticketType] && row.TaxAmount !== "") {
            acc[key].TaxFee[ticketType] = (row.isBundle === "True") ? (parseFloat(row.TaxAmount.replace(currencySymbol, "").replace(",", "")) * row.SlotPerBundle).toString() : row.TaxAmount
          }

          // Check if the notes is a string, try parsing it and push it to the array
          if (row.Notes !== "") {
            let parsedNotes = [];

            // Function to sanitize and parse JSON
            const sanitizeAndParseNotes = (notes) => {
              if (typeof notes === 'string') {
                // Replace any control characters that might break JSON parsing
                const sanitizedNotes = notes.replace(/[\u0000-\u0019]+/g, '');
                try {
                  return JSON.parse(sanitizedNotes);
                } catch (e) {
                  console.error('Failed to parse notes:', e);
                }
              } else if (Array.isArray(notes)) {
                return notes;
              }
            };

            // Parse the notes
            parsedNotes = sanitizeAndParseNotes(row.Notes);

            if (parsedNotes.length > 0) {
              acc[key].Notes = parsedNotes.join("\n\n");
            }
          }

          return acc;
        }, {});

        // Add data rows and apply alternate row background color
        let prevOrderId = null;
        let rowIndex = 6;
        Object.values(groupedRows).forEach((rowData, index) => {

          const {
            OrderId,
            FullName,
            Email,
            Phone,
            EventId,
            OrderDate,
            TicketDetails,
            TicketType,
            TicketCost,
            TicketStatuses,
            DiscountAmount,
            DiscountCode,
            TaxFee,
            UBMeFee,
            Amount,
            PdfFile,
            isBundle,
            SlotPerBundle,
            Notes
          } = rowData;

          // Initialize an object to store Ticket Title and corresponding Description
          const ticketTitleWithDescriptionData = {};

          // Loop through TicketType to group "TicketId" and "TicketTitle/Description" values
          TicketType.forEach((ticketType) => {
            const ticketId = ticketType.TicketId;
            const ticketName = ticketType.TicketName;
            const ticketDescription = ticketType.TicketDescription;

            if (!ticketTitleWithDescriptionData[ticketId]) {
              ticketTitleWithDescriptionData[ticketId] = {
                TicketName: ticketName,
                TicketDescription: ticketDescription,
              };
            }
          });

          // Initialize an object to store TicketId and corresponding Rescanned Date/Time
          const ticketRescannedData = {};

          // Loop through TicketDetails to group "TicketId" and "Rescanned Date/Time" values
          TicketDetails.forEach((ticketDetail) => {
            const ticketId = ticketDetail.TicketId;
            const ticketName = ticketDetail.TicketName;
            const confirmedDateTime = ticketDetail.ConfirmedDateTime
            const reScannedDateTimeValues = ticketDetail.ReScannedDateTime.join("\n"); // Join with line breaks

            if (!ticketRescannedData[ticketId]) {
              ticketRescannedData[ticketId] = {
                TicketName: ticketName,
                ConfirmedDateTime: confirmedDateTime,
                RescannedDateTimes: [],
              };
            }

            // Add ConfirmedDateTime at the beginning of the RescannedDateTimes array
            ticketRescannedData[ticketId].RescannedDateTimes.unshift(confirmedDateTime);

            ticketRescannedData[ticketId].RescannedDateTimes = ticketRescannedData[ticketId].RescannedDateTimes.concat(reScannedDateTimeValues);
          });

          const ubmeFeeValues = Object.values(UBMeFee).map(value => {
            const amount = parseFloat(value.replace(currencySymbol, "").replace(",", ""));
            return isNaN(amount) ? 0 : amount;
          });

          const taxFeeValues = Object.values(TaxFee).map(value => {
            const amount = parseFloat(value.replace(currencySymbol, "").replace(",", ""));
            return isNaN(amount) ? 0 : amount;
          });

          const nonEmptyDiscountCode = DiscountCode.find(code => code !== "");

          const nonEmptyDiscountAmount = Object.values(DiscountAmount).map(value => {
            const amount = parseFloat(value.replace(currencySymbol, "").replace(",", ""));
            return isNaN(amount) ? 0 : amount;
          });

          // const nonEmptyDiscountAmount = DiscountAmount.find(amount => amount !== "");

          let rescannedDateTimeValues = "";
          rescannedDateTimeValues = Object.keys(ticketRescannedData).map((id) => {
            const rescannedData = ticketRescannedData[id];
            if (rescannedData.RescannedDateTimes[0] !== undefined && rescannedData.RescannedDateTimes[0] !== "") {
              return `${rescannedData.TicketName}:\n${id}\n${rescannedData.RescannedDateTimes.join("\n")}`;
            }
          }).filter(Boolean).join("\n\n");

          let ticketTypeWithDescriptionValues = "";
          ticketTypeWithDescriptionValues = Object.keys(ticketTitleWithDescriptionData).map((id) => {
            const ticketTypeWithDescriptionData = ticketTitleWithDescriptionData[id];
            if (ticketTypeWithDescriptionData.TicketDescription[0] !== undefined && ticketTypeWithDescriptionData.TicketDescription[0] !== "") {
              return `${ticketTypeWithDescriptionData.TicketName}:\n${id}:\n${ticketTypeWithDescriptionData.TicketDescription}`;
            } else {
              return `${ticketTypeWithDescriptionData.TicketName}:\n${id}`;
            }
          }).filter(Boolean).join("\n\n");

          const ticketStatuses = [...new Set(TicketStatuses)]; // Remove duplicate statuses
          let formattedStatus = "";

          if (ticketStatuses.every(status => status === "Refunded")) {
            formattedStatus = "Refunded"; // All tickets refunded
          } else if (ticketStatuses.every(status => status === "CheckedIn")) {
            formattedStatus = ""; // All tickets checked in
          } else if (ticketStatuses.includes("Refunded")) {
            formattedStatus = "Partially Refunded"; // Some tickets refunded
          } else if (ticketStatuses.includes("CheckedIn")) {
            formattedStatus = ""; // Some tickets checked in
          } else {
            formattedStatus = ""; // Add more cases if needed
          }

          // Calculate the sum of UBMeFee values
          const DiscountAmountSum = nonEmptyDiscountAmount.reduce((sum, value) => sum + value, 0);

          // Calculate the sum of UBMeFee values
          const ubmeFeeSum = ubmeFeeValues.reduce((sum, value) => sum + value, 0);

          // Calculate the sum of TaxFee values
          const taxFeeSum = taxFeeValues.reduce((sum, value) => sum + value, 0);

          // Define the array of values for predefined columns
          const predefinedDataArray = [
            OrderId,
            EventId,
            FullName,
            Email,
            Phone,
            OrderDate,
            rescannedDateTimeValues,
            formattedStatus,
            ticketTypeWithDescriptionValues,
            TicketType.length,
            TicketCost.join("\n"),
            (DiscountAmountSum !== undefined && DiscountAmountSum !== "" && DiscountAmountSum !== 0) ? formatCurrency(DiscountAmountSum, currencySymbol) : "",
            (nonEmptyDiscountCode !== undefined && nonEmptyDiscountCode !== "") ? nonEmptyDiscountCode : "",
            (taxFeeSum !== undefined && taxFeeSum !== "" && taxFeeSum !== 0) ? formatCurrency(taxFeeSum, currencySymbol) : "",
            (ubmeFeeSum !== 0) ? formatCurrency(ubmeFeeSum, currencySymbol) : "",
            (Amount !== "" && Amount !== '0') ? formatCurrency(Amount, currencySymbol) : "Free",
            PdfFile,
            Notes,
          ];

          // Create an array for additional column data based on column order
          const additionalDataArray = additionalColumns.map(column => rowData[column.key] || '');

          // Combine predefined and additional data arrays
          const rowDataArray = predefinedDataArray.concat(additionalDataArray);

          // Add the row to the worksheet
          const orderRow = worksheet.addRow(rowDataArray);

          // Apply alternate row background color
          orderRow.fill = {type: "pattern", pattern: "solid", fgColor: {argb: index % 2 === 0 ? "FFFFFF" : "EFEFEF"}};

          // Get the column index for "TicketType"
          const ticketTypeColumnIndex = predefinedColumns.findIndex(header => header.key === "TicketType");
          let ticketTypeCell = orderRow.getCell(ticketTypeColumnIndex + 1);
          if (ticketTypeCell.value) {
            ticketTypeCell.alignment = {wrapText: true};
          } else {
            ticketTypeCell.value = ""; // Empty cell if no data
          }

          // Get the column index for "OrderPDF"
          const orderPdfColumnIndex = predefinedColumns.findIndex(header => header.key === "OrderPDF");
          let orderPdfCell = orderRow.getCell(orderPdfColumnIndex + 1);
          if (orderPdfCell.value) {
            const hyperlinkText = orderPdfCell.value;
            orderPdfCell.value = {
              text: hyperlinkText,
              hyperlink: hyperlinkText, // Provide the URL here
            };
            orderPdfCell.alignment = {wrapText: true, horizontal: "center"};
            orderPdfCell.font = {color: {argb: "0000FF"}, underline: true};
          } else {
            orderPdfCell.value = ""; // Empty cell if no PDF link
          }

          if (prevOrderId !== OrderId) {
            prevOrderId = OrderId;
          }

          rowIndex++;
        });

        // Set specific column widths based on column index
        worksheet.columns.forEach((column, columnIndex) => {
          if (columnIndex === 0 || columnIndex === 1 || columnIndex === 2 || columnIndex === 6 || columnIndex === 7 || columnIndex === 8 || columnIndex === 10) {
            column.width = 40;
          } else if (columnIndex === 3 || columnIndex === 4 || columnIndex === 5 ) {
            column.width = 25;
          } else if (columnIndex === 16 || columnIndex === 17) {
            column.width = 100;
          } else {
            column.width = 15
          }
        });

        // Function to apply center alignment to specified columns
        const applyCenterAlignmentToColumns = (worksheet, columnKeys) => {
          columnKeys.forEach((key) => {
            const column = worksheet.getColumn(key);
            column.eachCell((cell) => {
              cell.alignment = { horizontal: "center", vertical: 'middle', wrapText: true };
            });
          });
        };

        // Call the function to apply center alignment to "TicketCost" and "Discount" columns
        applyCenterAlignmentToColumns(worksheet, [10, 11, 12, 13, 14, 15, 16]);

        // Function to apply bold and green font to "OrderTotal" column
        const applyBoldAndGreenFontToColumn = (worksheet, columnKey) => {
          const column = worksheet.getColumn(columnKey);
          column.eachCell((cell, rowNumber) => {
            if (rowNumber !== 1) {
              cell.font = {bold: true, color: {argb: "00AA00"}}; // Green color code
            }
          });
        };

        // Call the function to apply bold and green font to "OrderTotal" column
        applyBoldAndGreenFontToColumn(worksheet, 16);

        if (data.results.EventAnalytic) {
          let response = data.results.EventAnalytic;

          // Set "Total Sales Value" and "Total Fees Value" values
          const totalSalesCell = worksheet.getCell("A2");
          totalSalesCell.value = formatCurrency(response.Sale, currencySymbol);
          Object.assign(totalSalesCell, headerCellStyle("406BCA", "FFFFFF"));

          const totalTaxFeesCell = worksheet.getCell("B2");
          totalTaxFeesCell.value = formatCurrency(response.TaxAmount, currencySymbol);
          Object.assign(totalTaxFeesCell, headerCellStyle("406BCA", "FFFFFF"));

          const totalUBMeFeesCell = worksheet.getCell("C2");
          totalUBMeFeesCell.value = formatCurrency(response.UBMeFeeAmount, currencySymbol);
          Object.assign(totalUBMeFeesCell, headerCellStyle("406BCA", "FFFFFF"));

          // const totalProcessingFeesCell = worksheet.getCell("D2");
          // totalProcessingFeesCell.value = response.ProcessingFee;
          // totalProcessingFeesCell.numFmt = "$#,##0.00";
          // totalProcessingFeesCell.fill = {type: "pattern", pattern: "solid", fgColor: {argb: "406BCA"}};
          // totalProcessingFeesCell.alignment = {horizontal: "center"}; // Center-align text
          // totalProcessingFeesCell.font = {color: {argb: "FFFFFF"}}; // White text color

          const totalPayoutCell = worksheet.getCell("D2");
          totalPayoutCell.value = formatCurrency(response.TotalPayout, currencySymbol);
          Object.assign(totalPayoutCell, headerCellStyle("2D5A1C", "FFFFFF"));

          // Set the width for the entire column E
          worksheet.getColumn('E').width = 20; // Adjust the width as needed

          const creditCardMessageCell = worksheet.getCell("E2");
          creditCardMessageCell.value = "**Credit card processing fees NOT included";
          Object.assign(creditCardMessageCell, headerCellStyle("406BCA", "FFFFFF", true));
        }

        // Generate XLSX file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        FileSaver.saveAs(blob, `${Title}.xlsx`);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const downloadEmailInvitationContacts = async (EventId, Title, TimeZone) => {
    try {
      // Fetch Attendees list
      const { data } = await axiosE.get(`DownloadAllAttendeeByEventId/${EventId}`);

      const timeUTCtoLocal = (date) => {
        var local = moment.tz(moment.utc(date), "MM/DD/YYYY hh:mm:ss a", TimeZone).format("MM/DD/YYYY hh:mm:ss a");
        return moment(local, "MM/DD/YYYY hh:mm:ss a").format("llll");
      };

      if (data?.results?.ListAttendee?.length > 0) {
        const filterdObjects = jsonFormatterHelper(data.results.ListAttendee);

        // Create a new workbook
        const workbook = new ExcelJS.Workbook();

        // Add a worksheet to the workbook
        const worksheet = workbook.addWorksheet("Attendee List");

        // Set column headers and make them bold
        const predefinedColumns = [
          { header: "Order ID", key: "OrderId", width: 15 },
          { header: "Event ID", key: "EventId", width: 15 },
          { header: "Full Name", key: "FullName", width: 20 },
          { header: "Email", key: "Email", width: 25 },
          { header: "Phone", key: "Phone", width: 15 },
          { header: "Order Date", key: "OrderDate", width: 20 },
          { header: "Confirmed Date/Time", key: "ScannedDateTime", width: 20 },
          { header: "Status", key: "Status", width: 15 },
          { header: "Ticket Type", key: "TicketType", width: 30 },
          { header: "Ticket Quantity", key: "TicketQuantity", width: 15 },
          { header: "Ticket ID", key: "TicketId", width: 15 },
          { header: "Ticket Cost", key: "TicketCost", width: 15 },
          { header: "Discount", key: "Discount", width: 15 },
          { header: "Discount Code", key: "DiscountCode", width: 15 },
          { header: filterdObjects[0].TaxName !== '' ? filterdObjects[0].TaxName : "Tax", key: "Tax", width: 15 },
          { header: "UBMe Fee", key: "UBMeFee", width: 15 },
          { header: "Order Total", key: "OrderTotal", width: 15 },
          { header: "Order PDF", key: "OrderPDF", width: 50 },
        ];

        // Convert UTC OrderDate to local time in sortedObjects
        const sortedObjects = filterdObjects.map((obj) => {
          const localOrderDate = timeUTCtoLocal(obj.OrderDate); // Assuming the OrderDate field contains UTC timestamps
          return { ...obj, OrderDate: localOrderDate };
        });

        // Sort the sortedObjects array in descending order based on OrderDate
        sortedObjects.sort((a, b) => new Date(b.OrderDate) - new Date(a.OrderDate));

        // Define a list of columns to exclude
        const columnsToExclude = [
          "Amount",
          "BookingDate",
          "BookingTime",
          "CurrencyCode",
          "CurrencySymbol",
          "DiscountAmount",
          "DiscountCode",
          "Email",
          "EventId",
          "FullName",
          "OrderDate",
          "OrderId",
          "PdfFile",
          "Phone",
          "QRCode",
          "Price",
          "RecurringInterval",
          "RecurringTicket",
          "ScannedDateTime",
          "Re_ScannedDateTime",
          "Status",
          "TaxAmount",
          "TaxName",
          "Title",
          "TotalCounts",
          "UBMeFeeAmount",
          "isAbsorb",
          "Terms_And_Conditions",
          "TermsAndConditions",
          "isBundle",
          "SlotPerBundle",
          "TicketDescription",
          "Notes",
        ];

        // Define additional columns by filtering out excluded columns
        const additionalColumns = Object.keys(sortedObjects[0]).filter((columnName) => {
          return !columnsToExclude.includes(columnName);
        }).map((columnName) => ({
          header: columnName.replace(/_/g, ' '), // Display name for the column
          key: columnName,    // Unique identifier for the column
          width: 15,          // Optional: specify the column width
        }));

        // Combine predefined and additional columns for the header row
        const allHeaders = predefinedColumns.concat(additionalColumns);

        // Extract keys from allHeaders
        const allHeadersKeys = allHeaders.map(header =>  header.header);

        // Add the header row for detailed headers (5th row) with headers and widths
        const headerRow = worksheet.addRow(allHeadersKeys.map(key => key)); // Include allHeadersKeys here if needed
        headerRow.font = { bold: true };

        // Loop through each cell in the header row and set wrapText alignment
        // headerRow.eachCell({ includeEmpty: true }, (cell) => {
        //   cell.alignment = { wrapText: true };
        // });

        let currencySymbol = ""

        // Group rows by orderId and collect ticket details for each orderId
        const groupedRows = sortedObjects.reduce((acc, row) => {
          const key = `${row.OrderId}-${row.FullName}-${row.Email}-${row.Phone}-${row.OrderDate}`;
          if (!acc[key]) {
            acc[key] = { ...row, TicketType: [], TicketId: [], TicketCost: [], UBMeFee: [], TicketStatuses: [], TaxFee: [], DiscountCode: [], DiscountAmount: [], TicketDetails: [] };
          }

          currencySymbol = row.CurrencySymbol;

          const ticketType = (row.Status === 'Refunded') ? `${row.Title} (Refunded)` : row.Title;

          acc[key].TicketType.push(ticketType);
          acc[key].TicketId.push(row.QRCode);
          acc[key].TicketStatuses.push(row.Status);

          const ticketDetail = {
            TicketName: row.Title,
            TicketId: row.QRCode,
            ConfirmedDateTime: row.ScannedDateTime !== "" ? timeUTCtoLocal(row.ScannedDateTime) : "",
            ReScannedDateTime: [],  // Store multiple ReScannedDateTime values
          };

          // Parse the ReScannedDateTime JSON string into an array of objects
          let reScannedDateTimeArray = [];
          try {
            reScannedDateTimeArray = JSON.parse(row.Re_ScannedDateTime);
          } catch (error) {
            console.error("Error parsing Re_ScannedDateTime:", error);
          }

          // Check if ReScannedDateTime is available and push it to the array
          reScannedDateTimeArray.forEach(entry => {
            ticketDetail.ReScannedDateTime.push(timeUTCtoLocal(entry.ScannedDateTime));
          });

          acc[key].TicketDetails.push(ticketDetail);

          acc[key].TicketCost.push((row.Price !== "" && row.Price !== '0') ? formatCurrency(Number(row.Price), currencySymbol) : "Free");
          acc[key].DiscountCode.push(row.DiscountCode);
          // acc[key].DiscountAmount.push(row.DiscountAmount);

          // Add Discount Amount value only once for each unique ticket type
          if (!acc[key].DiscountAmount[ticketType] && row.DiscountAmount !== "") {
            acc[key].DiscountAmount[ticketType] =  (row.isBundle === "True") ? (parseFloat(row.DiscountAmount.replace(currencySymbol, "").replace(",", "")) * row.SlotPerBundle).toString() : row.DiscountAmount
          }

          // Add UBMe fee value only once for each unique ticket type
          if (!acc[key].UBMeFee[ticketType]) {
            acc[key].UBMeFee[ticketType] = (row.isAbsorb !== '1' ? (row.isBundle === "True")? (parseFloat(row.UBMeFeeAmount.replace(currencySymbol, "").replace(",", "")) * row.SlotPerBundle).toString() : row.UBMeFeeAmount : "")
          }

          // Add Tax fee value only once for each unique ticket type
          if (!acc[key].TaxFee[ticketType] && row.TaxAmount !== "") {
            acc[key].TaxFee[ticketType] = (row.isBundle === "True") ? (parseFloat(row.TaxAmount.replace(currencySymbol, "").replace(",", "")) * row.SlotPerBundle).toString() : row.TaxAmount
          }

          return acc;
        }, {});

        // Add data rows and apply alternate row background color
        let prevOrderId = null;
        let rowIndex = 6;
        Object.values(groupedRows).forEach((rowData, index) => {

          const {
            OrderId,
            FullName,
            Email,
            Phone,
            EventId,
            OrderDate,
            TicketDetails,
            TicketType,
            TicketId,
            TicketCost,
            TicketStatuses,
            DiscountAmount,
            DiscountCode,
            TaxFee,
            UBMeFee,
            Amount,
            PdfFile,
            isBundle,
            SlotPerBundle
          } = rowData;

          // Initialize an object to store TicketId and corresponding Rescanned Date/Time
          const ticketRescannedData = {};

          // Loop through TicketDetails to group "TicketId" and "Rescanned Date/Time" values
          TicketDetails.forEach((ticketDetail) => {
            const ticketId = ticketDetail.TicketId;
            const ticketName = ticketDetail.TicketName;
            const confirmedDateTime = ticketDetail.ConfirmedDateTime
            const reScannedDateTimeValues = ticketDetail.ReScannedDateTime.join("\n"); // Join with line breaks

            if (!ticketRescannedData[ticketId]) {
              ticketRescannedData[ticketId] = {
                TicketName: ticketName,
                ConfirmedDateTime: confirmedDateTime,
                RescannedDateTimes: [],
              };
            }

            // Add ConfirmedDateTime at the beginning of the RescannedDateTimes array
            ticketRescannedData[ticketId].RescannedDateTimes.unshift(confirmedDateTime);

            ticketRescannedData[ticketId].RescannedDateTimes = ticketRescannedData[ticketId].RescannedDateTimes.concat(reScannedDateTimeValues);
          });

          const ubmeFeeValues = Object.values(UBMeFee).map(value => {
            const amount = parseFloat(value.replace(currencySymbol, "").replace(",", ""));
            return isNaN(amount) ? 0 : amount;
          });

          const taxFeeValues = Object.values(TaxFee).map(value => {
            const amount = parseFloat(value.replace(currencySymbol, "").replace(",", ""));
            return isNaN(amount) ? 0 : amount;
          });

          const nonEmptyDiscountCode = DiscountCode.find(code => code !== "");

          const nonEmptyDiscountAmount = Object.values(DiscountAmount).map(value => {
            const amount = parseFloat(value.replace(currencySymbol, "").replace(",", ""));
            return isNaN(amount) ? 0 : amount;
          });

          // const nonEmptyDiscountAmount = DiscountAmount.find(amount => amount !== "");

          let rescannedDateTimeValues = "";
          rescannedDateTimeValues = Object.keys(ticketRescannedData).map((id) => {
            const rescannedData = ticketRescannedData[id];
            if (rescannedData.RescannedDateTimes[0] !== undefined && rescannedData.RescannedDateTimes[0] !== "") {
              return `${rescannedData.TicketName}:\n${id}:\n${rescannedData.RescannedDateTimes.join("\n")}`;
            }
          }).filter(Boolean).join("\n\n");

          const ticketStatuses = [...new Set(TicketStatuses)]; // Remove duplicate statuses
          let formattedStatus = "";

          if (ticketStatuses.every(status => status === "Refunded")) {
            formattedStatus = "Refunded"; // All tickets refunded
          } else if (ticketStatuses.every(status => status === "CheckedIn")) {
            formattedStatus = ""; // All tickets checked in
          } else if (ticketStatuses.includes("Refunded")) {
            formattedStatus = "Partially Refunded"; // Some tickets refunded
          } else if (ticketStatuses.includes("CheckedIn")) {
            formattedStatus = ""; // Some tickets checked in
          } else {
            formattedStatus = ""; // Add more cases if needed
          }

          // Calculate the sum of UBMeFee values
          const DiscountAmountSum = nonEmptyDiscountAmount.reduce((sum, value) => sum + value, 0);

          // Calculate the sum of UBMeFee values
          const ubmeFeeSum = ubmeFeeValues.reduce((sum, value) => sum + value, 0);

          // Calculate the sum of TaxFee values
          const taxFeeSum = taxFeeValues.reduce((sum, value) => sum + value, 0);

          // Define the array of values for predefined columns
          const predefinedDataArray = [
            OrderId,
            EventId,
            FullName,
            Email,
            Phone,
            OrderDate,
            rescannedDateTimeValues,
            formattedStatus,
            TicketType.join("\n"),
            TicketType.length,
            TicketId.join("\n"),
            TicketCost.join("\n"),
            (DiscountAmountSum !== undefined && DiscountAmountSum !== "" && DiscountAmountSum !== 0) ? formatCurrency(DiscountAmountSum, currencySymbol) : "",
            (nonEmptyDiscountCode !== undefined && nonEmptyDiscountCode !== "") ? nonEmptyDiscountCode : "",
            (taxFeeSum !== undefined && taxFeeSum !== "" && taxFeeSum !== 0) ? formatCurrency(taxFeeSum, currencySymbol) : "",
            (ubmeFeeSum !== 0) ? formatCurrency(ubmeFeeSum, currencySymbol) : "",
            (Amount !== "" && Amount !== '0') ? formatCurrency(Amount, currencySymbol) : "Free",
            PdfFile
          ];

          // Create an array for additional column data based on column order
          const additionalDataArray = additionalColumns.map(column => rowData[column.key] || '');

          // Combine predefined and additional data arrays
          const rowDataArray = predefinedDataArray.concat(additionalDataArray);

          // Add the row to the worksheet
          const orderRow = worksheet.addRow(rowDataArray);

          // Apply alternate row background color
          orderRow.fill = {type: "pattern", pattern: "solid", fgColor: {argb: index % 2 === 0 ? "FFFFFF" : "EFEFEF"}};

          // Get the column index for "OrderPDF"
          const orderPdfColumnIndex = predefinedColumns.findIndex(header => header.key === "OrderPDF");
          let orderPdfCell = orderRow.getCell(orderPdfColumnIndex + 1);
          if (orderPdfCell.value) {
            const hyperlinkText = orderPdfCell.value;
            orderPdfCell.value = {
              text: hyperlinkText,
              hyperlink: hyperlinkText, // Provide the URL here
            };
            orderPdfCell.alignment = {wrapText: true, horizontal: "center"};
            orderPdfCell.font = {color: {argb: "0000FF"}, underline: true};
          } else {
            orderPdfCell.value = ""; // Empty cell if no PDF link
          }

          if (prevOrderId !== OrderId) {
            prevOrderId = OrderId;
          }

          rowIndex++;
        });

        // Set specific column widths based on column index
        worksheet.columns.forEach((column, columnIndex) => {
          if (columnIndex === 0 || columnIndex === 1 || columnIndex === 2 || columnIndex === 6 || columnIndex === 7 || columnIndex === 8 || columnIndex === 10) {
            column.width = 40;
          } else if (columnIndex === 3 || columnIndex === 4 || columnIndex === 5 ) {
            column.width = 25;
          } else if (columnIndex === 17) {
            column.width = 100;
          } else {
            column.width = 15
          }
        });

        // Function to apply center alignment to specified columns
        const applyCenterAlignmentToColumns = (worksheet, columnKeys) => {
          columnKeys.forEach((key) => {
            const column = worksheet.getColumn(key);
            column.eachCell((cell) => {
              cell.alignment = { horizontal: "center", vertical: 'middle', wrapText: true };
            });
          });
        };

        // Call the function to apply center alignment to "TicketCost" and "Discount" columns
        applyCenterAlignmentToColumns(worksheet, [10, 12, 13, 14, 15, 16, 17]);

        // Function to apply bold and green font to "OrderTotal" column
        const applyBoldAndGreenFontToColumn = (worksheet, columnKey) => {
          const column = worksheet.getColumn(columnKey);
          column.eachCell((cell, rowNumber) => {
            if (rowNumber !== 1) {
              cell.font = {bold: true, color: {argb: "00AA00"}}; // Green color code
            }
          });
        };

        // Call the function to apply bold and green font to "OrderTotal" column
        applyBoldAndGreenFontToColumn(worksheet, 17);

        // Generate XLSX file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        FileSaver.saveAs(blob, `${Title}.xlsx`);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const downloadPDF = async (EventId, Title, TimeZone, EventStartDate, EventEndDate) => {
    try {
      // Fetch Attendees list
      const { data } = await axiosE.get(`DownloadAllAttendeeByEventId/${EventId}`);

      const timeUTCtoLocal = (date) => {
        var local = moment.tz(moment.utc(date), "MM/DD/YYYY hh:mm:ss a", TimeZone).format("MM/DD/YYYY hh:mm:ss a");
        return moment(local, "MM/DD/YYYY hh:mm:ss a").format("llll");
      };

      if (data?.results?.ListAttendee?.length > 0) {
        const filteredObjects = jsonFormatterHelper(data.results.ListAttendee);

        // Set column headers
        const allHeaders = [
          { header: "", key: "CheckMark"},
          { header: "Order ID", key: "OrderId"},
          { header: "Full Name", key: "FullName"},
          { header: "Email", key: "Email"},
          { header: "Phone", key: "Phone"},
          { header: "Ticket Type", key: "TicketType"},
        ];

        // Convert UTC OrderDate to local time in sortedObjects
        const sortedObjects = filteredObjects.map((obj) => {
          const localOrderDate = timeUTCtoLocal(obj.OrderDate); // Assuming the OrderDate field contains UTC timestamps
          return { ...obj, OrderDate: localOrderDate };
        });

        // Sort the sortedObjects array in descending order based on OrderDate
        sortedObjects.sort((a, b) => new Date(b.OrderDate) - new Date(a.OrderDate));

        // Define rows data
        const rows = sortedObjects.map((attendee, index) => [
          "",
          attendee.OrderId,
          attendee.FullName,
          attendee.Email,
          attendee.Phone,
          attendee.Title,
        ]);

        // Define the font you want to use
        const fontName = "Arial";

        // Create a new PDF document
        const doc = new jsPDF('landscape');
        doc.setFont(fontName);

        // Center-align the title
        const titleWidth = doc.getStringUnitWidth(Title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        const titleX = (doc.internal.pageSize.width - titleWidth) / 2;
        // Set text color to red
        doc.setTextColor('#851679');
        doc.text(Title, titleX, 10);
        doc.setFontSize(18)

        // change back to what we want for other stuff
        doc.setFontSize(12);
        doc.setTextColor(0, 0, 0);

        // Format event start date-time and end date-time
        const eventStart = moment.tz(EventStartDate, TimeZone).format("dddd, MMMM DD, YYYY hh:mm A");
        let eventEnd = moment.tz(EventEndDate, TimeZone).format("dddd, MMMM DD, YYYY hh:mm A");
        // Display end time only if it's different from the start date
        if (moment(EventStartDate).format("YYYY-MM-DD") === moment(EventEndDate).format("YYYY-MM-DD")) {
          eventEnd = moment.tz(EventEndDate, TimeZone).format("hh:mm A");
        }

        // Center-align the start and end date-time
        const eventDateTimeWidth = doc.getStringUnitWidth(eventStart + " - " + eventEnd) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        const eventDateTimeX = (doc.internal.pageSize.width - eventDateTimeWidth) / 2;
        doc.text(eventStart + " - " + eventEnd, eventDateTimeX, 20);

        // Customize the content of the cell using the didParseCell hook
        const didParseCell = function (data) {
          data.cell.styles.font = fontName;
        };

        // Draw rectangle in the first column after cell is drawn
        const didDrawCell = function (data) {
          if (data.column.index === 0 && data.section === 'body') { // Draw for data rows only
            const padding = 2; // Adjust padding as needed
            const cellWidth = data.cell.width; // Increase cell width by 10 units
            const cellHeight = data.cell.height;
            const startX = data.cell.x + padding;
            const startY = data.cell.y + padding;
            const rectSize = Math.min(cellWidth, cellHeight) - (2 * padding); // Choose the smaller dimension as the size of the square
            doc.rect(startX, startY, rectSize, rectSize);
          }
        };

        // Generate autoTable after event date-time
        doc.autoTable({
          headStyles: {
            fillColor: '#851679'
          },
          head: [allHeaders.map(header => header.header)],
          body: rows,
          startY: 40,
          columnStyles: {
            0: { // index of the first column
              cellWidth: 8 // specify the width for the first column
            }
          },
          didParseCell: didParseCell,
          didDrawCell: didDrawCell,
        });

        // Add footer with page numbers
        const totalPages = doc.internal.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
          doc.setPage(i);
          doc.text('Page ' + i + ' of ' + totalPages, doc.internal.pageSize.width / 2, doc.internal.pageSize.height - 10, { align: 'center' });
        }

        // Save the PDF
        doc.save(`${Title}.pdf`);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const paperStyles = {
    root: {
      height: "54px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: `0 20px 0 10px`,
      margin: "0 auto 7px auto",
    },
  };

  const ColoredPaper = withStyles(paperStyles)(Paper);

  const classes = useStyles();
  function ListItemLink(props) {
    return (
        <ListItem
            primaryTypographyProps={{ style: { fontSize: 14 } }}
            button
            component="a"
            {...props}
        />
    );
  }

  const columns = React.useMemo(
      () => [
        {
          Header: "Event",
          accessor: "OrderId",
          width: 400,
          Cell: ({ row }) => {
            //console.log("Row", row.original);

            const { StartDate, ImageSmall, Title, Add1, VenueName, TimeZone } =
                row.original;
            return (
                <div className="sales_event_list_wrapper">
                  <div className="sales_event_list">
                    <div>
                      <p
                          style={{
                            color: "#fc6603",
                            fontWeight: "600",
                            textAlign: "center",
                          }}
                          className="sales_event_list_text"
                      >
                        {moment(convertTimeUtcToLocal(StartDate, TimeZone))
                            .format("MMM")
                            .toLocaleUpperCase()}
                      </p>
                      <p
                          style={{ textAlign: "center" }}
                          className="sales_event_list_text"
                      >
                        {moment(convertTimeUtcToLocal(StartDate, TimeZone)).format(
                            "DD"
                        )}
                      </p>
                    </div>

                    <img
                        className="sales_event_list_img"
                        src={ImageSmall}
                        alt="sales_event"
                    />
                  </div>
                  <div style={{ padding: 10 }}>
                    <a
                        target="_blank"
                        href={`${constants.URLs.hostName}${routeName}/event/${row.original.EventId}`}
                    >
                      <p className="sales_event_list_text_header">{Title}</p>
                      <p
                          style={{ color: "rgb(57 179 174)" }}
                          className="sales_event_list_text"
                      >
                        {VenueName}
                      </p>
                    </a>
                    <p className="sales_event_list_text">
                      {moment(convertTimeUtcToLocal(StartDate, TimeZone)).format(
                          "llll"
                      )}
                    </p>
                  </div>
                </div>
            );
          },
        },
        {
          Header: "Engagement Score",
          accessor: "PercentageCheckedIn",
          className: "bg-red",
          width: 250,

          Cell: ({ row }) => (
              <div className="sale_EngagementWheel_wrapper">
                <CircularProgressbar
                    className="sale_EngagementWheel"
                    value={Number(row.original.PercentageCheckedIn)}
                    text={`${(Number(row.original.PercentageCheckedIn))}%`}
                    styles={buildStyles({
                      textSize: "20px",
                      textColor: "purple",
                      pathColor: "purple",
                    })}
                />
              </div>
          ),
        },
        {
          Header: "Ticket Holders",
          accessor: "StartDateTime",
          width: 200,
          Cell: ({ row }) => {
            //   const UtcToLocal =  moment.utc(row.original.StartDateTime).local().format('MM/DD/YYYY hh:mm:ss A')
            //   var Time = moment(UtcToLocal, "MM/DD/YYYY hh:mm:ss A");
            //   const convertedTime = Time.tz(row.original.EventTimeZone);
            return (
                <div>
                  <p style={{ textAlign: "center", marginBottom: 10 }}>
                    {Intl.NumberFormat().format(row.original.TicketSold)}/
                    {Intl.NumberFormat().format(row.original.TicketsIssue)}
                  </p>

                  <BorderLinearProgress
                      variant="determinate"
                      value={
                          (Number(row.original.TicketSold) /
                              Number(row.original.TicketsIssue)) *
                          100
                      }
                  />
                </div>
            );
          },
        },
        {
          Header: "Sales",
          accessor: "Sales",
          width: 100,
          Cell: ({ row }) => (
              <div>
                <p className="total_price_text">
                  {row.original.CurrencySymbol}
                  {Number(row.original.Sales)
                      ? Intl.NumberFormat(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(row.original.Sales)
                      : 0}
                </p>
              </div>
          ),
        },
        {
          Header: "Status",
          accessor: "Status",
          width: 50,
          Cell: ({ row }) => (
              <div>
                {row.original.Status === "Published" ? (
                    <p style={{ color: "rgb(0, 171, 85)" }}>Live</p>
                ) : (
                    <p
                        style={{
                          color:
                              row.original.Status === "Canceled"
                                  ? "rgb(255, 48, 48)"
                                  : "#808080",
                        }}
                    >
                      {row.original.Status}
                    </p>
                )}
              </div>
          ),
        },
        {
          Header: " App Engagements",
          accessor: "app_interaction",
          width: 250,
          Cell: ({ row }) => {
            return <h5>0</h5>;
          },
        },
        {
          Header: "Landing Page Views",
          accessor: "landing",
          width: 250,
          Cell: ({ row }) => {
            return (
                <h5>
                  {row.original.EngagementCount !== ""
                      ? Intl.NumberFormat().format(row.original.EngagementCount)
                      : 0}
                </h5>
            );
          },
        },
        {
          Header: "Check-ins",
          accessor: "TicketSold",
          width: 200,
          Cell: ({ row }) => {
            return <h5>0</h5>;
          },
        },
        {
          Header: "Ticket Holders",
          accessor: "attendee",
          width: 200,
          Cell: ({ row }) => {
            return (
                <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                  <GrDownload />
                  <div>
                    <h5>{Intl.NumberFormat().format(row.original.TicketSold)}</h5>
                    <a
                        onClick={() =>
                            Number(row.original.TicketSold) > 0
                                ? downloadFullReportXLSX(
                                    row.original.EventId,
                                    row.original.Title,
                                    row.original.TimeZone
                                )
                                : {}
                        }
                        style={{
                          color:
                              Number(row.original.TicketSold) > 0
                                  ? "rgb(74 135 226)"
                                  : "rgb(178, 206, 247)",
                        }}
                        href="#"
                    >
                      Detailed Report
                    </a>
                  </div>
                </div>
            );
          },
        },
        {
          Header: "",
          accessor: "ChesckedIn",
          width: 10,
          Cell: ({ row }) => {
            const [open, setOpen] = useState(false);
            UseOutsideClick(
                () => {
                  if (open) {
                    setOpen(false);
                  }

                },
                ".Menu-list-wrapper",
                menuRef
            );
            return (
                <div style={{ position: "relative" }}>
                  <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={() => setOpen(true)}
                  >
                    <MoreVertIcon size={20} />
                  </IconButton>
                  {open ? (
                      <div className="Menu-list-wrapper">
                        <div className="Menu-list-popover">
                          <div className={classes.root}>
                            <List component="nav" aria-label="main mailbox folders">
                              {row.original.Status !== "Ended" &&
                                  row.original.Status !== "Canceled" && (
                                      <ListItemLink
                                          target="_blank"
                                          href={`${constants.URLs.hostName}${routeName}/event/${row.original.EventId}`}
                                      >
                                        <ListItemText
                                            primaryTypographyProps={{
                                              style: { fontSize: 14 },
                                            }}
                                            primary="View"
                                        />
                                      </ListItemLink>
                                  )}

                              {row.original.Status !== "Canceled" &&
                                  row.original.Status !== "Ended" && (
                                      <ListItem
                                          onClick={() => {
                                            setOpen(false);
                                            localStorage.setItem("isRecurring",
                                                row.original?.IsRecurringTicketPresent === "1"
                                                    ? true
                                                    : false
                                            );
                                            editThisEvent(row.original.EventId);
                                            localStorage.setItem('TotalScheduledChatCount',0);
                                          }}
                                          button
                                      >
                                        <ListItemText
                                            primaryTypographyProps={{
                                              style: { fontSize: 14 },
                                            }}
                                            primary="Edit"
                                        />
                                      </ListItem>
                                  )}

                              {row.original.Status === "Published" && (
                                  <ListItem
                                      onClick={() => {
                                        setOpen(false);
                                        shareButton(row.original.EventId)
                                        /*navigator.clipboard.writeText(
                                          `${constants.URLs.hostName}${routeName}/event/${row.original.EventId}`
                                        );*/
                                      }}
                                      button
                                  >
                                    <ListItemText
                                        primaryTypographyProps={{
                                          style: { fontSize: 14 },
                                        }}
                                        primary="Share"
                                    />
                                  </ListItem>
                              )}

                              <ListItem
                                  onClick={() => {
                                    setOpen(false);
                                    cloneThisEvent(row.original.EventId);
                                  }}
                                  button
                              >
                                <ListItemText
                                    primaryTypographyProps={{ style : {fontSize: 14} }}
                                    primary="Clone"
                                />
                              </ListItem>

                              {
                                row.original?.IsEventExperienceAccessible === "True" && (
                                  <ListItem
                                  onClick={() => {
                                    setOpen(false);
                                    let eventTitle = row.original.Title?.replaceAll(
                                        "&",
                                        "$"
                                    );
                                    localStorage.setItem(
                                      "CurrentEventId",
                                      JSON.stringify(row.original.EventId)
                                  );
                                  localStorage.setItem(
                                      "CurrentTimeZone",
                                      JSON.stringify(row.original.TimeZone)
                                  );
                                  localStorage.setItem(
                                    "EventEndDate",
                                    JSON.stringify(row.original.EndDate)
                                  );
                                    // let storeData = storeFolder.getState();
                                    // ws?.json({
                                    //   eventId: row.original.EventId,
                                    //   userId: storeData.loginResponse.results.ProfileDetail.UserId,
                                    //   type: "eventUserCheckedIn"
                                    // })
                                    let path = `${routeName}/Dashboard/EventExperience`;
                                      editThisEvent(row.original.EventId, path);
                                  }}
                                  button
                                  >
                                    <ListItemText
                                        primaryTypographyProps={{ style: { fontSize: 14 } }}
                                        primary="Event Experience"
                                    />
                                  </ListItem>
                                )
                              }

                              {row.original?.IsRecurringTicketPresent == 1 && (
                                  <ListItem
                                      onClick={() => {
                                        localStorage.setItem(
                                            "CurrentEventId",
                                            JSON.stringify(row.original.EventId)
                                        );
                                        localStorage.setItem(
                                            "CurrentTimeZone",
                                            JSON.stringify(row.original.TimeZone)
                                        );
                                        setOpen(false);
                                        let path = `${routeName}/Dashboard/Bookings/${row.original.EventId}`;
                                        if (
                                            row.original.Status !== "Canceled" &&
                                            row.original.Status !== "Ended"
                                        ) {
                                          editThisEvent(row.original.EventId, path);
                                        } else {
                                          cleareEventsAndCreateNewone(path);
                                        }
                                      }}
                                      button
                                  >
                                    <ListItemText
                                        primaryTypographyProps={{
                                          style: { fontSize: 14 },
                                        }}
                                        primary="Bookings"
                                    />
                                  </ListItem>
                              )}

                              <ListItem
                                  onClick={() => {
                                    setOpen(false);
                                    let eventTitle = row.original.Title?.replaceAll(
                                        "&",
                                        "$"
                                    );
                                    localStorage.setItem(
                                      "CurrentEventId",
                                      JSON.stringify(row.original.EventId)
                                  );
                                    let path = `${routeName}/Dashboard/Analytics/?eventId=${row.original.EventId}&title=${eventTitle}`;
                                    if (
                                        row.original.Status !== "Canceled" &&
                                        row.original.Status !== "Ended"
                                    ) {
                                      editThisEvent(row.original.EventId, path);
                                    } else {
                                      cleareEventsAndCreateNewone(path);
                                    }
                                  }}
                                  button
                                  // target="_blank"
                                  // href={`${routeName}/Dashboard/Analytics/?eventId=${row.original.EventId}&title=${row.original.Title.replace(" ", "-")}`}
                              >
                                <ListItemText
                                    primaryTypographyProps={{ style: { fontSize: 14 } }}
                                    primary="Analytics"
                                />
                              </ListItem>

                              {Number(row.original.TicketSold) > 0 && (
                                  <ListItem
                                      onClick={async () => {
                                        try {
                                          setOpen(false);

                                          // First API call
                                          const { data: eventData } = await axiosE.get(
                                              `${eventApis.GetEventMasterById}${row.original.EventId}/${results?.ProfileDetail?.UserId}/Admin`
                                          );

                                          if (eventData.results.ResponseCode) {
                                            localStorage.setItem(
                                                "CurrentEventId",
                                                JSON.stringify(row.original.EventId)
                                            );
                                            localStorage.setItem(
                                                "CurrentTimeZone",
                                                JSON.stringify(row.original.TimeZone)
                                            );
                                            localStorage.setItem(
                                                "EventEndDate",
                                                JSON.stringify(row.original.EndDate)
                                            );
                                            localStorage.setItem(
                                                "isRecurring",
                                                row.original?.IsRecurringTicketPresent === "1" ? true : false
                                            );

                                            // Second API call
                                            const { data: feeData } = await axiosE.get(
                                                `GetEventFeeData/${row.original.EventId}`
                                            );

                                            localStorage.setItem(
                                                "buyerFee",
                                                JSON.stringify(Number(feeData.results.UBMeFee))
                                            );
                                            localStorage.setItem(
                                                "additionalFee",
                                                JSON.stringify(Number(feeData.results.UbmeAdditionalCharge))
                                            );
                                            localStorage.setItem("CurrencySymbol", feeData.results.CurrencySymbol);

                                            // Dispatch action
                                            dispatch(editEvenntByEventUser(eventData.results));

                                            // Redirect after everything is done
                                            setTimeout(() => {
                                              window.location.href = `${routeName}/Dashboard/Attendees`;
                                            }, 150);
                                          }
                                        } catch (error) {
                                          console.error("Error fetching data:", error);
                                        }
                                      }}
                                      button
                                  >
                                    <ListItemText
                                        primaryTypographyProps={{ style: { fontSize: 14 } }}
                                        primary="Attendees"
                                    />
                                  </ListItem>
                              )}

                              {Number(row.original.TicketSold) > 0  && (
                              <ListItem
                                  onClick={() => {
                                    setOpen(false);
                                    downloadEmailInvitationContacts(
                                        row.original.EventId,
                                        row.original.Title,
                                        row.original.TimeZone
                                    )
                                  }}
                                  button
                              >
                                <ListItemText
                                    primaryTypographyProps={{ style : {fontSize: 14} }}
                                    primary="Download Attendees"
                                />
                              </ListItem>
                              )}

                              {Number(row.original.TicketSold) > 0  && (
                                  <ListItem
                                      onClick={() => {
                                        setOpen(false);
                                        downloadPDF(
                                            row.original.EventId,
                                            row.original.Title,
                                            row.original.TimeZone,
                                            row.original.StartDate,
                                            row.original.EndDate
                                        )
                                      }}
                                      button
                                  >
                                    <ListItemText
                                        primaryTypographyProps={{ style : {fontSize: 14} }}
                                        primary="Print Attendees"
                                    />
                                  </ListItem>
                              )}

                              {row.original.Status === "Published" &&
                                  Number(row.original.TicketSold) !== 0 &&
                                  row.original.Status !== "Ended" && (
                                      <ListItem
                                          // disabled={Number(row.original.TicketSold) !== 0}
                                          onClick={() => {
                                            setOpen(false);
                                            setModal({
                                              isShow: true,
                                              title: "cancel",
                                              eventId: row.original.EventId,
                                            });
                                          }}
                                          button
                                      >
                                        <ListItemText
                                            primaryTypographyProps={{
                                              style: { fontSize: 14 },
                                            }}
                                            primary="Cancel"
                                        />
                                      </ListItem>
                                  )}
                              {(Number(row.original.TicketSold) === 0 &&
                                  row.original.Status === "Canceled") ||
                              (Number(row.original.TicketSold) !== 0 &&
                                  row.original.Status === "Canceled") ||
                              (Number(row.original.TicketSold) === 0 &&
                                  row.original.Status !== "Canceled") ||
                              row.original.Status === "Ended" ? (
                                  <ListItem
                                      // disabled={
                                      //   Number(row.original.TicketSold) !== 0 &&
                                      //   row.original.Status !== "Canceled"
                                      // }
                                      onClick={() => {
                                        setOpen(false);
                                        setModal({
                                          isShow: true,
                                          title: "delete",
                                          eventId: row.original.EventId,
                                        });
                                        // deleteThisEvent(row.original.EventId);
                                      }}
                                      button
                                  >
                                    <ListItemText
                                        primaryTypographyProps={{
                                          style: { fontSize: 14 },
                                        }}
                                        primary="Delete"
                                    />
                                  </ListItem>
                              ) : null}
                            </List>
                          </div>
                        </div>
                      </div>
                  ) : null}
                </div>
            );
          },
        },
      ],
      [anchorEl, salesListByOrganizerData]
  );

  function Table({ columns, data }) {
    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable({
          columns,
          data,
        });

    // Render the UI for your table
    return (
        <table {...getTableProps()}>
          <thead>
          {headerGroups.map((headerGroup, i) => (
              <tr
                  key={i}
                  style={{ backgroundColor: "#ffffff" }}
                  {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column, i) => (
                    <th
                        key={i}
                        style={{
                          fontSize: 14,
                          // paddingBottom: 20,
                          padding: "2px 2px 20px 2px",
                          textAlign: "center",
                        }}
                        {...column.getHeaderProps()}
                    >
                      {column.render("Header")}
                    </th>
                ))}
              </tr>
          ))}
          </thead>
          <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);

            return (
                <tr key={i} {...row.getRowProps()}>
                  {row.cells.map((cell, i) => {
                    return (
                        <td
                            ref={menuRef}
                            key={i}
                            data-label={cell.column.Header}
                            style={{
                              textAlign: "center",
                              position: "relative",
                            }}
                            className={`text-white ${cell.column.className ?? ""}`}
                            {...cell.getCellProps({
                              style: {
                                padding: "10px",
                                width: cell.column.width,
                                textAlign:
                                    cell.column.Header !== "Event" ? "center" : "left",
                              },
                            })}
                        >
                          {cell.render("Cell")}
                        </td>
                    );
                  })}
                </tr>
            );
          })}
          </tbody>
        </table>
    );
  }

  const scheduleEmailDeleteModal = () => {
    const deleteLabels = constants.Tickets.deleteTicket;
    return (
        <Modal
            open={modal.isShow}
            onClose={() => setModal({ isShow: false, title: "", eventId: "" })}
        >
          <div className="delete_modal_wrapper">
            <div
                style={{
                  width: "100px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100px",
                }}
            >
              <img
                  src={deleteLabels.img}
                  alt="Delete Icon"
                  style={{ width: 30 }}
                  // className={classes.deleteModalImg}
              />
            </div>
            <p style={{ textAlign: "center" }}>
              Are you sure you want to {modal.title} this event?
            </p>
            <div style={{ display: "flex" }}>
              <Button
                  // className={classes.deleteModalSaveAndCancelButton}
                  onClick={() => {
                    setModal({ isShow: false, title: "", eventId: "" });
                  }}
                  style={{
                    color: "blue",
                    fontSize: "1.5rem",
                    padding: "10px 10px",
                  }}
              >
                NO, do not {modal.title} event
              </Button>

              <Button
                  // className={classes.deleteModalSaveAndCancelButton}
                  onClick={() =>
                      modal.title === "delete"
                          ? deleteThisEvent(modal.eventId)
                          : cancelThisEvent(modal.eventId)
                  }
                  style={{ color: "red", fontSize: "1.5rem", padding: "10px 10px" }}
              >
                Yes, {modal.title} event
              </Button>
            </div>
          </div>
        </Modal>
    );
  };

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }
  const [width, height] = useWindowSize();

  const Pagination = () => {
    return (
        <TablePagination
            size={50}
            rowsPerPageOptions={[]}
            component="div"
            count={Number(salesListByOrganizerData[0]?.TotalCounts)}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            showFirstButton
            showLastButton
        />
    );
  };

  return (
      <div>
        {visible && (
            <ShareModal closeModal={closeModal}>
              <Share
                  url={`${constants.URLs.baseUrl}/event/` + eventId}
              />
            </ShareModal>
        )}

        <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={cloning}
            onClick={() => {}}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        {loading ? (
            <div
                style={{
                  width: "100%",
                  height: "90vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
            >
              <CircularProgress size={50} color="secondary" />
            </div>
        ) : (
            <div>
              <div className="">
                {scheduleEmailDeleteModal()}
                {salesListByOrganizerData?.length > 0 ? (
                    width < 900 ? (
                        <div>
                          {salesListByOrganizerData?.map((el, i) => (
                              <EventOverviewMobular
                                  {...{
                                    editThisEvent,
                                    cloneThisEvent,
                                    setModal,
                                    modal
                                  }}
                                  data={el}
                                  index={i}
                              />
                          ))}
                          <div className="custom-pagination">
                            <Pagination />
                          </div>
                        </div>
                    ) : (
                        <div>
                          <Table columns={columns} data={salesListByOrganizerData} />
                          <br />
                          <div className="custom-pagination">
                            <Pagination />
                          </div>
                        </div>
                    )
                ) : (
                    <div
                        style={{
                          width: "100%",
                          height: "40vh",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                    >
                      {Status === "" && SearchTerm === "" ? (
                          <h1 style={{ color: "#808080", textAlign: "center" }}>
                            {constants.Text.NoEventCreated}
                          </h1>
                      ) : (
                          <h1 style={{ color: "#808080", textAlign: "center" }}>
                            {constants.Text.NoRecordsFound}
                          </h1>
                      )}
                    </div>
                )}
              </div>
            </div>
        )}
      </div>
  );
};

const mapStateToProps = (state) => {
  return {
    results: state.loginResponse.results,
    currentEventDetails: state.eventReducer.currentEventDetails,
    messageData: state.messageReducer,
    eventDetails: state.eventUserEvents,
  };
};

const matchDispatchToProps = (dispatch) => {
  return {
    GetOrdersByUserId,
    removeEmptyDialog,
    SalesListByOrganizerId,
    cloneEvenntByEventUser,
    editEvenntByEventUser,
    GetEventMasterDetailsById,
    clearEventDetails,
    removeEventByEventUser,
    dispatch,
  };
};

export default connect(
    mapStateToProps,
    matchDispatchToProps
)(EventOverviewTable);
