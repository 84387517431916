import React from "react";
import { Link } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import { GetDiscountData, ticktesCartItem } from "Actions/eventActions";
import { connect } from "react-redux";
const TimeOutScreen = ({
  dispatch,
  setContinueToCheckOut,
  setOpenCheckOut,
  setMinutes,
  GetDiscountData,
  ticktesCartItem,
  hasCheckoutQuery,
}) => {
  const handleClick = () => {
    const data = {
      DiscountType: "",
      disCountAmount: 0.0,
      disCountId: "",
      TicketsId: [],
      RemainingUtilization: "",
      ResponseCode: false,
    };

    dispatch(GetDiscountData(data));
    dispatch(ticktesCartItem([]));
    dispatch({
      type: "PAYMENT_INTENT_DETAILS",
      payload: { PublicKey: null, paymentId: null },
    });
    // For restart cartSession
    dispatch({ type: "CREATE_SHOPPING_DETAILS", payload: null });
    setMinutes(30);
    setContinueToCheckOut(false);
  };
  return (
    <div class="ticket-container">
      {!hasCheckoutQuery ? (
      <div onClick={() => setOpenCheckOut(false)} className="order_close">
        <IoClose size={20} />
      </div>) : null
      }
      <div class="info-1">
        <h1 style={{ marginBottom: 30 }}>Time Limit Reached</h1>
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          {/* <img src="https://icons.veryicon.com/png/o/miscellaneous/8atour/success-35.png" style={{marginBottom:30, width:100, height:"auto"}}/> */}
        </div>

        <p>
          Your reservation has been released. Please re-start your purchase.
        </p>
      </div>
      <div class="button-group">
        <button
          style={{ color: "#fff" }}
          to="#"
          onClick={() => handleClick()}
          class="leave"
        >
          Back to Tickets
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    results: state.loginResponse.results,
    isLoggedIn: state.loginResponse.isLoggedIn,
    currentEventDetails: state.eventReducer.currentEventDetails,
    cartItems: state.eventReducer.cartData,
    discountData: state.eventReducer.discountData,
    shoppingCartDetails: state.eventReducer.createshoppingCartDetails,
    paymentIntent: state.eventReducer.paymentIntent,
  };
};
const matchDispatchToProps = (dispatch) => {
  return {
    // AddToFavorite: () => dispatch(AddToFavorite),
    // SetCardData: () => dispatch(SetCardData),
    // LoginPopup: () => dispatch(LoginPopup),
    // currentEventTicketDetails,
    GetDiscountData,
    ticktesCartItem,
    // GetEventMasterById,
    dispatch,
  };
};
export default connect(mapStateToProps, matchDispatchToProps)(TimeOutScreen);
