import React, { Component } from "react";
import { Button, Card, CardContent, Grid, withStyles } from "@material-ui/core";
import EventsOverviewSearchBar from "./eventsOverviewSearchBar";
import EventStatusDropdown from "./eventsOverviewDropdown";
import { routeName } from "../../../../Service/env";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { connect } from "react-redux";
import {
  clearEventDetails,
  removeEventByEventUser,
} from "../../../../Actions/eventActions";
import { bindActionCreators } from "redux";
import EventOverviewTable from "./eventOverviewTables";
import { Text } from "Constants";

const styles = {
  eventsOnDashboardCard: {
    width: "100%",
    overflow: "inherit",
  },
  eventsOnDashboardSearchbarDropDownContainer: {
    paddingBottom: "10px",
    flexWrap: "wrap",
    "@media screen and (max-device-width: 545px)": {
      display: "flex",
      justifyContent: "center",
    },
  },
  eventsOnDashboardCreateEventButton: {
    float: "right",
    borderRadius: "5px",
    fontSize: "16px",
    textTransform: "none",
    height: "50px",
    width: "150px",
    margin: "10px 0px 10px 0px;",

    "@media screen and (max-width: 900px)": {
      width: "220px",
    },
    "@media only screen and (max-width:545px)": {
      width: "100%",
      margin: "10px",
    },
  },
  eventsOnDashboardCreateEventButton2: {
    float: "right",
    borderRadius: "5px",
    fontSize: "16px",
    textTransform: "none",
    height: "50px",
    width: "150px",
    margin: "10px 0px 10px 10px",
    display: "block",
    "@media only screen and (max-width: 900px)": {
      display: "none",
      float: "none",
    },
    //  "@media only screen and (max-width: 900px)": {
    //   float: "none",
    // },
  },
  eventsDashboardCreateEventFloatingButton: {
    position: "absolute",
    bottom: "100px",
    right: "30px",
    // display: "none",
    visibility: "hidden",
    "@media only screen and (max-width: 900px)": {
      visibility: "visible",
    },
  },
};

const ForwardedChildComponent = React.forwardRef((props, ref) => {
  return <EventOverviewTable {...props} forwardedRef={ref} />;
});

class EventsOverviewCard extends Component {
  constructor(props) {
    super(props);
    this.childRef = React.createRef();
    this.state = {
      searchResults: [],
      initialLoad: true,
      status: "",
      SearchTerm: "",
      eventname: "",
    };
  }

  // componentDidUpdate()    {
  //     if(this.state.initialLoad && store.getState().eventUserEvents !== undefined && store.getState().eventUserEvents != "") {
  //         this.setState({
  //             searchResults: store.getState().eventUserEvents.results.Events,
  //             initialLoad: false
  //         });
  //     }
  // }

  onSearch = (e) => {
    this.setState({ SearchTerm: e.target.value });
  };

  onClickSerchEvents = (e) => {
    this.childRef.current.SalesListByOrganizerIdFn();
  };

  onEventStatusDropdownChange = (event) => {

    // this.setState({SearchTerm: "",})
    // this.setState({eventname:""})
    this.setState({ status: event.target.value });
  };

  changeToCreateEvent = () => {
    const { setActiveTab } = this.props.sidebarFunctions || {};
    setActiveTab("About");
    localStorage.setItem("isItCreateOrEditEvent", "Edit");
    this.props.clearEventDetails();
    this.props.removeEventByEventUser();
    localStorage.setItem("persist:persisted-key", "");
    setTimeout(() => {
      window.location.href = `${routeName}/Dashboard/About`;
    }, 200);
  };

  render() {
    const { classes, refresher } = this.props;
    const { searchResults } = this.state;

    return (
      <Grid item className={classes.eventsOnDashboardCard}>
        <Card className={classes.eventsOnDashboardCard}>
          <CardContent>
            <h3>Events</h3>
            <div
              className={classes.eventsOnDashboardSearchbarDropDownContainer}
            >
              <EventsOverviewSearchBar
                value={this.state.SearchTerm}
                onSearch={this.onSearch}
              />

              <EventStatusDropdown
                onEventStatusDropdownChange={this.onEventStatusDropdownChange}
                title="Event status"
              />
              <Button
                style={{ float: "none" }}
                className={classes.eventsOnDashboardCreateEventButton}
                color={"primary"}
                variant={"contained"}
                onClick={() => this.onClickSerchEvents()}
              >
                {Text.Search}
              </Button>
              {/* <EventStatusDropdown
                onEventStatusDropdownChange={this.onEventStatusDropdownChange}
                title="Organizer"
              /> */}

              {/* {window.matchMedia("(any-hover: none)").matches ? ( */}
              <Fab
                color="primary"
                aria-label="add"
                className={classes.eventsDashboardCreateEventFloatingButton}
                onClick={() => {
                    localStorage.removeItem("CurrentEventId");
                    localStorage.removeItem("isRecurring");
                    this.changeToCreateEvent()
                }}
              >
                <AddIcon style={{ fontSize: 50 }} />
              </Fab>
              {/* // ) : ( */}
              <Button
                className={classes.eventsOnDashboardCreateEventButton2}
                color={"primary"}
                variant={"contained"}
                onClick={() => {
                  localStorage.removeItem("CurrentEventId");
                  localStorage.removeItem("isRecurring");
                  this.changeToCreateEvent();
                }}
              >
                {Text.CreateEvent}
              </Button>
              {/* // )} */}
            </div>
            <ForwardedChildComponent
              ref={this.childRef}
              refresher={refresher}
              SearchTerm={this.state.SearchTerm}
              Status={this.state.status}
            />
          </CardContent>
        </Card>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  const { eventUserEvents } = state;
  return {
    sidebarFunctions: eventUserEvents.sidebarFunctions,
  };
};
const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      clearEventDetails,
      removeEventByEventUser,
    },
    dispatch
  );
};
export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withStyles(styles)(EventsOverviewCard));
