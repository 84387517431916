import React, { useEffect } from "react";

import Modal from "@material-ui/core/Modal";
import "./style.css";
import AddMoreTickets from "../checkout/AddMoreTickets/AddMoreTickets";
import { useState } from "react";
import Payment from "../checkout/payment/Payment";
import { connect } from "react-redux";
import LoginBeforeCheckOut from "../checkout/LoginBeforeCheckout/LoginBeforeCheckOut";
import {
  GetDiscountData,
  removeAllEventDateDetails,
  ticktesCartItem,
} from "Actions/eventActions";
import Countdown from "react-countdown";
const CheckOutModal = ({
  openCheckOut,
  checkOutTimer,
  setOpenCheckOut,
  isLoggedIn,
  paymentIntent,
  GetDiscountData,
  ticktesCartItem,
  dispatch,
  Token,
  currentEventDetails,
  hasCheckoutQuery
}) => {
  const [continueToCheckOut, setContinueToCheckOut] = useState(false);
  const [isNotLoggedIn, setIsNotLoggedIn] = useState(false);
  const [minutes, setMinutes] = useState(30);
  const [seconds, setSeconds] = useState(0);
  const [newCartItemsData, setNewCartItemsData] = useState([]);

  const ticketQty = newCartItemsData?.reduce(
    (a, c) => a + (c.DiscountQty === null ? 0 : Number(c.DiscountQty)),
    0
  );
  useEffect(() => {
    if (checkOutTimer) {
      // console.log("Hello world!", checkOutTimer);
      let myInterval = setInterval(() => {
        if (seconds > 0) {
          setSeconds((prev) => prev - 1);
        }
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(myInterval);
          } else {
            setMinutes((prev) => prev - 1);
            setSeconds(59);
          }
        }
      }, 1000);
      return () => {
        clearInterval(myInterval);
      };
    }
  }, [minutes, seconds, paymentIntent]);

  useEffect(() => {
    console.log('Current Event Details updated:', currentEventDetails);
  }, [currentEventDetails]);

  const [recurringData, setRecurringData] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  return (
    <div>
      <Modal
        open={openCheckOut}
        // onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="checkout_modal">
          <div className="checkout_modal_container">
            {/* {CountDown()} */}
            {continueToCheckOut ? (
              !isLoggedIn ? (
                <LoginBeforeCheckOut
                    setOpenCheckOut={setOpenCheckOut}
                    hasCheckoutQuery = {hasCheckoutQuery}
                />
              ) : (
                <Payment
                  {...{ minutes, seconds, Token, setMinutes }}
                  setContinueToCheckOut={setContinueToCheckOut}
                  setOpenCheckOut={setOpenCheckOut}
                  recurringData={recurringData}
                  hasCheckoutQuery={hasCheckoutQuery}
                />
              )
            ) : (
              <AddMoreTickets
                {...{ minutes, setMinutes, seconds, Token }}
                setIsNotLoggedIn={setIsNotLoggedIn}
                setOpenCheckOut={setOpenCheckOut}
                setContinueToCheckOut={setContinueToCheckOut}
                setRecurringData={setRecurringData}
                recurringData={recurringData}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                hasCheckoutQuery={hasCheckoutQuery}
              />
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    results: state.loginResponse.results,
    isLoggedIn: state.loginResponse.isLoggedIn,
    currentEventDetails: state.eventReducer.currentEventDetails,
    cartItems: state.eventReducer.cartData,
    paymentIntent: state.eventReducer.paymentIntent,
    checkOutTimer: state.eventReducer.checkOutTimer,
    discountData: state.eventReducer.discountData,
  };
};
const matchDispatchToProps = (dispatch) => {
  return {
    // AddToFavorite: () => dispatch(AddToFavorite),
    // SetCardData: () => dispatch(SetCardData),
    // LoginPopup: () => dispatch(LoginPopup),
    // currentEventTicketDetails,
    // ticktesCartItem,
    // GetDiscountDetails,
    GetDiscountData,
    ticktesCartItem,
    dispatch,
  };
};

export default connect(mapStateToProps, matchDispatchToProps)(CheckOutModal);
